import { UserVetDelegateLevelT, VetsForPracticeUserT } from "../../../../data/gql/apiTypes";
import { tag } from "../../../../util";
import messages from "../../messages";

const getThirtyDatesFromToday = () => {
  const today = new Date();
  const thirtyDaysFromToday = new Date(today);
  thirtyDaysFromToday.setDate(today.getDate() + 30);
  return thirtyDaysFromToday;
};

// surprisingly this function returns true if the vet license is valid and false if invalid
export const checkVetLicenseInvalid = (vetKey: string, authorizingVets: VetsForPracticeUserT[], veterinarianLicenseKey = "") => {
  if (!vetKey || authorizingVets.length === 0) return true;
  const filteredVet = authorizingVets?.filter((vet: { veterinarianKey: string }) => vet.veterinarianKey === vetKey);
  if (filteredVet?.length > 0) {
    if (filteredVet[0].veterinarianLicenses !== null && filteredVet[0].veterinarianLicenses?.length > 0) {
      if (veterinarianLicenseKey) {
        return !!filteredVet[0].veterinarianLicenses.find((license) => license?.veterinarianLicenseKey === veterinarianLicenseKey && license?.isActive === true);
      } else {
        return !!filteredVet[0].veterinarianLicenses.find((license) => license?.veterinarianLicenseKey === filteredVet[0].primaryVetLicenseNumber && license?.isActive === true);
      }
    }
    return false;
  }
  return true;
};

export const checkVetLicenseExpired = (vetKey: string, authorizingVets: VetsForPracticeUserT[], veterinarianLicenseKey = "") => {
  if (!vetKey || authorizingVets.length === 0) return true;
  const filteredVet = authorizingVets?.filter((vet: { veterinarianKey: string }) => vet.veterinarianKey === vetKey);
  if (filteredVet?.length > 0) {
    const vetInfo: VetsForPracticeUserT = filteredVet[0];
    if (vetInfo.veterinarianLicenses !== null && vetInfo.veterinarianLicenses?.length > 0) {
      const primaryLicense = vetInfo.veterinarianLicenses.find(license => license?.veterinarianLicenseKey === filteredVet[0].primaryVetLicenseNumber);
      if (!vetInfo.veterinarianLicenses.find(license => license?.isActive === true)) {
        tag('No active license found for vet', vetKey);
      }
      // handle multiple licenses: use the first active (default?) if the specific veterinarianLicenseKey is ""
      const vetLicense = veterinarianLicenseKey && vetInfo.veterinarianLicenses.some((license) => license?.veterinarianLicenseKey === veterinarianLicenseKey) ? 
      vetInfo.veterinarianLicenses.find((license) => license?.veterinarianLicenseKey === veterinarianLicenseKey) : primaryLicense;
      if (vetLicense?.expirationDate) {
        return new Date(vetLicense.expirationDate) >= new Date();
      }
    }
  }
  return true;
};

export const checkVetLicenseExpiredByLicenseKey = (authorizingVets: VetsForPracticeUserT[], veterinarianLicenseKey: string) => {
  if (!veterinarianLicenseKey || authorizingVets.length === 0) return true;
  const filteredData = authorizingVets.find((vets) =>
    (vets.veterinarianLicenses!.find((field) => field?.veterinarianLicenseKey === veterinarianLicenseKey)));
    const vetLicense = filteredData?.veterinarianLicenses ? filteredData.veterinarianLicenses.find(license => license?.veterinarianLicenseKey === veterinarianLicenseKey ) : null ;
    if (vetLicense?.expirationDate) {
      return new Date(vetLicense?.expirationDate) >= new Date();
    }
  
  return true;
};

export const checkVetDeaLicenseInvalid = (
  vetKey: string,
  authorizingVets: VetsForPracticeUserT[],
  isControlled: boolean,
) => {
  if (!vetKey || authorizingVets.length === 0) return true;
  if (isControlled) {
    const filteredVet = authorizingVets.filter((vet: { veterinarianKey: any }) => vet.veterinarianKey === vetKey);
    if (filteredVet?.length > 0) {
      return filteredVet[0].deaVeterinarianLicense !== null;
    }
  }
  return true;
};

export const checkVetDeaLicenseExpired = (
  vetKey: string,
  authorizingVets: VetsForPracticeUserT[],
  isControlled: boolean | null,
) => {
  if (!vetKey || authorizingVets.length === 0) return true;
  if (isControlled) {
    const filteredVet = authorizingVets?.filter((vet: { veterinarianKey: any }) => vet.veterinarianKey === vetKey);
    if (filteredVet[0]?.deaVeterinarianLicense?.expirationDate) {
      return new Date(filteredVet[0].deaVeterinarianLicense?.expirationDate) >= new Date();
    }
  }
  return true;
};

export const getVetLicenseForState = (vetKey: string, authorizingVets: VetsForPracticeUserT[], veterinarianLicenseKey: string = "") => {
  const filteredVet = authorizingVets.filter(({ veterinarianKey }) => veterinarianKey === vetKey)?.[0];
  if (filteredVet?.veterinarianLicenses && !veterinarianLicenseKey) {
    return filteredVet?.veterinarianLicenses?.[0];
  }
  if (filteredVet?.veterinarianLicenses && veterinarianLicenseKey) {
    return filteredVet?.veterinarianLicenses.filter(license => license?.veterinarianLicenseKey === veterinarianLicenseKey)?.[0];
  }
  return undefined;
};

export const checkVetLicenseExpiring = (vetKey: string, authorizingVets: VetsForPracticeUserT[], veterinarianLicenseKey: string = "") => {
  const filteredLicense = getVetLicenseForState(vetKey, authorizingVets, veterinarianLicenseKey);
  // Vet License Expiring
  if (filteredLicense) {
    const vetLicenseExpirationDate = filteredLicense.expirationDate
      ? new Date(filteredLicense.expirationDate)
      : "";
    if (vetLicenseExpirationDate &&
      vetLicenseExpirationDate >= new Date() &&
      vetLicenseExpirationDate <= getThirtyDatesFromToday()) {
      return false;
    }
  }
  return true;
};

export const checkVetDeaLicenseExpiring = (
  vetKey: string,
  authorizingVets: VetsForPracticeUserT[],
  isControlled: boolean,
) => {
  const filteredVet = authorizingVets?.filter((vet: { veterinarianKey: any }) => vet.veterinarianKey === vetKey);
  if (filteredVet?.length > 0) {
    if (isControlled && filteredVet[0].deaVeterinarianLicense?.expirationDate) {
      const expirationDate = filteredVet[0].deaVeterinarianLicense?.expirationDate
        ? new Date(filteredVet[0].deaVeterinarianLicense?.expirationDate)
        : "";
      if (expirationDate && expirationDate >= new Date() && expirationDate <= getThirtyDatesFromToday()) return false;
    }
  }
  return true;
};

export const checkBasicDelegatePermission = (
  vetKey: string,
  vetDelegateLevels: UserVetDelegateLevelT[],
  legalCategoryCode: string,
  treatmentCategories: string[]
) => {
  const selectedVetDelegateLevel = vetDelegateLevels?.filter((vet: { veterinarianKey: any }) => vet.veterinarianKey === vetKey);
  if (selectedVetDelegateLevel?.length > 0) {
    if (selectedVetDelegateLevel[0].delegationTypeCode === 'US_OTC_AOTC_PREVENTATIVE') {
      if (legalCategoryCode === "RX" && (treatmentCategories.includes("PREVENTATIVE") || treatmentCategories.includes("DIET"))) {
        return false;
      }
      return true;
    }
  }
  return false;
};

export const checkVetSelectOtherVetForControlledSubstance = (
  vetKey: string,
  vetDelgateLevels: UserVetDelegateLevelT[],
  isControlled: boolean,
  userVeterinarianKey: string
) => {
  if (isControlled) {
    if (vetKey !== userVeterinarianKey && !vetDelgateLevels?.some(vetDelegateLevel => vetDelegateLevel?.delegationTypeCode === 'US_OTC_AOTC_PREVENTATIVE_RX')) {
      return false;
    }
  }
  return true;
};

export const getAuthorizingVetWarningRx = (
  vetKey: string,
  authorizingVets: VetsForPracticeUserT[],
  isControlled: boolean,
  veterinarianLicenseKey: string
) => {
  const warnings = [];
  if (!checkVetLicenseExpiring(vetKey, authorizingVets, veterinarianLicenseKey)) {
    warnings.push({ message: messages.validations.authorizingVet.vetLicenseExpiring });
  }
  if (!checkVetDeaLicenseExpiring(vetKey, authorizingVets, isControlled)) {
    warnings.push({ message: messages.validations.authorizingVet.deaLicenseExpiring });
  }
  return warnings;
};


export const getAuthorizingVetWarningOtc = (
  vetKey: string,
  authorizingVets: VetsForPracticeUserT[],
) => {
  const warnings = [];
  if (!checkVetLicenseInvalid(vetKey, authorizingVets)) {
    warnings.push({ message: messages.validations.authorizingVet.vetLicenseInvalid });
  }
  if (!checkVetLicenseExpiring(vetKey, authorizingVets)) {
    warnings.push({ message: messages.validations.authorizingVet.vetLicenseExpiring });
  }

  return warnings;
};

export const getAuthorizingVetWarningAotc = (
  vetKey: string,
  authorizingVets: VetsForPracticeUserT[],
) => {
  const warnings = [];
  if (!checkVetLicenseExpiring(vetKey, authorizingVets)) {
    warnings.push({ message: messages.validations.authorizingVet.vetLicenseExpiring });
  }

  return warnings;
};