import * as r from "ramda";
import { useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { addToast } from "../../actions/toast";
import { RxIcon } from "../../assets/RxIcon";
import CollapsableCard from "../../components/CollapsableCard/index";
import { DescriptionComponent } from "../../components/DescriptionComponent";
import { ProductStatusEnum, legalCategoryCodeEnum } from "../../components/FinishScriptForm/FinishScriptCard";
import FinishScriptCard from "../../components/FinishScriptForm/index";
import { getDisplayPimsName } from "../../components/PimsLogo/getDisplayPimsName";
import PimsLogo from "../../components/PimsLogo/PimsLogo";
import { SpeciesPicker } from "../../components/Species/Species";
import { Container } from "../../components/StyledComponents/Container/Container";
import Loading from "../../components/StyledComponents/Loading/Loading";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import { generateToastID } from "../../components/Toast/Toast";
import {
  usegetAuthorizingVetsForUserQuery,
  useRetrieveLinkedClientDetailsQuery,
  useRetrieveLinkedPatientDetailsQuery,
  useRetrieveProductDetailsQuery,
  useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
  useSubmitRxMutation,
  VetsForPracticeUserT,
  useRetrievePracticeUserWithClaimCheckQuery,
  FullPracticeUserT,
  ProductT,
  PatientT,
  ClientT,
  useGetPracticeApprovalQuery,
  ApprovalT,
  RenewalT,
  UserVetDelegateLevelT,
  useGetPracticeRenewalQuery,
  VariantAttributeSelections,
  useUnlinkProductLinkMutation,
  useGetBaseProductQuery,
  BaseProductT,
  usegetPlatformAccountQuery,
} from "../../data/gql/apiTypes";
import { exists } from "../../util";
import messages from "./messages";
import { FinishScriptWrapper } from "./styles";
import {
  computeNumberOfRefills,
  fetchClientName,
  fetchCollapsedText,
  fetchPatientWeight,
  shouldDefaultAutoShipOn,
  showUserPermissionWarningCard,
  getTotalRefills,
  StatesWith503B
} from "./utils";
import commonMessages from "../../common/messages";
import { ProductCollapseCardValues } from "../../components/CollapsableCard/types";
import { useSession } from "../../hooks/sessionFlow";
import { flowType } from "../../context/actions";
import { PencilIcon } from "../../assets/PencilIcon";
import UpdatePatientWeightModal from "../../components/UpdatePatientWeightModal";
import { ContainerProps } from "../../PrivateRoute/types";
import { WorkFlowType } from "../../components/CollapsableCard/types";
import { DELEGATION_TYPE_CODE } from "../../common/enum";
import { useDispatchErrors } from "../../hooks/useDispatchErrors";

const ReviewRx = (props: ContainerProps) => {
  const { claimCheckKey, prescriptionGuid } = useParams<{ claimCheckKey?: string, prescriptionGuid?: string }>();
  const history = useHistory();
  const session = useSession();
  const dispatch = useDispatch();
  const [patientCollapsed, setPatientCollapsed] = useState(true);
  const [productCollapsed, setProductCollapsed] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isProductOrBaseProductDiscontinuedOrInactive, setIsProductOrBaseProductDiscontinuedOrInactive] = useState(false);
  const [showUpdatePatientWeightModal, setShowUpdatePatientWeightModal] = useState(false);
  const [submitRx] = useSubmitRxMutation();
  const [unlinkProductLink, { loading: unlinkProductLinkLoading }] = useUnlinkProductLinkMutation();

  const { loading, error, data } = useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery({
    variables: { claimCheckKey: claimCheckKey || "", pimsPrescriptionKey: prescriptionGuid || null },
    fetchPolicy: "cache-and-network",
  });

  const { data: userData, error: userError } = useRetrievePracticeUserWithClaimCheckQuery({
    variables: {
      claimCheckKey: claimCheckKey || '',
    },

    fetchPolicy: "cache-first",
  });
  const user = userData?.retrievePracticeUser as FullPracticeUserT;

  const maybePimsType = props.claimCheck?.pims || data?.retrieveRxByGuidOrClaimCheckGuid?.pimsType || "";
  const maybeInstallationKey = props.claimCheck?.installationKey || null;

  //In the pending-rx flow, we should always check the unlinkedProductKey first when retrieving the product info
  const productKey = prescriptionGuid ? (data?.retrieveRxByGuidOrClaimCheckGuid?.unlinkedProductKey || data?.retrieveRxByGuidOrClaimCheckGuid?.linkedProductKey || "") :
    (data?.retrieveRxByGuidOrClaimCheckGuid?.linkedProductKey || data?.retrieveRxByGuidOrClaimCheckGuid?.unlinkedProductKey || "")

  const gpmProductQuery = useRetrieveProductDetailsQuery({
    variables: {
      baseProductKey:
        data?.retrieveRxByGuidOrClaimCheckGuid?.baseProductKey ||
        productKey,
      productKey: productKey,
      claimCheckKey: claimCheckKey || ""
    },
    skip:
      !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.linkedProductKey) &&
      !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.unlinkedProductKey),
    fetchPolicy: "cache-and-network",
  });

  const baseProductKey = data?.retrieveRxByGuidOrClaimCheckGuid?.baseProductKey || '';
  const gpmBaseProductQuery = useGetBaseProductQuery
    ({
      variables: { guid: baseProductKey },
    });

  const gpmApprovalQuery = useGetPracticeApprovalQuery({
    variables: { approvableKey: data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey! },
    skip:
      !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey),
  });

  const gpmRenewalQuery = useGetPracticeRenewalQuery({
    variables: { renewalKey: data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey! },
    skip:
      !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey),
  });

  const gpmPlatformAccount = usegetPlatformAccountQuery({
    variables: {
      acbKey: user?.animalCareBusinessKey,
      platformAccountKey: userData?.retrieveClaimCheckByGuid?.platformAccountKey ?? ""
    },
    skip:
      !exists(user?.animalCareBusinessKey),
  });

  const gpmPatientQuery = useRetrieveLinkedPatientDetailsQuery({
    variables: { key: data?.retrieveRxByGuidOrClaimCheckGuid?.linkedPatientKey || "" },
    skip: !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.linkedPatientKey),
  });
  const gpmClientQuery = useRetrieveLinkedClientDetailsQuery({
    variables: {
      guid:
        data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey || gpmPatientQuery?.data?.getPatient?.clientKey || "",
    },
    skip:
      !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey) &&
      !exists(gpmPatientQuery?.data?.getPatient?.clientKey),
  });

  var variants = gpmApprovalQuery?.data?.getApproval?.variantAttributeSelections as Array<VariantAttributeSelections> ?? gpmRenewalQuery?.data?.getRenewal?.variantAttributeSelections as Array<VariantAttributeSelections> ?? null;

  const dosageVariant = variants?.find(variant => variant.variantAttributeTypeCode === "DOSAGE")?.variantAttributeValueTypeKey ?? null;

  const { loading: loadingVets, error: errorVets, data: dataVets } = usegetAuthorizingVetsForUserQuery({});

  const product = props.claimCheck?.payload.product;

  React.useEffect(() => {
    if (gpmProductQuery.data) {
      if (
        gpmProductQuery.data?.getProduct?.baseProductStatusCode === ProductStatusEnum.DISCONTINUED ||
        gpmProductQuery.data?.getProduct?.baseProductStatusCode === ProductStatusEnum.INACTIVE ||
        gpmProductQuery.data?.getProduct?.productStatusCode === ProductStatusEnum.DISCONTINUED ||
        gpmProductQuery.data?.getProduct?.productStatusCode === ProductStatusEnum.INACTIVE
      ) {
        setIsProductOrBaseProductDiscontinuedOrInactive(true);
      }
      setProductCollapsed(session.flow === flowType.unlinked || !isProductOrBaseProductDiscontinuedOrInactive);
    }
  }, [gpmProductQuery]);

  useDispatchErrors([
    { error: gpmApprovalQuery.error, message: messages.fetchApprovalsFailure },
    { error: gpmRenewalQuery.error, message: messages.fetchRenewalsFailure }
  ]);

  if (!claimCheckKey) {
    return <WarningCard reason={"No Claim Check GUID present on URL"} />;
  }
  if (
    loading ||
    gpmProductQuery.loading ||
    gpmPatientQuery.loading ||
    gpmClientQuery.loading ||
    gpmApprovalQuery.loading ||
    gpmRenewalQuery.loading ||
    gpmBaseProductQuery.loading ||
    isFormSubmitting ||
    unlinkProductLinkLoading ||
    loadingVets
  ) {
    return <Loading fullPage />;
  }
  if (!data?.retrieveRxByGuidOrClaimCheckGuid || error) {
    console.error(error);
    return <WarningCard reason={"Unknown error."} />;
  }
  if (userError) {
    return <WarningCard reason={"Unable to fetch practice user"} />;
  }
  if (gpmProductQuery.error) {
    return <WarningCard reason={"Unable to fetch product details"} />;
  }
  if (gpmPatientQuery.error) {
    return <WarningCard reason={"Unable to fetch linked patient details"} />;
  }
  if (gpmClientQuery.error) {
    return <WarningCard reason={"Unable to fetch linked client details"} />;
  }
  if (errorVets) {
    return <WarningCard reason={"Unable to fetch Authorized Veterinarian for User"} />;
  }

  const gpmProduct = gpmProductQuery?.data?.getProduct as ProductT;
  const gpmApproval = gpmApprovalQuery?.data?.getApproval as ApprovalT;
  const gpmRenewal = gpmRenewalQuery?.data?.getRenewal as RenewalT;
  const gpmClient = gpmClientQuery?.data?.retrieveClientByGuid as ClientT;
  const gpmPatient = gpmPatientQuery?.data?.getPatient as PatientT;
  const gpmBaseProduct = gpmBaseProductQuery?.data?.getBaseProduct as BaseProductT;

  const get503B = () => {
    const platformAccountState = gpmPlatformAccount.data?.getPlatformAccount?.address?.stateOrProvince ?? "";
    if (gpmBaseProduct.productManufacturerClassificationCodes?.includes("503B") && Object.values(StatesWith503B).includes(platformAccountState)) {
      return true;
    }
    return false;
  }


  const reason = showUserPermissionWarningCard(gpmBaseProduct, gpmProduct, gpmApproval, gpmRenewal, gpmPatient, gpmClient, user);
  if (reason) {
    switch (reason) {
      case "USER_MUST_BE_VET":
        return <WarningCard reason={<div>You <b>must be a Veterinarian</b> with an active and valid DEA license or an <b>Advanced Delegate</b> to create a prescription for a controlled substance product.</div>} />;
      case "BASIC_DELEGATE_USER":
        return <WarningCard reason={<div>You have <b>Basic Delegate</b> permissions and are not authorized to prescribe a medication for a Veterinarian at your practice.</div>} />;
      default:
        return <WarningCard reason={reason} />;
    }
  }

  const getPatientWeightRightValue = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'row' }} title={gpmWeightWithUnits || ''}>
        {gpmWeightWithUnits}
        <div style={{ marginRight: 20 }}>
          <div style={{ position: 'absolute', whiteSpace: 'normal', flexDirection: 'row' }}>
            <div style={{ cursor: 'pointer' }} onClick={() => setShowUpdatePatientWeightModal(true)}>
              <PencilIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const linkedGuids = {
    client: data.retrieveRxByGuidOrClaimCheckGuid.linkedClientKey || gpmPatientQuery?.data?.getPatient?.clientKey || "",
    patient: data.retrieveRxByGuidOrClaimCheckGuid.linkedPatientKey || "",
    product: productKey,
    baseProduct: data?.retrieveRxByGuidOrClaimCheckGuid?.baseProductKey || "",
    prescription: data.retrieveRxByGuidOrClaimCheckGuid.pimsPrescriptionKey,
    approvalKey: data.retrieveRxByGuidOrClaimCheckGuid.approvalKey!,
    renewalKey: data.retrieveRxByGuidOrClaimCheckGuid.renewalKey!,
    linkedProductKey: data.retrieveRxByGuidOrClaimCheckGuid.linkedProductKey
  };

  const getDateYearFromNow = () => {
    const date = new Date();
    const newDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate() - 1);
    return newDate.toISOString().split('T')[0];
  }

  const getDateSixMonthsFromNow = () => {
    const date = new Date();
    const newDate = new Date(date.getFullYear(), date.getMonth() + 6, date.getDate() - 1);
    return newDate.toISOString().split('T')[0];
  }
  //waiting on newtron team to change end point to accept correct expiration date.  Setting all approvals as 6 months for now:
  const getExpirationDate = () => {
    if (gpmProduct?.isControlled || gpmApproval?.isControlled || gpmRenewal?.isControlled) {
      return getDateSixMonthsFromNow();
    }
    return getDateYearFromNow();
  }

  const handleUnlinkProduct = (productKey: string | null, installationKey: string | null, claimCheckKey: string) => {
    if (!productKey) return; // No Guid for Product Link? Nothing to do here. Bye.
    if (!installationKey) return; // No Guid for Installation Key? Nothing to do here. Bye
    unlinkProductLink({
      variables: {
        productKey,
        installationKey,
        claimCheckKey
      },
    })
      .then(() => {
        history.push(`/product-linking/${claimCheckKey}`);
      })
      .catch((error) => {
        dispatch(
          addToast({
            message: messages.unlinkProductFilure,
            id: generateToastID(),
            kind: "error"
          })
        );
      });
  };

  const isLinkedFlowAndHasProductLink = (session.flow === flowType.linked && exists(linkedGuids?.linkedProductKey)) || false;
  const unlinkProductProps = {
    unlinkButtonText: "unlink",
    buttonRouteTo: {
      handleClick: () => handleUnlinkProduct(linkedGuids.linkedProductKey, maybeInstallationKey, claimCheckKey),
    },
  };

  const productProps: ProductCollapseCardValues = {
    headerIcon: <RxIcon />,
    collapsed: productCollapsed,
    headerText: gpmProduct?.name || gpmApproval?.productName || gpmRenewal?.productName || "",
    setCollapsed: setProductCollapsed,
    comparison: {
      cvetMatchFound: true,
      linkSelectionMade: false,
      showPimsData: exists(product),
      leftColumnIcon: <PimsLogo pimsType={maybePimsType} />,
      continue: () => { },
      comparisonGrid: [
        { label: commonMessages.name, leftValue: product?.description, rightValue: gpmProduct?.name || gpmApproval?.productName || gpmRenewal?.productName },
        { label: commonMessages.strength, leftValue: product?.strength, rightValue: gpmProduct?.strength || gpmApproval?.productStrength || gpmRenewal?.productStrength },
        { label: commonMessages.form, leftValue: product?.form, rightValue: gpmProduct?.size || gpmApproval?.productUOM || gpmRenewal?.productUOM },
        { label: "Code", leftValue: product?.code },
      ],
    },
    // Show unlink button only if flow is linked and product link exists (Product links are practice specific).
    ...(isLinkedFlowAndHasProductLink && unlinkProductProps)
  };

  const patient = props.claimCheck?.payload.patient;
  const client = props.claimCheck?.payload.client;
  const pimsWeightWithUnits = fetchPatientWeight(patient?.currentWeight, patient?.currentWeightUnit);
  const gpmWeightWithUnits = fetchPatientWeight(gpmPatient?.weight, gpmPatient?.weightUnitCode);
  const collapsedText = fetchCollapsedText(gpmWeightWithUnits, gpmClient?.givenName, gpmClient?.familyName);
  const pimsClientName = fetchClientName(null, client?.firstName, client?.lastName);
  const gpmClientName = fetchClientName(gpmPatient?.clientName, gpmClient?.givenName, gpmClient?.familyName);
  const gpmAuthorizingVets = dataVets?.retrieveVetsForPracticeUser as VetsForPracticeUserT[];
  const loggedInUsername = props.user?.username;

  const delegates = user?.vetDelegateLevels as UserVetDelegateLevelT[];
  if (gpmApproval?.prescriptionTypeCode === DELEGATION_TYPE_CODE.US_OTC_AOTC_PREVENTATIVE_RX ?? gpmRenewal?.prescriptionTypeCode ?? gpmProduct?.legalCategoryCode === legalCategoryCodeEnum.RX) {
    delegates?.filter(delegate => delegate.delegationTypeCode = DELEGATION_TYPE_CODE.US_OTC_AOTC_PREVENTATIVE_RX)
  }
  let gpmAuthorizingVetsSorted = gpmAuthorizingVets?.filter(vets => delegates?.find(x => x.veterinarianKey === vets.veterinarianKey)
    || vets.veterinarianKey === user?.veterinarianKey).sort((a, b) => a.familyName < b.familyName ? -1 : 1);

  const patientProps = {
    headerIcon: <SpeciesPicker species={gpmPatient?.speciesCode || ""} useThumbnail={false} />,
    collapsed: patientCollapsed,
    setCollapsed: setPatientCollapsed,
    headerText: gpmPatient?.name,
    collapsedText,
    comparison: {
      cvetMatchFound: true,
      linkSelectionMade: false,
      showPimsData: exists(patient),
      leftColumnIcon: <PimsLogo pimsType={maybePimsType} />,
      continue: () => { },
      comparisonGrid: [
        { label: commonMessages.name, leftValue: patient?.name, rightValue: gpmPatient?.name },
        { label: commonMessages.client, leftValue: pimsClientName, rightValue: gpmClientName },
        { label: commonMessages.email, leftValue: client?.email, rightValue: gpmClient?.emailAddress },
        { label: commonMessages.phone, leftValue: client?.phoneNumber, rightValue: gpmClient?.phoneNumber },
        { label: commonMessages.species, leftValue: patient?.speciesDescription, rightValue: gpmPatient?.speciesCode.toLowerCase().replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key, p1) { return key.toUpperCase(); }) },
        { label: commonMessages.weight, leftValue: pimsWeightWithUnits, rightValue: getPatientWeightRightValue() },
        { label: commonMessages.sex, leftValue: patient?.gender, rightValue: gpmPatient?.sexCode },
        { label: commonMessages.dob, leftValue: patient?.dateOfBirth, rightValue: gpmPatient?.dateOfBirth },
      ],
    },
  };

  const { copy, sendButton } = messages;

  const reviewRx = {
    notesToPharmacy: gpmApproval?.noteToPharmacy ?? gpmRenewal?.noteToPharmacy ?? "",
    directions: data.retrieveRxByGuidOrClaimCheckGuid.directions || gpmApproval?.directions || gpmRenewal?.directions || "",
    expirationDate: gpmApproval?.expirationDate || getExpirationDate(),
    totalRefills: getTotalRefills(data.retrieveRxByGuidOrClaimCheckGuid.refills, gpmProduct?.isControlled) || gpmRenewal?.refills || gpmRenewal?.refills || null,
    isPrn: false,
    flavorCodes: gpmProduct?.flavorCodes || gpmApproval?.flavorCodes || gpmRenewal?.flavorCodes || null,
    selectedFlavor: gpmApproval?.flavorCode || gpmRenewal?.flavorCode || null,
    authorizingVets: gpmAuthorizingVetsSorted ?? [],
    autoship: shouldDefaultAutoShipOn(gpmProduct, gpmApproval, gpmRenewal),
    productStatusCode: gpmProduct?.productStatusCode || gpmApproval?.baseProductStatusCode || gpmRenewal.baseProductStatusCode || "",
    baseProductStatusCode: gpmProduct?.baseProductStatusCode || "",
    isControlled: gpmProduct?.isControlled || gpmApproval?.isControlled || gpmRenewal?.isControlled || false,
    isEssentialCopy: gpmProduct?.isEssentialCopy || false,
    numberOfRefills: computeNumberOfRefills(gpmProduct?.isControlled) || gpmApproval?.refills || gpmRenewal?.refills || 0,
    clientDOB: gpmClient.dateOfBirth || null,
    defaultSupplyCount:
      gpmProduct?.defaultSupplyCount || parseInt(props.claimCheck?.payload?.rx?.quantity || "") || gpmApproval?.productQty || gpmRenewal?.qtyPerFill || 1,
    linkedGuids,
    productStrength: gpmProduct?.strength || gpmApproval?.productStrength || gpmRenewal.productStrength || "",
    authorizedWithChanges: data.retrieveRxByGuidOrClaimCheckGuid.authorizedWithChanges || false,
    packageQuantity: gpmProduct?.size,
    units: gpmApproval?.qtyOrdered && gpmApproval.productQty ? (gpmApproval?.qtyOrdered / gpmApproval?.productQty) : 1,
    authorizingVet: data.retrieveRxByGuidOrClaimCheckGuid.prescribingVetKey || user.veterinarianKey || gpmApproval?.prescribingVetKey || gpmRenewal?.prescribingVetKey || "",
    veterinarianLicenseKey: "",
    vetDelegateLevels: user.vetDelegateLevels || [],
    userVeterinarianKey: user.veterinarianKey,
    legalCategoryCode: r.toUpper(gpmProduct?.legalCategoryCode || gpmApproval?.legalCategoryCode || gpmRenewal.legalCategoryCode || legalCategoryCodeEnum.RX),
    treatmentCategories: gpmBaseProduct?.treatmentCategories || [],
    is503B: get503B()
  };

  const relevantFieldsForConflict = {
    clientDOB: gpmClient.dateOfBirth,
    baseProductKey: data?.retrieveRxByGuidOrClaimCheckGuid?.baseProductKey || gpmApprovalQuery?.data?.getApproval?.baseProductKey || gpmRenewalQuery?.data?.getRenewal?.baseProductKey || "",
    childProductKey: productKey ? productKey : null,
    clientKey: gpmPatientQuery?.data?.getPatient?.clientKey || "",
    patientKey: gpmPatientQuery?.data?.getPatient?.key || "",
    unitsPerFill: gpmRenewalQuery?.data?.getRenewal?.qtyPerFill || 1,
    directions: data?.retrieveRxByGuidOrClaimCheckGuid?.directions ?? gpmApprovalQuery?.data?.getApproval?.directions ?? gpmRenewalQuery?.data?.getRenewal?.directions ?? "",
    acbKey: user?.animalCareBusinessKey,
    dosageTypeCode: dosageVariant
  };

  const pimsSpecificCopy = {
    ...copy,
    partTwo: copy.partTwo(getDisplayPimsName(maybePimsType)),
  };

  let requestType = "";
  if (data.retrieveRxByGuidOrClaimCheckGuid.approvalKey) {
    requestType = WorkFlowType.APPROVALS;
  } else if (data.retrieveRxByGuidOrClaimCheckGuid.renewalKey) {
    requestType = WorkFlowType.RENEWALS;
  }

  const legalCategoryCode = gpmProduct?.legalCategoryCode || gpmApproval?.legalCategoryCode || gpmRenewal?.legalCategoryCode || "";
  const finishScriptProps = {
    copy: pimsSpecificCopy,
    sendButton,
    submitRx,
    legalCategoryCode: r.toUpper(legalCategoryCode) || legalCategoryCodeEnum.RX,
    reviewRx,
    relevantFieldsForConflict,
    setIsFormSubmitting,
    pendingRequestType: requestType,
    isProductOrBaseProductDiscontinuedOrInactive
  };

  const updatePatientWeightModal = () => {
    if (showUpdatePatientWeightModal && gpmPatient)
      return <UpdatePatientWeightModal
        patient={gpmPatient}
        onClose={() => setShowUpdatePatientWeightModal(false)}
        onFinish={() => {
          dispatch(
            addToast({
              message: messages.updateWeightSuccessful,
              id: generateToastID(),
              kind: "success",
            }),
          );
          setShowUpdatePatientWeightModal(false);
          //gpmPatientQuery.refetch();
        }}
        onError={(err) => {
          dispatch(
            addToast({
              message: messages.updateWeightFailure,
              id: generateToastID(),
              kind: "error",
            }),
          );
        }}
      />;
    return null;
  };


  return (
    <Container>
      {updatePatientWeightModal()}
      <DescriptionComponent {...messages} pimsType={maybePimsType} username={loggedInUsername || ""} claimCheckKey={claimCheckKey} />
      <CollapsableCard {...patientProps} />
      <CollapsableCard {...productProps} />
      <FinishScriptWrapper>
        <FinishScriptCard {...finishScriptProps} />
      </FinishScriptWrapper>
    </Container>
  );
};

export default ReviewRx;
