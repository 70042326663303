import * as Yup from "yup";
import flaggedMessages from "../Subcomponents/FlaggedIssuesError/messages";
import { FlaggedGridValues } from "../Subcomponents/FlaggedIssuesError/types";
import messages from "../messages";
import { FlavorCodeT, VetsForPracticeUserT } from "../../../data/gql/apiTypes";
import {
  checkVetLicenseInvalid,
  checkVetDeaLicenseInvalid,
  checkVetDeaLicenseExpired,
  checkBasicDelegatePermission,
  checkVetSelectOtherVetForControlledSubstance,
  checkVetLicenseExpiredByLicenseKey,
} from "../Subcomponents/AuthorizingVet/utils";
import * as r from "ramda";
import { DEFAULT_NOTIFY_DATE, DEFAULT_UTC_NOTIFY_DATE } from "../Subcomponents/NotificationField/Notification";
import { FinishScriptFormVals, Props } from "./types";
import { LinkedGuids } from "../types";
import { AcknowledgeError } from "../AcknowledgeErrors/types";
import { ApprovalWithChangeReason } from "../../ApproveWithChangesDialog/types";
import getFourByteUtfCharacters from "../../../util";

export const getFlavorsHaveLength = (flavorCodes: FlavorCodeT[]) => r.length(flavorCodes) > 0;

export const flaggedIssuesHasLength = (flaggedGridValues: FlaggedGridValues[]): boolean =>
  r.length(flaggedGridValues) > 0;

export const getSmartScribeError = (flaggedGridValues: FlaggedGridValues[]): string[] => {
  const smartScribeArray: string[] = [];
  flaggedGridValues.forEach(flagged => {
    switch (flagged.label.toLocaleLowerCase()) {
      case flaggedMessages.flaggedLabels.directionsKeywords.toLocaleLowerCase(): smartScribeArray.push(AcknowledgeError.DIRECTIONS);
        break;
      case flaggedMessages.flaggedLabels.weight.toLocaleLowerCase(): smartScribeArray.push(AcknowledgeError.WEIGHT_OUT_OF_RANGE);
        break
      case flaggedMessages.flaggedLabels.species.toLocaleLowerCase(): smartScribeArray.push(AcknowledgeError.SPECIES_MISMATCH);
        break;
    }
  });
  return smartScribeArray;
}

export const getDefaultVet = (authorizingVets: VetsForPracticeUserT[]) =>
  authorizingVets
    ? r.find((vet) => {
      return vet.isDefault;
    }, authorizingVets)
    : null;

export const getFormSchema = (flagged: FlaggedGridValues[] | null, reviewRx: any) => {
  const mustOverrideReason = flagged?.filter((flag) => flag.required) ?? [];
  const baseValidationSchema = {
    expiration: Yup.date()
      .required(messages.validations.required)
      .min(new Date(new Date().setHours(24, 0, 0, 0)), messages.validations.dateInPast)
      .max(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), messages.validations.expOneYearGreater)
      .when("isControlled", {
        is: true,
        then: Yup.date().max(
          new Date(new Date().setMonth(new Date().getMonth() + 6)),
          messages.validations.contExpSixMonthsGreater,
        ),
      }),
    units: Yup.number()
      .required(messages.validations.required)
      .positive(messages.validations.unitsPerFillZero)
      .max(999, messages.validations.unitsPerFillMax)
      //when the product lacks the packageQuantity info, the units per fill turns out to be negative which should be validated.
      .test("packageQuantityValidation", messages.validations.unitsPerFillZero, function () {
        return reviewRx;
      }),
    refills: Yup.string().required(messages.validations.required),
    notification: Yup.date()
      .required(messages.validations.required)
      .min(new Date(new Date().setHours(0, 0, 0, 0)), messages.validations.dateInPast),
    sigShortcuts: Yup.string(),
    directions: Yup.string().required(messages.validations.required).max(180, messages.validations.directions)
      .test("UTF_FOUND", messages.validations.utfFound, (directions) => getFourByteUtfCharacters(directions)),
    notesToClientShortcuts: Yup.string(),
    notesToClient: Yup.string().max(1000, messages.validations.notesToClient)
      .test("UTF_FOUND", messages.validations.utfFound, (notesToClient) => getFourByteUtfCharacters(notesToClient)),
    notesToPharmacy: Yup.string().max(512, messages.validations.notesToPharmacy)
      .test("UTF_FOUND", messages.validations.utfFound, (notesToPharmacy) => getFourByteUtfCharacters(notesToPharmacy)),
    autoship: Yup.boolean(),
    isExternalPharmacy: Yup.boolean(),
    veterinarianLicenseKey: Yup.string()
      .required(messages.validations.required)
      .test("VET_LICENSE_EXPIRED", messages.validations.authorizingVet.vetLicenseExpired, (vetKey) =>
        checkVetLicenseExpiredByLicenseKey(reviewRx.authorizingVets, vetKey)),
    authorizingVet: Yup.string()
      .required(messages.validations.required)
      .test("VET_LICENSE_INVALID", messages.validations.authorizingVet.vetLicenseInvalid, (vetKey) =>
        checkVetLicenseInvalid(vetKey, reviewRx.authorizingVets),
      )
      .test("DEA_LICENSE_INVALID", messages.validations.authorizingVet.deaLicenseInvalid, (vetKey) =>
        checkVetDeaLicenseInvalid(vetKey, reviewRx.authorizingVets, reviewRx.isControlled),
      )
      .test("DEA_LICENSE_EXPIRED", messages.validations.authorizingVet.deaLicenseExpired, (vetKey) =>
        checkVetDeaLicenseExpired(vetKey, reviewRx.authorizingVets, reviewRx.isControlled),
      )
      .test("STAFF_NEED_ADVANCE_DELEGATION", messages.validations.authorizingVet.staffBasicDelegate, (vetKey) =>
        !checkBasicDelegatePermission(vetKey, reviewRx.vetDelegateLevels, reviewRx.legalCategoryCode, reviewRx.treatmentCategories),
      )
      .test("VET_CHOSE_DELGATE_FOR_CONTROLLED", messages.validations.authorizingVet.vetDelegate, (vetKey) =>
        checkVetSelectOtherVetForControlledSubstance(vetKey, reviewRx.vetDelegateLevels, reviewRx.isControlled, reviewRx.userVeterinarianKey),
      ),
    ...(reviewRx.authorizedWithChanges && {
      approveWithChangeReason: Yup.string().required(messages.validations.required),
      approveWithChangeClientNote: Yup.string().required(messages.validations.required),
    }),
  };

  if (flagged && flagged.length > 0) {
    return Yup.object().shape({
      ...baseValidationSchema,
      ...(reviewRx.isEssentialCopy && {
        essentialCopy: Yup.string().required(messages.validations.medicalRationaleRequired),
      }),
      otherEssentialCopyReason: Yup.string()
        .test("UTF_FOUND", messages.validations.utfFound, (otherEssentialCopyReason) => getFourByteUtfCharacters(otherEssentialCopyReason))
        .when("essentialCopy", {
          is: "OTHER",
          then: Yup.string().required(messages.validations.medicalRationaleRequired)
        }),
      ...(mustOverrideReason.length > 0 && {
        reason: Yup.string()
          .min(5, messages.validations.reasonMin)
          .max(512, messages.validations.reasonMax)
          .required(flaggedMessages.reasonHelpText)
          .test("UTF_FOUND", messages.validations.utfFound, (reason) => getFourByteUtfCharacters(reason)),
      }),
    });
  }
  return Yup.object().shape(baseValidationSchema);
};

const getSixMonthsFromYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const sixMonthsFromYesterday = new Date(yesterday);
  sixMonthsFromYesterday.setMonth(sixMonthsFromYesterday.getMonth() + 6);
  return sixMonthsFromYesterday.toDateString();
};

export const getInitialValues = (reviewRx: Props["reviewRx"]) => {
  const defaultVet = getDefaultVet(reviewRx.authorizingVets);
  return {
    isControlled: reviewRx.isControlled,
    flavor:
      getFlavorsHaveLength(reviewRx.flavorCodes || []) && reviewRx.flavorCodes
        ? reviewRx.selectedFlavor || reviewRx.flavorCodes[0].flavorCode : undefined,
    units: reviewRx?.units || 0,
    expiration: reviewRx.expirationDate || getSixMonthsFromYesterday(),
    refills: reviewRx.totalRefills || "0",
    isPrn: reviewRx.isPrn || false,
    notification: DEFAULT_NOTIFY_DATE,
    sigShortcuts: "",
    directions: reviewRx.directions,
    notesToClientShortcuts: "",
    notesToClient: "",
    notesToPharmacy: reviewRx.notesToPharmacy,
    autoship: reviewRx.autoship,
    authorizingVet: defaultVet?.veterinarianKey || reviewRx.authorizingVet || "",
    strength: reviewRx.productStrength,
    isExternalPharmacy: false,
    approveWithChangeReason: ApprovalWithChangeReason.INCORRECT_STRENGTH_QUANTITY_OR_PRODUCT,
    approveWithChangeClientNote: "",
    legalCategoryCode: reviewRx.legalCategoryCode,
    veterinarianLicenseKey: "",
  };
};

export const transformRxForApi = (
  values: FinishScriptFormVals,
  linkedGuids: LinkedGuids,
  claimCheckKey: string,
  defaultSupplyCount: number,
) => {
  return {
    rx: {
      guid: linkedGuids.prescription,
      prescribingVetKey: values.authorizingVet,
      claimCheckKey,
      linkedClientKey: linkedGuids.client,
      linkedPatientKey: linkedGuids.patient,
      linkedProductKey: linkedGuids.product,
      baseProductKey: linkedGuids.baseProduct,
      units: values.units || 0,
      maxQtyPerFill: values.units ? values.units * defaultSupplyCount : 1,
      refills: values.refills === "PRN" ? '0' : values.refills,
      isPrn: values.isPrn,
      expirationDate: values.expiration,
      notificationDisplayDate: values.notification === DEFAULT_NOTIFY_DATE ? DEFAULT_UTC_NOTIFY_DATE : DEFAULT_NOTIFY_DATE,
      sigShortcut: values.sigShortcuts,
      directions: values.directions,
      notesToClientShortcut: values.notesToClientShortcuts,
      notesToClient: values.notesToClient,
      notesToPharmacy: values.notesToPharmacy,
      hasRecommendedAutoship: values.autoship,
      productFlavor: values.flavor || null,
      strength: values.strength,
      isExternalPharmacy: values.isExternalPharmacy,
      acknowledgeErrors: values.acknowledgeErrors ?? [],
      smartScribeOverrideReason: values.reason,
      essentialCopyJustification: values.otherEssentialCopyReason ? values.essentialCopy + "." + values.otherEssentialCopyReason : values.essentialCopy,
      approveWithChangeReason: values.approveWithChangeReason,
      approveWithChangesClientNote: values.approveWithChangeClientNote,
      approvalKey: linkedGuids.approvalKey,
      renewalKey: linkedGuids.renewalKey,
      legalCategoryCode: values.legalCategoryCode,
      veterinarianLicenseKey: values.veterinarianLicenseKey,
    },
  };
};
