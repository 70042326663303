import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  LocalDateTime: any;
  UUID: string;
};

export type ApprovalT = {
  __typename?: "ApprovalT";
  approvableStatusCode: Maybe<Scalars["String"]>;
  approvableKey: Maybe<Scalars["String"]>;
  prescriptionKey: Maybe<Scalars["String"]>;
  prescriptionTypeCode: Maybe<Scalars["String"]>;
  baseProductKey: Maybe<Scalars["String"]>;
  baseProductStatusCode: Maybe<Scalars["String"]>;
  productFamilyCode: Maybe<Scalars["String"]>;
  productName: Maybe<Scalars["String"]>;
  productStrength: Maybe<Scalars["String"]>;
  productUOM: Maybe<Scalars["String"]>;
  productQty: Maybe<Scalars["Int"]>;
  variantAttributeSelections: Maybe<Array<Maybe<VariantAttributeSelections>>>;
  qtyOrdered: Maybe<Scalars["Int"]>;
  patientKey: Maybe<Scalars["String"]>;
  patientName: Maybe<Scalars["String"]>;
  patientWeight: Maybe<Scalars["String"]>;
  patientWeightCode: Maybe<Scalars["String"]>;
  patientSpeciesCode: Maybe<Scalars["String"]>;
  patientBreedCode: Maybe<Scalars["String"]>;
  patientIsPimsLinked: Maybe<Scalars["Boolean"]>;
  clientKey: Maybe<Scalars["String"]>;
  clientGivenName: Maybe<Scalars["String"]>;
  clientFamilyName: Maybe<Scalars["String"]>;
  clientEmail: Maybe<Scalars["String"]>;
  clientPhoneNumber: Maybe<Scalars["String"]>;
  prescribingVetKey: Maybe<Scalars["String"]>;
  prescribingVetGivenName: Maybe<Scalars["String"]>;
  prescribingVetFamilyName: Maybe<Scalars["String"]>;
  workflowStatusCode: Maybe<Scalars["String"]>;
  approvableNote: Maybe<Scalars["String"]>;
  isAutoship: Maybe<Scalars["Boolean"]>;
  veterinarianLicenseKey: Maybe<Scalars["String"]>;
  flavorCode: Maybe<Scalars["String"]>;
  directions: Maybe<Scalars["String"]>;
  noteToPharmacy: Maybe<Scalars["String"]>;
  smartScribeOverrideReason: Maybe<Scalars["String"]>;
  expirationDate: Maybe<Scalars["String"]>;
  refills: Maybe<Scalars["String"]>;
  speciesCategoryCodes: Maybe<Array<Scalars["String"]>>;
  isControlled: Maybe<Scalars["Boolean"]>;
  legalCategoryCode: Maybe<Scalars["String"]>;
  flavorCodes: Maybe<Array<FlavorCodeT>>;
};

export type ApprovalsT = {
  __typename?: "ApprovalsT";
  approvableKey: Maybe<Scalars["String"]>;
  prescriptionKey: Maybe<Scalars["String"]>;
  clientFirstName: Maybe<Scalars["String"]>;
  clientLastName: Maybe<Scalars["String"]>;
  patientName: Maybe<Scalars["String"]>;
  patientSpecies: Maybe<Scalars["String"]>;
  patientWeight: Maybe<Scalars["String"]>;
  productName: Maybe<Scalars["String"]>;
  productStrength: Maybe<Scalars["String"]>;
  orderedQty: Maybe<Scalars["String"]>;
  orderedOnDate: Maybe<Scalars["String"]>;
  orderNumber: Maybe<Scalars["String"]>;
  shippingType: Maybe<Scalars["String"]>;
  prescribingVetName: Maybe<Scalars["String"]>;
  prescribingVetKey: Maybe<Scalars["String"]>;
  workflowStatusCode: Maybe<Scalars["String"]>;
};

export type BaseProductFamilyT = {
  __typename?: "BaseProductFamilyT";
  familyCode: Scalars["String"];
  name: Scalars["String"];
};

export type BaseProductLegalCategoryT = {
  __typename?: "BaseProductLegalCategoryT";
  country: Maybe<Scalars["String"]>;
  legalCategoryCode: Maybe<Scalars["String"]>;
  requiresDispensing: Maybe<Scalars["String"]>;
  requiresApproval: Maybe<Scalars["String"]>;
};

export type BaseProductT = {
  __typename?: "BaseProductT";
  baseProductKey: Scalars["String"];
  familyCode: BaseProductFamilyT;
  baseProductLegalCategories: Maybe<BaseProductLegalCategoryT>;
  controlledProducts: Maybe<ControlledProductsT>;
  treatmentCategories: Maybe<Array<Maybe<Scalars["String"]>>>;
  isCompound: Maybe<Scalars["Boolean"]>;
  productManufacturerClassificationCodes: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BreedT = {
  __typename?: "BreedT";
  name: Scalars["String"];
  breedCode: Scalars["String"];
};

export type ClaimCheckClientI = {
  id: Scalars["String"];
  pmsId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  driversLicenseNumber?: Maybe<Scalars["String"]>;
  siteId: Scalars["String"];
};

export type ClaimCheckClientT = {
  __typename?: "ClaimCheckClientT";
  vdsKey: Scalars["String"];
  pmsKey: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Maybe<Scalars["String"]>;
  dateOfBirth: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Scalars["String"]>;
  driversLicenseNumber: Maybe<Scalars["String"]>;
  siteId: Scalars["String"];
};

export type ClaimCheckContextI = {
  localTime: Scalars["String"];
  sessionId: Scalars["String"];
};

export type ClaimCheckContextT = {
  __typename?: "ClaimCheckContextT";
  localTime: Maybe<Scalars["String"]>;
  sessionId: Maybe<Scalars["String"]>;
};

export type ClaimCheckEnteredByResourceI = {
  id: Scalars["String"];
  pmsId: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  siteId: Scalars["String"];
};

export type ClaimCheckEnteredByResourceT = {
  __typename?: "ClaimCheckEnteredByResourceT";
  vdsKey: Scalars["String"];
  pmsKey: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  siteId: Scalars["String"];
};

export type ClaimCheckI = {
  correlationId: Scalars["String"];
  callbackUrl: Scalars["String"];
  originationDateTime: Scalars["LocalDateTime"];
  installationId: Scalars["UUID"];
  eventType: Scalars["String"];
  precedingEventCorrelationId?: Maybe<Scalars["String"]>;
  payload: ClaimCheckPayloadI;
  id: Scalars["String"];
  context: ClaimCheckContextI;
};

export type ClaimCheckPatientI = {
  id: Scalars["String"];
  pmsId: Scalars["String"];
  name: Scalars["String"];
  speciesDescription: Scalars["String"];
  gender?: Maybe<Scalars["String"]>;
  genderDescription?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  currentWeight?: Maybe<Scalars["String"]>;
  currentWeightUnit?: Maybe<Scalars["String"]>;
  siteId: Scalars["String"];
};

export type ClaimCheckPatientT = {
  __typename?: "ClaimCheckPatientT";
  vdsKey: Scalars["String"];
  pmsKey: Scalars["String"];
  name: Scalars["String"];
  speciesDescription: Scalars["String"];
  gender: Maybe<Scalars["String"]>;
  genderDescription: Maybe<Scalars["String"]>;
  dateOfBirth: Maybe<Scalars["String"]>;
  currentWeight: Maybe<Scalars["String"]>;
  currentWeightUnit: Maybe<Scalars["String"]>;
  siteId: Scalars["String"];
};

export type ClaimCheckPayloadI = {
  enteredByResource: ClaimCheckEnteredByResourceI;
  client: ClaimCheckClientI;
  patient: ClaimCheckPatientI;
  product?: Maybe<ClaimCheckProductI>;
  rx?: Maybe<ClaimCheckRxI>;
  sourceEntity: ClaimCheckSourceEntityI;
};

export type ClaimCheckPayloadT = {
  __typename?: "ClaimCheckPayloadT";
  client: Maybe<ClaimCheckClientT>;
  patient: Maybe<ClaimCheckPatientT>;
  product: Maybe<ClaimCheckProductT>;
  rx: Maybe<ClaimCheckRxT>;
};

export type ClaimCheckProductI = {
  id: Scalars["String"];
  pmsId: Scalars["String"];
  code?: Maybe<Scalars["String"]>;
  form?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  strength?: Maybe<Scalars["String"]>;
  msdsNdc?: Maybe<Scalars["String"]>;
};

export type ClaimCheckProductT = {
  __typename?: "ClaimCheckProductT";
  vdsKey: Scalars["String"];
  pmsKey: Scalars["String"];
  code: Maybe<Scalars["String"]>;
  form: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  strength: Maybe<Scalars["String"]>;
  msdsNdc: Maybe<Scalars["String"]>;
};

export type ClaimCheckRxI = {
  id: Scalars["String"];
  pmsId: Scalars["String"];
  expirationDate?: Maybe<Scalars["LocalDateTime"]>;
  quantity?: Maybe<Scalars["String"]>;
  totalRefills?: Maybe<Scalars["String"]>;
  units?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  strength?: Maybe<Scalars["String"]>;
};

export type ClaimCheckRxT = {
  __typename?: "ClaimCheckRxT";
  vdsKey: Scalars["String"];
  pmsKey: Scalars["String"];
  expirationDate: Maybe<Scalars["LocalDateTime"]>;
  quantity: Maybe<Scalars["String"]>;
  totalRefills: Maybe<Scalars["String"]>;
  units: Maybe<Scalars["String"]>;
  directions: Maybe<Scalars["String"]>;
  strength: Maybe<Scalars["String"]>;
  gpmGuid: Maybe<Scalars["UUID"]>;
  requestType: Maybe<Scalars["String"]>;
  workflowStatus: Maybe<Scalars["String"]>;
};

export type ClaimCheckSourceEntityI = {
  id: Scalars["String"];
  type: Scalars["String"];
  siteId: Scalars["String"];
};

export type ClaimCheckSourceEntityT = {
  __typename?: "ClaimCheckSourceEntityT";
  vdsId: Scalars["String"];
  type: Scalars["String"];
  siteId: Scalars["String"];
};

export type ClaimCheckT = {
  __typename?: "ClaimCheckT";
  claimCheckKey: Scalars["UUID"];
  animalCareBusinessKey: Scalars["String"];
  platformAccountKey: Scalars["String"];
  callbackUrl: Scalars["String"];
  correlationKey: Scalars["String"];
  originationDateTime: Scalars["LocalDateTime"];
  installationKey: Scalars["UUID"];
  eventType: Scalars["String"];
  pims: Scalars["String"];
  precedingEventCorrelationId: Maybe<Scalars["String"]>;
  payload: ClaimCheckPayloadT;
  context: Maybe<ClaimCheckContextT>;
};

export type ClientImportI = {
  givenName: Scalars["String"];
  familyName: Scalars["String"];
  emailAddress: Scalars["String"];
  claimCheckKey: Scalars["String"];
  dateOfBirth: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type ClientSearchCriteriaI = {
  query: Scalars["String"];
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  filter?: Maybe<DomainSearchRequestFilterI>;
};

export type ClientT = {
  __typename?: "ClientT";
  key: Scalars["UUID"];
  givenName: Maybe<Scalars["String"]>;
  familyName: Scalars["String"];
  phoneNumber: Maybe<Scalars["String"]>;
  emailAddress: Scalars["String"];
  dateOfBirth: Maybe<Scalars["String"]>;
};

export type ControlledProductsT = {
  __typename?: "ControlledProductsT";
  country: Maybe<Scalars["String"]>;
  stateOrProvince: Maybe<Scalars["String"]>;
  controlledClassCode: Maybe<Scalars["String"]>;
};

export type CreateClientResponseT = {
  clientKey: Scalars["UUID"];
};

export type CreatePatientResponseT = {
  patientKey: Scalars["UUID"];
};

export type CreateRxI = {
  baseProductKey?: Maybe<Scalars["String"]>;
  productKey: Scalars["String"];
  claimCheckKey: Scalars["String"];
  installationKey?: Maybe<Scalars["String"]>;
  patientKey?: Maybe<Scalars["String"]>;
  clientKey?: Maybe<Scalars["String"]>;
  approvalKey?: Maybe<Scalars["String"]>;
  renewalKey?: Maybe<Scalars["String"]>;
};

export type DeaVeterinarianLicense = {
  __typename?: "DeaVeterinarianLicense";
  deaRegistrationNumber: Maybe<Scalars["String"]>;
  expirationDate: Maybe<Scalars["String"]>;
};

export type DeclineApprovalI = {
  approvableKey: Scalars["String"];
  declineReason: Scalars["String"];
  noteToClient: Scalars["String"];
  declineVet: Scalars["String"];
  authorizingVet?: Maybe<Scalars["String"]>;
};

export type DomainSearchRequestFilterI = {
  isActive?: Maybe<Scalars["Boolean"]>;
  isClientActive?: Maybe<Scalars["Boolean"]>;
  isLinked?: Maybe<Scalars["Boolean"]>;
  isActivePatient?: Maybe<Scalars["Boolean"]>;
  isLinkedPatient?: Maybe<Scalars["Boolean"]>;
  isCompound?: Maybe<Scalars["Boolean"]>;
  categoryCode?: Maybe<Scalars["String"]>;
  treatmentCategoryCode?: Maybe<Scalars["String"]>;
  speciesCode?: Maybe<Scalars["String"]>;
  brandCode?: Maybe<Scalars["String"]>;
  primaryManufacturerCode?: Maybe<Scalars["String"]>;
  catalogCategoryCode?: Maybe<Scalars["String"]>;
  baseProductStatus?: Maybe<Scalars["String"]>;
  baseProductKey?: Maybe<Scalars["String"]>;
  familyCode?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
  minStrength?: Maybe<Scalars["String"]>;
  maxStrength?: Maybe<Scalars["String"]>;
};

export type FamilyProductSearchCriteriaI = {
  query: Scalars["String"];
  filter: DomainSearchRequestFilterI;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type FlavorCodeT = {
  __typename?: "FlavorCodeT";
  flavorCode: Scalars["String"];
  position: Scalars["Int"];
  isVisibleToConsumer: Scalars["Boolean"];
};

export type FullPracticeUserT = {
  __typename?: "FullPracticeUserT";
  userKey: Scalars["String"];
  username: Maybe<Scalars["String"]>;
  givenName: Scalars["String"];
  familyName: Scalars["String"];
  email: Scalars["String"];
  practiceUserTypeCode: Maybe<Scalars["String"]>;
  vetDelegateLevels: Maybe<Array<UserVetDelegateLevelT>>;
  animalCareBusinessKey: Scalars["String"];
  isActive: Maybe<Scalars["Boolean"]>;
  hasAccessPrescriptionPermission: Scalars["Boolean"];
  veterinarianKey: Maybe<Scalars["String"]>;
  isDeaLicenceActive: Maybe<Scalars["Boolean"]>;
};

export type GetShortcutsInputI = {
  queryText?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createClaimCheck: Scalars["UUID"];
  createProductLink: Scalars["UUID"];
  importClient: Scalars["UUID"];
  importPatient: Scalars["UUID"];
  createPatientLink: Scalars["UUID"];
  submitPrescription: Scalars["UUID"];
  declineApproval: Scalars["UUID"];
  updateRxWithUnlinkedProduct: Scalars["UUID"];
  createDraftRxWithUnlinkedProduct: Scalars["UUID"];
  unlinkProductLink: Scalars["Boolean"];
  updatePatientWeight: Scalars["Boolean"];
  updateRxWithWorkflowStatus: Scalars["Boolean"];
  updateApprovalVet: Scalars["Boolean"];
  declinePrescription: Scalars["Boolean"];
  createOrUpdateDraftPrescription: Scalars["Boolean"];
  testCreateOrUpdatePendingAuthRequest: Scalars["Boolean"];
  removePrescription: Scalars["Boolean"];
  removeRenewal: Scalars["String"];
};

export type MutationcreateClaimCheckArgs = {
  claimCheck: ClaimCheckI;
};

export type MutationcreateProductLinkArgs = {
  productLink: ProductLinkI;
};

export type MutationimportClientArgs = {
  client: ClientImportI;
};

export type MutationimportPatientArgs = {
  patient: PatientImportI;
};

export type MutationcreatePatientLinkArgs = {
  patientLink: PatientLinkI;
};

export type MutationsubmitPrescriptionArgs = {
  prescription: PrescriptionI;
};

export type MutationdeclineApprovalArgs = {
  declineApproval: DeclineApprovalI;
};

export type MutationupdateRxWithUnlinkedProductArgs = {
  baseProductKey: Scalars["UUID"];
  productKey: Scalars["UUID"];
  claimCheckKey: Scalars["UUID"];
  pimsPrescriptionKey: Maybe<Scalars["UUID"]>;
};

export type MutationcreateDraftRxWithUnlinkedProductArgs = {
  createRx: CreateRxI;
};

export type MutationunlinkProductLinkArgs = {
  productKey: Scalars["UUID"];
  installationKey: Scalars["UUID"];
  claimCheckKey: Scalars["UUID"];
};

export type MutationupdatePatientWeightArgs = {
  patientKey: Scalars["UUID"];
  weight: Scalars["Float"];
};

export type MutationupdateRxWithWorkflowStatusArgs = {
  key: Scalars["String"];
  workflowType: Scalars["String"];
  workflowStatusCode: Scalars["String"];
};

export type MutationupdateApprovalVetArgs = {
  key: Scalars["String"];
  workflowType: Scalars["String"];
  veterinarianKey: Scalars["String"];
};

export type MutationdeclinePrescriptionArgs = {
  prescriptionGuid: Scalars["UUID"];
  declineReason: Scalars["String"];
  clientNote: Scalars["String"];
};

export type MutationcreateOrUpdateDraftPrescriptionArgs = {
  claimCheckKey: Scalars["UUID"];
  pendingAuthRequestGuid: Scalars["UUID"];
  dvmPrescriptionGuid: Scalars["UUID"];
};

export type MutationtestCreateOrUpdatePendingAuthRequestArgs = {
  dvmPrescriptionGuid: Scalars["UUID"];
  pendingAuthRequestType: Maybe<Scalars["String"]>;
};

export type MutationremovePrescriptionArgs = {
  prescriptionGuid: Scalars["UUID"];
};

export type MutationremoveRenewalArgs = {
  renewalKey: Scalars["UUID"];
};

export type NotesShortcutsT = {
  __typename?: "NotesShortcutsT";
  shortcutKey: Scalars["UUID"];
  label: Scalars["String"];
  note: Scalars["String"];
};

export enum PatientGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  UNKNOWN = "UNKNOWN",
}

export enum PatientGenderAltered {
  ALTERED = "ALTERED",
  UNALTERED = "UNALTERED",
  UNKNOWN = "UNKNOWN",
}

export type PatientImportI = {
  claimCheckKey: Scalars["String"];
  name: Scalars["String"];
  clientKey: Scalars["String"];
  sexCode: PatientGender;
  sexAltered: PatientGenderAltered;
  dateOfBirth: Scalars["Date"];
  weight: Scalars["String"];
  weightUnitCode?: Maybe<WeightUOM>;
  speciesCode: Scalars["String"];
  vdsClientKey?: Maybe<Scalars["String"]>;
  pimsClientKey?: Maybe<Scalars["String"]>;
  vdsPatientKey?: Maybe<Scalars["String"]>;
  pimsPatientKey?: Maybe<Scalars["String"]>;
  installationKey: Scalars["String"];
};

export type PatientLinkI = {
  claimCheckKey: Scalars["String"];
  patientKey: Scalars["String"];
  installationKey: Scalars["String"];
  clientKey: Scalars["String"];
  vdsPatientKey: Scalars["String"];
  pimsPatientKey: Scalars["String"];
  vdsClientKey: Scalars["String"];
  pimsClientKey: Scalars["String"];
};

export type PatientSearchCriteriaI = {
  query: Scalars["String"];
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  filter?: Maybe<DomainSearchRequestFilterI>;
};

export type PatientT = {
  __typename?: "PatientT";
  key: Scalars["String"];
  name: Scalars["String"];
  clientKey: Scalars["String"];
  clientName: Scalars["String"];
  isClientActive: Maybe<Scalars["Boolean"]>;
  speciesCode: Scalars["String"];
  speciesType: Maybe<Scalars["String"]>;
  breedCode: Maybe<Scalars["String"]>;
  sexCode: Maybe<Scalars["String"]>;
  weight: Maybe<Scalars["String"]>;
  weightUnitCode: Maybe<Scalars["String"]>;
  dateOfBirth: Maybe<Scalars["String"]>;
  isActive: Maybe<Scalars["Boolean"]>;
  isLinked: Scalars["Boolean"];
  clientRoleTypeCode: Maybe<Scalars["String"]>;
};

export type PendingAuthRequestClientT = {
  __typename?: "PendingAuthRequestClientT";
  guid: Maybe<Scalars["UUID"]>;
  clientFirstName: Maybe<Scalars["String"]>;
  clientLastName: Maybe<Scalars["String"]>;
  emailAddress: Maybe<Scalars["String"]>;
  phoneNumber: Maybe<Array<Maybe<Scalars["String"]>>>;
  dob: Maybe<Scalars["String"]>;
};

export type PendingAuthRequestForUserT = {
  __typename?: "PendingAuthRequestForUserT";
  approvalPendingAuthRequests: Maybe<Array<Maybe<PendingAuthRequestT>>>;
  renewalPendingAuthRequests: Maybe<Array<Maybe<PendingAuthRequestT>>>;
};

export type PendingAuthRequestOrderT = {
  __typename?: "PendingAuthRequestOrderT";
  guid: Maybe<Scalars["UUID"]>;
  orderNumber: Maybe<Scalars["String"]>;
  orderedOn: Maybe<Scalars["String"]>;
  shippingType: Maybe<Scalars["String"]>;
  orderedQuantity: Maybe<Scalars["Int"]>;
};

export type PendingAuthRequestPatientT = {
  __typename?: "PendingAuthRequestPatientT";
  guid: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
  dob: Maybe<Scalars["String"]>;
  gender: Maybe<Scalars["String"]>;
  species: Maybe<Scalars["String"]>;
  breed: Maybe<Scalars["String"]>;
  weight: Maybe<Scalars["String"]>;
};

export type PendingAuthRequestPrescriptionT = {
  __typename?: "PendingAuthRequestPrescriptionT";
  guid: Maybe<Scalars["UUID"]>;
  workflowStatus: Maybe<Scalars["String"]>;
  requestType: Maybe<Scalars["String"]>;
  updateEventType: Maybe<Scalars["String"]>;
  client: Maybe<PendingAuthRequestClientT>;
  patient: Maybe<PendingAuthRequestPatientT>;
  product: Maybe<PendingAuthRequestProductT>;
  vet: Maybe<PendingAuthRequestVetT>;
  renewalNeededBy: Maybe<Scalars["String"]>;
};

export type PendingAuthRequestProductT = {
  __typename?: "PendingAuthRequestProductT";
  guid: Maybe<Scalars["UUID"]>;
  title: Maybe<Scalars["String"]>;
  strength: Maybe<Scalars["String"]>;
  quantity: Maybe<Scalars["String"]>;
};

export type PendingAuthRequestT = {
  __typename?: "PendingAuthRequestT";
  guid: Maybe<Scalars["UUID"]>;
  rx: Maybe<PendingAuthRequestPrescriptionT>;
  order: Maybe<PendingAuthRequestOrderT>;
};

export type PendingAuthRequestVetT = {
  __typename?: "PendingAuthRequestVetT";
  guid: Maybe<Scalars["UUID"]>;
  name: Maybe<Scalars["String"]>;
};

export type PlatformAccountAddressT = {
  __typename?: "PlatformAccountAddressT";
  addressTypeCode: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  stateOrProvince: Maybe<Scalars["String"]>;
  postalCode: Maybe<Scalars["String"]>;
  line1: Maybe<Scalars["String"]>;
  line2: Maybe<Scalars["String"]>;
  line3: Maybe<Scalars["String"]>;
  city: Maybe<Scalars["String"]>;
};

export type PlatformAccountT = {
  __typename?: "PlatformAccountT";
  platformAccountKey: Maybe<Scalars["String"]>;
  platformAccountEnterpriseNumber: Maybe<Scalars["String"]>;
  primaryAccountCovetrusCustomerNumber: Maybe<Scalars["String"]>;
  displayName: Maybe<Scalars["String"]>;
  address: Maybe<PlatformAccountAddressT>;
};

export type PracticeUserT = {
  __typename?: "PracticeUserT";
  userKey: Scalars["String"];
  givenName: Scalars["String"];
  familyName: Scalars["String"];
  username: Maybe<Scalars["String"]>;
};

export type PrescriptionI = {
  guid: Scalars["UUID"];
  prescribingVetKey: Scalars["UUID"];
  claimCheckKey: Scalars["UUID"];
  linkedClientKey: Scalars["UUID"];
  linkedPatientKey: Scalars["UUID"];
  linkedProductKey: Scalars["UUID"];
  baseProductKey?: Maybe<Scalars["UUID"]>;
  units: Scalars["Int"];
  maxQtyPerFill: Scalars["Int"];
  refills?: Maybe<Scalars["String"]>;
  expirationDate?: Maybe<Scalars["Date"]>;
  notificationDisplayDate: Scalars["Date"];
  strength?: Maybe<Scalars["String"]>;
  sigShortcut?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  notesToClientShortcut?: Maybe<Scalars["String"]>;
  notesToClient?: Maybe<Scalars["String"]>;
  notesToPharmacy?: Maybe<Scalars["String"]>;
  hasRecommendedAutoship: Scalars["Boolean"];
  productFlavor?: Maybe<Scalars["String"]>;
  acknowledgeErrors: Array<Maybe<Scalars["String"]>>;
  isPrn?: Maybe<Scalars["Boolean"]>;
  smartScribeOverrideReason?: Maybe<Scalars["String"]>;
  essentialCopyJustification?: Maybe<Scalars["String"]>;
  isExternalPharmacy?: Maybe<Scalars["Boolean"]>;
  approveWithChangeReason?: Maybe<Scalars["String"]>;
  approveWithChangesClientNote?: Maybe<Scalars["String"]>;
  approvalKey?: Maybe<Scalars["UUID"]>;
  renewalKey?: Maybe<Scalars["UUID"]>;
  legalCategoryCode?: Maybe<Scalars["String"]>;
  veterinarianLicenseKey?: Maybe<Scalars["UUID"]>;
};

export type PrescriptionT = {
  __typename?: "PrescriptionT";
  pimsPrescriptionKey: Scalars["UUID"];
  claimCheckKey: Scalars["UUID"];
  code: Maybe<Scalars["String"]>;
  form: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  dosage: Maybe<Scalars["String"]>;
  strength: Maybe<Scalars["String"]>;
  linkedClientKey: Maybe<Scalars["UUID"]>;
  linkedPatientKey: Maybe<Scalars["UUID"]>;
  linkedProductKey: Maybe<Scalars["UUID"]>;
  unlinkedProductKey: Maybe<Scalars["UUID"]>;
  baseProductKey: Maybe<Scalars["UUID"]>;
  requestedProductKey: Maybe<Scalars["UUID"]>;
  prescribingVetKey: Maybe<Scalars["UUID"]>;
  refills: Maybe<Scalars["String"]>;
  directions: Maybe<Scalars["String"]>;
  expirationDate: Maybe<Scalars["Date"]>;
  pimsType: Scalars["String"];
  userCanEditRx: Maybe<Scalars["Boolean"]>;
  userCanDeclineRx: Maybe<Scalars["Boolean"]>;
  rxHasBeenProcessed: Maybe<Scalars["Boolean"]>;
  status: Maybe<Scalars["String"]>;
  workflowStatus: Maybe<Scalars["String"]>;
  authorizedWithChanges: Maybe<Scalars["Boolean"]>;
  units: Maybe<Scalars["Int"]>;
  prescriptionRequestType: Maybe<Scalars["String"]>;
  legalCategoryCode: Maybe<Scalars["String"]>;
  approvalKey: Maybe<Scalars["String"]>;
  renewalKey: Maybe<Scalars["String"]>;
};

export type ProductFamilySearchCriteriaI = {
  query: Scalars["String"];
  filter?: Maybe<ProductFamilySearchFilter>;
};

export type ProductFamilySearchFilter = {
  isCompound?: Maybe<Scalars["Boolean"]>;
  categoryCode?: Maybe<Scalars["String"]>;
  treatmentCategoryCode?: Maybe<Scalars["String"]>;
  speciesCode?: Maybe<Scalars["String"]>;
  brandCode?: Maybe<Scalars["String"]>;
  primaryManufacturerCode?: Maybe<Scalars["String"]>;
  catalogCategoryCode?: Maybe<Scalars["String"]>;
  baseProductStatus?: Maybe<Scalars["String"]>;
};

export type ProductLinkI = {
  vdsProductKey: Scalars["String"];
  pimsProductKey: Scalars["String"];
  productTitle: Scalars["String"];
  pimsProductTitle: Scalars["String"];
  productKey: Scalars["UUID"];
  installationKey: Scalars["UUID"];
  baseProductKey: Scalars["UUID"];
  claimCheckKey: Scalars["UUID"];
};

export type ProductLinkT = {
  __typename?: "ProductLinkT";
  productLinkKey: Scalars["String"];
  installationKey: Scalars["String"];
  productKey: Scalars["String"];
  productName: Maybe<Scalars["String"]>;
  vdsProductKey: Maybe<Scalars["String"]>;
  pimsProductKey: Maybe<Scalars["String"]>;
  pimsProductName: Maybe<Scalars["String"]>;
  isActive: Maybe<Scalars["Boolean"]>;
  initiatingUserKey: Maybe<Scalars["String"]>;
  baseProductKey: Maybe<Scalars["String"]>;
};

export type ProductLocaleT = {
  __typename?: "ProductLocaleT";
  localeCode: Scalars["String"];
  title: Scalars["String"];
};

export type ProductSearchCriteriaI = {
  query: Scalars["String"];
  filter: DomainSearchRequestFilterI;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type ProductT = {
  __typename?: "ProductT";
  key: Scalars["String"];
  name: Scalars["String"];
  baseProductKey: Scalars["String"];
  baseProductName: Scalars["String"];
  baseProductStatusCode: Maybe<Scalars["String"]>;
  productStatusCode: Maybe<Scalars["String"]>;
  familyCode: Scalars["String"];
  familyName: Maybe<Scalars["String"]>;
  familyNameWithDosage: Scalars["String"];
  normalizedStrengthWithUOM: Maybe<Scalars["String"]>;
  strength: Maybe<Scalars["String"]>;
  speciesCategoryCodes: Maybe<Array<Scalars["String"]>>;
  directions: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  ingredients: Maybe<Scalars["String"]>;
  information: Maybe<Scalars["String"]>;
  precautions: Maybe<Scalars["String"]>;
  unitOfMeasure: Maybe<Scalars["String"]>;
  size: Maybe<Scalars["String"]>;
  legalCategoryCode: Scalars["String"];
  clientRetailPrice: Maybe<Scalars["Float"]>;
  handling: Maybe<Scalars["Float"]>;
  clientSubTotal: Maybe<Scalars["Float"]>;
  patientWeightMin: Maybe<Scalars["Float"]>;
  patientWeightMax: Maybe<Scalars["Float"]>;
  labelKeywords: Maybe<Array<Scalars["String"]>>;
  isControlled: Maybe<Scalars["Boolean"]>;
  isDiet: Maybe<Scalars["Boolean"]>;
  flavorCodes: Maybe<Array<FlavorCodeT>>;
  treatmentCategories: Maybe<Array<Maybe<Scalars["String"]>>>;
  defaultSupplyCount: Maybe<Scalars["Int"]>;
  linkedProductKey: Maybe<Scalars["UUID"]>;
  isEssentialCopy: Maybe<Scalars["Boolean"]>;
  productForm: Maybe<Scalars["String"]>;
  shelfLife: Maybe<Scalars["Int"]>;
  productLink: Maybe<ProductLinkT>;
};

export type Query = {
  __typename?: "Query";
  getPlatformAccount: Maybe<PlatformAccountT>;
  getPatient: Maybe<PatientT>;
  retrieveClaimCheckByGuid: Maybe<ClaimCheckT>;
  retrieveClaimChecks: Array<Maybe<ClaimCheckT>>;
  searchPatients: Array<PatientT>;
  autoCompleteSearchProductFamilies: Array<familyProductT>;
  getBaseProduct: Maybe<BaseProductT>;
  searchProductFamilies: Array<familyProductT>;
  getProduct: Maybe<ProductT>;
  searchProducts: Array<ProductT>;
  searchProductsWithSpeciesAndWeightFilter: Array<ProductT>;
  retrieveStrengthRange: Maybe<Array<Scalars["String"]>>;
  retrieveRxByGuidOrClaimCheckGuid: Maybe<PrescriptionT>;
  retrieveProductLinks: Array<ProductLinkT>;
  retrieveClientByGuid: Maybe<ClientT>;
  searchClients: Array<ClientT>;
  searchApprovals: Maybe<Array<Maybe<ApprovalsT>>>;
  getApproval: Maybe<ApprovalT>;
  getRenewal: Maybe<RenewalT>;
  searchRenewals: Maybe<Array<Maybe<RenewalsT>>>;
  retrieveSIGShortcuts: Maybe<Array<Maybe<SIGShortcutsT>>>;
  retrieveNotesShortcuts: Maybe<Array<Maybe<NotesShortcutsT>>>;
  retrieveVetsForPracticeUser: Maybe<Array<Maybe<VetsForPracticeUserT>>>;
  retrievePracticeUser: Maybe<FullPracticeUserT>;
  retrievePendingAuthRequestForUser: Maybe<PendingAuthRequestForUserT>;
  retrievePendingAuthRequestForVet: Maybe<PendingAuthRequestForUserT>;
  retrieveSpecies: Array<SpeciesT>;
  validateSmartScribe: Maybe<ValidateSmartScribeT>;
};

export type QuerygetPlatformAccountArgs = {
  acbKey: Scalars["String"];
  platformAccountKey: Scalars["String"];
};

export type QuerygetPatientArgs = {
  key: Scalars["UUID"];
};

export type QueryretrieveClaimCheckByGuidArgs = {
  guid: Scalars["UUID"];
};

export type QuerysearchPatientsArgs = {
  searchCriteria: PatientSearchCriteriaI;
};

export type QueryautoCompleteSearchProductFamiliesArgs = {
  criteria: FamilyProductSearchCriteriaI;
};

export type QuerygetBaseProductArgs = {
  guid: Scalars["UUID"];
};

export type QuerysearchProductFamiliesArgs = {
  criteria: FamilyProductSearchCriteriaI;
};

export type QuerygetProductArgs = {
  baseProductKey: Scalars["UUID"];
  productKey: Scalars["UUID"];
  claimCheckKey: Maybe<Scalars["UUID"]>;
};

export type QuerysearchProductsArgs = {
  criteria: ProductSearchCriteriaI;
  claimCheckKey: Maybe<Scalars["UUID"]>;
};

export type QuerysearchProductsWithSpeciesAndWeightFilterArgs = {
  criteria: ProductSearchCriteriaI;
  claimCheckKey: Maybe<Scalars["UUID"]>;
};

export type QueryretrieveStrengthRangeArgs = {
  criteria: ProductSearchCriteriaI;
};

export type QueryretrieveRxByGuidOrClaimCheckGuidArgs = {
  claimCheckKey: Scalars["UUID"];
  pimsPrescriptionKey: Maybe<Scalars["UUID"]>;
};

export type QueryretrieveProductLinksArgs = {
  installationGuid: Scalars["UUID"];
  vdsId: Scalars["String"];
};

export type QueryretrieveClientByGuidArgs = {
  guid: Scalars["UUID"];
};

export type QuerysearchClientsArgs = {
  searchCriteria: ClientSearchCriteriaI;
};

export type QuerysearchApprovalsArgs = {
  searchApprovals: SearchApprovalsCriteriaI;
};

export type QuerygetApprovalArgs = {
  approvableKey: Scalars["String"];
};

export type QuerygetRenewalArgs = {
  renewalKey: Scalars["String"];
};

export type QuerysearchRenewalsArgs = {
  searchRenewals: SearchRenewalsCriteriaI;
};

export type QueryretrieveSIGShortcutsArgs = {
  getShortcutsInput: Maybe<GetShortcutsInputI>;
};

export type QueryretrieveNotesShortcutsArgs = {
  getShortcutsInput: Maybe<GetShortcutsInputI>;
};

export type QueryretrievePendingAuthRequestForVetArgs = {
  vetGuid: Scalars["UUID"];
};

export type QueryvalidateSmartScribeArgs = {
  validateCriteria: ValidateSmartScribeI;
};

export type RenewalT = {
  __typename?: "RenewalT";
  renewableKey: Maybe<Scalars["String"]>;
  previousPrescriptionKey: Maybe<Scalars["String"]>;
  nextPrescriptionKey: Maybe<Scalars["String"]>;
  prescriptionTypeCode: Maybe<Scalars["String"]>;
  clientKey: Maybe<Scalars["String"]>;
  clientPrimaryPhone: Maybe<Scalars["String"]>;
  clientPrimaryEmail: Maybe<Scalars["String"]>;
  patientWeight: Maybe<Scalars["String"]>;
  isPatientPimsLinked: Maybe<Scalars["Boolean"]>;
  patientKey: Maybe<Scalars["String"]>;
  practiceKey: Maybe<Scalars["String"]>;
  baseProductKey: Maybe<Scalars["String"]>;
  productStrength: Maybe<Scalars["String"]>;
  productQty: Maybe<Scalars["Int"]>;
  productUOM: Maybe<Scalars["String"]>;
  isControlled: Maybe<Scalars["Boolean"]>;
  legalCategoryCode: Maybe<Scalars["String"]>;
  variantAttributeTypeCode: Maybe<Scalars["String"]>;
  variantAttributeValueCode: Maybe<Scalars["String"]>;
  dosageName: Maybe<Scalars["String"]>;
  familyCode: Maybe<Scalars["String"]>;
  notesToClient: Maybe<Scalars["String"]>;
  notificationDate: Maybe<Scalars["String"]>;
  prescribingVetKey: Maybe<Scalars["String"]>;
  prescribingVetGivenName: Maybe<Scalars["String"]>;
  prescribingVetFamilyName: Maybe<Scalars["String"]>;
  createdByUserKey: Maybe<Scalars["String"]>;
  modifiedByUserKey: Maybe<Scalars["String"]>;
  modifiedByUserGivenName: Maybe<Scalars["String"]>;
  modifiedByFamilyName: Maybe<Scalars["String"]>;
  notificationDisplayDate: Maybe<Scalars["String"]>;
  clientFirstName: Maybe<Scalars["String"]>;
  clientLastName: Maybe<Scalars["String"]>;
  patientName: Maybe<Scalars["String"]>;
  productName: Maybe<Scalars["String"]>;
  speciesCode: Maybe<Scalars["String"]>;
  isPrn: Maybe<Scalars["Boolean"]>;
  reminderDate: Maybe<Scalars["String"]>;
  isAutoship: Maybe<Scalars["Boolean"]>;
  directions: Maybe<Scalars["String"]>;
  qtyPerFill: Maybe<Scalars["Int"]>;
  refills: Maybe<Scalars["String"]>;
  smartScribeOverrideReason: Maybe<Scalars["String"]>;
  noteToPharmacy: Maybe<Scalars["String"]>;
  createdDate: Maybe<Scalars["String"]>;
  modifiedDate: Maybe<Scalars["String"]>;
  workflowStatusCode: Maybe<Scalars["String"]>;
  renewableExpirationDate: Maybe<Scalars["String"]>;
  renewableStatusCode: Maybe<Scalars["String"]>;
  renewableNote: Maybe<Scalars["String"]>;
  expirationDisplayDate: Maybe<Scalars["String"]>;
  isExternalPharmacy: Maybe<Scalars["Boolean"]>;
  methodTypeCode: Maybe<Scalars["String"]>;
  originTypeCode: Maybe<Scalars["String"]>;
  rejectionReason: Maybe<Scalars["String"]>;
  variantAttributeSelections: Maybe<Array<Maybe<VariantAttributeSelections>>>;
  flavorCode: Maybe<Scalars["String"]>;
  veterinarianLicenseKey: Maybe<Scalars["String"]>;
  lastFillDaysSupply: Maybe<Scalars["Int"]>;
  lastFillDate: Maybe<Scalars["String"]>;
  expirationDate: Maybe<Scalars["String"]>;
  pimsName: Maybe<Scalars["String"]>;
  pimsProductName: Maybe<Scalars["String"]>;
  speciesCategoryCodes: Maybe<Array<Scalars["String"]>>;
  flavorCodes: Maybe<Array<FlavorCodeT>>;
  baseProductStatusCode: Maybe<Scalars["String"]>;
};

export type RenewalsT = {
  __typename?: "RenewalsT";
  renewableKey: Maybe<Scalars["String"]>;
  clientFirstName: Maybe<Scalars["String"]>;
  clientLastName: Maybe<Scalars["String"]>;
  patientName: Maybe<Scalars["String"]>;
  patientSpecies: Maybe<Scalars["String"]>;
  patientWeight: Maybe<Scalars["String"]>;
  productName: Maybe<Scalars["String"]>;
  productStrength: Maybe<Scalars["String"]>;
  productQty: Maybe<Scalars["String"]>;
  renewalNeededByDate: Maybe<Scalars["String"]>;
  prescribingVetName: Maybe<Scalars["String"]>;
  prescribingVetKey: Maybe<Scalars["String"]>;
  workflowStatusCode: Maybe<Scalars["String"]>;
};

export type SIGShortcutsT = {
  __typename?: "SIGShortcutsT";
  shortcutKey: Scalars["UUID"];
  label: Scalars["String"];
  note: Scalars["String"];
};

export type ScriptValidations = {
  __typename?: "ScriptValidations";
  type: Maybe<Scalars["String"]>;
  code: Maybe<Scalars["String"]>;
  values: Maybe<Array<Maybe<Scalars["String"]>>>;
  patientWeightMin: Maybe<Scalars["Float"]>;
  patientWeightMax: Maybe<Scalars["Float"]>;
  selectedWeight: Maybe<Scalars["Float"]>;
  selectedSpecies: Maybe<Scalars["String"]>;
};

export type SearchApprovalsCriteriaI = {
  practiceKey?: Maybe<Scalars["String"]>;
  clientOrPatientQuery?: Maybe<Scalars["String"]>;
  authorizingVetKey?: Maybe<Scalars["String"]>;
  workflowStatusCode?: Maybe<Scalars["String"]>;
  shippingTypeCode?: Maybe<Scalars["String"]>;
  externalCartId?: Maybe<Scalars["String"]>;
};

export type SearchRenewalsCriteriaI = {
  practiceKey?: Maybe<Scalars["String"]>;
  onlyPreventatives?: Maybe<Scalars["Boolean"]>;
  onlyPending?: Maybe<Scalars["Boolean"]>;
  onlyMyItems?: Maybe<Scalars["Boolean"]>;
  showExpiredBy?: Maybe<Scalars["String"]>;
  workflowStatus?: Maybe<Scalars["String"]>;
  onlyAutoships?: Maybe<Scalars["Boolean"]>;
  prescribingVetKey?: Maybe<Scalars["String"]>;
  excludeRecentlyAuthorized?: Maybe<Scalars["Boolean"]>;
};

export type SkippedValidations = {
  __typename?: "SkippedValidations";
  skippedReasonCodes: Maybe<Array<Maybe<Scalars["String"]>>>;
  validationCode: Maybe<Scalars["String"]>;
};

export type SpeciesT = {
  __typename?: "SpeciesT";
  speciesCode: Scalars["String"];
  name: Scalars["String"];
  breeds: Maybe<Array<BreedT>>;
  isDeleted: Scalars["Boolean"];
  categoryCode: Scalars["String"];
};

export type UserVetDelegateLevelT = {
  __typename?: "UserVetDelegateLevelT";
  veterinarianKey: Scalars["String"];
  delegationTypeCode: Scalars["String"];
};

export type ValidateSmartScribeI = {
  childProductKey?: Maybe<Scalars["String"]>;
  baseProductKey?: Maybe<Scalars["String"]>;
  clientKey: Scalars["String"];
  patientKey: Scalars["String"];
  unitsPerFill?: Maybe<Scalars["Int"]>;
  directions?: Maybe<Scalars["String"]>;
  overrideReason?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  acbKey: Scalars["String"];
  veterinarianLicenseKey?: Maybe<Scalars["String"]>;
  dosageTypeCode?: Maybe<Scalars["String"]>;
};

export type ValidateSmartScribeT = {
  __typename?: "ValidateSmartScribeT";
  passesValidation: Maybe<Scalars["Boolean"]>;
  validation: Maybe<Array<Maybe<ScriptValidations>>>;
  skippedValidations: Maybe<Array<Maybe<SkippedValidations>>>;
};

export type VariantAttributeSelections = {
  __typename?: "VariantAttributeSelections";
  variantAttributeTypeCode: Maybe<Scalars["String"]>;
  variantAttributeValueTypeKey: Maybe<Scalars["String"]>;
};

export type VeterinarianLicense = {
  __typename?: "VeterinarianLicense";
  veterinarianLicenseKey: Maybe<Scalars["String"]>;
  isActive: Maybe<Scalars["Boolean"]>;
  expirationDate: Maybe<Scalars["String"]>;
  country: Maybe<Scalars["String"]>;
  stateOrProvince: Maybe<Scalars["String"]>;
};

export type VetsForPracticeUserT = {
  __typename?: "VetsForPracticeUserT";
  veterinarianKey: Scalars["UUID"];
  isActive: Scalars["Boolean"];
  givenName: Scalars["String"];
  familyName: Scalars["String"];
  isVisibleOnStorefront: Scalars["Boolean"];
  isDefault: Scalars["Boolean"];
  veterinarianLicenses: Maybe<Array<Maybe<VeterinarianLicense>>>;
  deaVeterinarianLicense: Maybe<DeaVeterinarianLicense>;
  primaryVetLicenseNumber: Maybe<Scalars["String"]>;
};

export enum WeightUOM {
  LBS = "LBS",
  KG = "KG",
}

export type familyProductT = {
  __typename?: "familyProductT";
  name: Scalars["String"];
  familyCode: Scalars["String"];
  isCompound: Maybe<Scalars["Boolean"]>;
  status: Maybe<Scalars["String"]>;
};

export type getAuthorizingVetsForUserQueryVariables = Exact<{ [key: string]: never }>;

export type getAuthorizingVetsForUserQuery = { __typename?: "Query" } & {
  retrieveVetsForPracticeUser: Maybe<
    Array<
      Maybe<
        { __typename?: "VetsForPracticeUserT" } & Pick<
          VetsForPracticeUserT,
          "veterinarianKey" | "givenName" | "familyName" | "isDefault" | "primaryVetLicenseNumber"
        > & {
            veterinarianLicenses: Maybe<
              Array<
                Maybe<
                  { __typename?: "VeterinarianLicense" } & Pick<
                    VeterinarianLicense,
                    "veterinarianLicenseKey" | "expirationDate" | "stateOrProvince" | "isActive"
                  >
                >
              >
            >;
            deaVeterinarianLicense: Maybe<
              { __typename?: "DeaVeterinarianLicense" } & Pick<DeaVeterinarianLicense, "expirationDate">
            >;
          }
      >
    >
  >;
};

export type GetNotesShortcutsQueryVariables = Exact<{
  getShortcutsInput: Maybe<GetShortcutsInputI>;
}>;

export type GetNotesShortcutsQuery = { __typename?: "Query" } & {
  retrieveNotesShortcuts: Maybe<
    Array<Maybe<{ __typename?: "NotesShortcutsT" } & Pick<NotesShortcutsT, "shortcutKey" | "label" | "note">>>
  >;
};

export type getSigShortcutsQueryVariables = Exact<{
  getShortcutsInput: Maybe<GetShortcutsInputI>;
}>;

export type getSigShortcutsQuery = { __typename?: "Query" } & {
  retrieveSIGShortcuts: Maybe<
    Array<Maybe<{ __typename?: "SIGShortcutsT" } & Pick<SIGShortcutsT, "shortcutKey" | "label" | "note">>>
  >;
};

export type ProductDetailsByKeyQueryVariables = Exact<{
  baseProductKey: Scalars["UUID"];
  productKey: Scalars["UUID"];
  claimCheckKey: Maybe<Scalars["UUID"]>;
}>;

export type ProductDetailsByKeyQuery = { __typename?: "Query" } & {
  getProduct: Maybe<
    { __typename?: "ProductT" } & Pick<
      ProductT,
      | "key"
      | "name"
      | "baseProductKey"
      | "baseProductName"
      | "baseProductStatusCode"
      | "productStatusCode"
      | "familyCode"
      | "familyName"
      | "familyNameWithDosage"
      | "normalizedStrengthWithUOM"
      | "description"
      | "directions"
      | "strength"
      | "size"
      | "information"
      | "ingredients"
      | "precautions"
      | "speciesCategoryCodes"
      | "legalCategoryCode"
      | "handling"
      | "clientRetailPrice"
      | "clientSubTotal"
      | "unitOfMeasure"
      | "labelKeywords"
      | "isControlled"
      | "patientWeightMin"
      | "patientWeightMax"
      | "isDiet"
      | "treatmentCategories"
      | "defaultSupplyCount"
      | "linkedProductKey"
      | "isEssentialCopy"
      | "productForm"
      | "shelfLife"
    > & {
        flavorCodes: Maybe<
          Array<{ __typename?: "FlavorCodeT" } & Pick<FlavorCodeT, "flavorCode" | "position" | "isVisibleToConsumer">>
        >;
        productLink: Maybe<
          { __typename?: "ProductLinkT" } & Pick<
            ProductLinkT,
            | "productLinkKey"
            | "pimsProductKey"
            | "pimsProductName"
            | "installationKey"
            | "baseProductKey"
            | "productKey"
            | "productName"
            | "vdsProductKey"
            | "isActive"
            | "initiatingUserKey"
          >
        >;
      }
  >;
};

export type RetrieveSpeciesQueryVariables = Exact<{ [key: string]: never }>;

export type RetrieveSpeciesQuery = { __typename?: "Query" } & {
  retrieveSpecies: Array<
    { __typename?: "SpeciesT" } & Pick<SpeciesT, "name" | "speciesCode" | "isDeleted" | "categoryCode"> & {
        breeds: Maybe<Array<{ __typename?: "BreedT" } & Pick<BreedT, "name" | "breedCode">>>;
      }
  >;
};

export type UpdatePatientWeightMutationVariables = Exact<{
  patientKey: Scalars["UUID"];
  weight: Scalars["Float"];
}>;

export type UpdatePatientWeightMutation = { __typename?: "Mutation" } & Pick<Mutation, "updatePatientWeight">;

export type searchGPMClientsQueryVariables = Exact<{
  criteria: ClientSearchCriteriaI;
}>;

export type searchGPMClientsQuery = { __typename?: "Query" } & {
  searchClients: Array<
    { __typename?: "ClientT" } & Pick<ClientT, "key" | "givenName" | "familyName" | "phoneNumber" | "emailAddress">
  >;
};

export type RetrievePracticeUserWithClaimCheckQueryVariables = Exact<{
  claimCheckKey: Scalars["UUID"];
}>;

export type RetrievePracticeUserWithClaimCheckQuery = { __typename?: "Query" } & {
  retrievePracticeUser: Maybe<
    { __typename?: "FullPracticeUserT" } & Pick<
      FullPracticeUserT,
      | "userKey"
      | "username"
      | "givenName"
      | "familyName"
      | "email"
      | "practiceUserTypeCode"
      | "animalCareBusinessKey"
      | "isActive"
      | "hasAccessPrescriptionPermission"
      | "veterinarianKey"
      | "isDeaLicenceActive"
    > & {
        vetDelegateLevels: Maybe<
          Array<
            { __typename?: "UserVetDelegateLevelT" } & Pick<
              UserVetDelegateLevelT,
              "veterinarianKey" | "delegationTypeCode"
            >
          >
        >;
      }
  >;
  retrieveClaimCheckByGuid: Maybe<
    { __typename?: "ClaimCheckT" } & Pick<
      ClaimCheckT,
      | "claimCheckKey"
      | "animalCareBusinessKey"
      | "platformAccountKey"
      | "callbackUrl"
      | "correlationKey"
      | "originationDateTime"
      | "installationKey"
      | "pims"
      | "eventType"
      | "precedingEventCorrelationId"
    > & {
        context: Maybe<{ __typename?: "ClaimCheckContextT" } & Pick<ClaimCheckContextT, "localTime" | "sessionId">>;
        payload: { __typename?: "ClaimCheckPayloadT" } & {
          client: Maybe<
            { __typename?: "ClaimCheckClientT" } & Pick<
              ClaimCheckClientT,
              | "vdsKey"
              | "pmsKey"
              | "firstName"
              | "lastName"
              | "email"
              | "phoneNumber"
              | "dateOfBirth"
              | "driversLicenseNumber"
              | "siteId"
            >
          >;
          patient: Maybe<
            { __typename?: "ClaimCheckPatientT" } & Pick<
              ClaimCheckPatientT,
              | "vdsKey"
              | "pmsKey"
              | "name"
              | "speciesDescription"
              | "gender"
              | "genderDescription"
              | "dateOfBirth"
              | "currentWeight"
              | "currentWeightUnit"
              | "siteId"
            >
          >;
          product: Maybe<
            { __typename?: "ClaimCheckProductT" } & Pick<
              ClaimCheckProductT,
              "vdsKey" | "pmsKey" | "description" | "form" | "strength" | "msdsNdc" | "code"
            >
          >;
          rx: Maybe<
            { __typename?: "ClaimCheckRxT" } & Pick<
              ClaimCheckRxT,
              | "vdsKey"
              | "pmsKey"
              | "expirationDate"
              | "units"
              | "quantity"
              | "totalRefills"
              | "directions"
              | "strength"
              | "gpmGuid"
              | "requestType"
              | "workflowStatus"
            >
          >;
        };
      }
  >;
};

export type importClientMutationVariables = Exact<{
  client: ClientImportI;
}>;

export type importClientMutation = { __typename?: "Mutation" } & Pick<Mutation, "importClient">;

export type importPatientMutationVariables = Exact<{
  patient: PatientImportI;
}>;

export type importPatientMutation = { __typename?: "Mutation" } & Pick<Mutation, "importPatient">;

export type retrieveLinksByClaimCheckGuidQueryVariables = Exact<{
  guid: Scalars["UUID"];
}>;

export type retrieveLinksByClaimCheckGuidQuery = { __typename?: "Query" } & {
  retrieveRxByGuidOrClaimCheckGuid: Maybe<
    { __typename?: "PrescriptionT" } & Pick<PrescriptionT, "linkedClientKey" | "pimsType">
  >;
};

export type RetrieveClaimCheckByGuidForPatientQueryVariables = Exact<{
  claimCheckKey: Scalars["UUID"];
  pimsPrescriptionKey: Maybe<Scalars["UUID"]>;
}>;

export type RetrieveClaimCheckByGuidForPatientQuery = { __typename?: "Query" } & {
  retrieveRxByGuidOrClaimCheckGuid: Maybe<
    { __typename?: "PrescriptionT" } & Pick<PrescriptionT, "linkedPatientKey" | "pimsType">
  >;
};

export type createPatientLinkMutationVariables = Exact<{
  patientLink: PatientLinkI;
}>;

export type createPatientLinkMutation = { __typename?: "Mutation" } & Pick<Mutation, "createPatientLink">;

export type RetrieveClientByGuidQueryVariables = Exact<{
  guid: Scalars["UUID"];
}>;

export type RetrieveClientByGuidQuery = { __typename?: "Query" } & {
  retrieveClientByGuid: Maybe<
    { __typename?: "ClientT" } & Pick<ClientT, "givenName" | "familyName" | "emailAddress" | "phoneNumber">
  >;
};

export type searchGPMPatientsQueryVariables = Exact<{
  criteria: PatientSearchCriteriaI;
}>;

export type searchGPMPatientsQuery = { __typename?: "Query" } & {
  searchPatients: Array<
    { __typename?: "PatientT" } & Pick<
      PatientT,
      | "key"
      | "name"
      | "clientKey"
      | "clientName"
      | "isClientActive"
      | "speciesCode"
      | "breedCode"
      | "sexCode"
      | "weight"
      | "weightUnitCode"
      | "dateOfBirth"
      | "isActive"
      | "isLinked"
      | "clientRoleTypeCode"
    >
  >;
};

export type UpdateApprovalVetMutationVariables = Exact<{
  key: Scalars["String"];
  workflowType: Scalars["String"];
  veterinarianKey: Scalars["String"];
}>;

export type UpdateApprovalVetMutation = { __typename?: "Mutation" } & Pick<Mutation, "updateApprovalVet">;

export type DeclineApprovalMutationVariables = Exact<{
  declineApproval: DeclineApprovalI;
}>;

export type DeclineApprovalMutation = { __typename?: "Mutation" } & Pick<Mutation, "declineApproval">;

export type GetPracticeApprovalQueryVariables = Exact<{
  approvableKey: Scalars["String"];
}>;

export type GetPracticeApprovalQuery = { __typename?: "Query" } & {
  getApproval: Maybe<
    { __typename?: "ApprovalT" } & Pick<
      ApprovalT,
      | "approvableStatusCode"
      | "approvableKey"
      | "prescriptionKey"
      | "prescriptionTypeCode"
      | "baseProductKey"
      | "baseProductStatusCode"
      | "productFamilyCode"
      | "productName"
      | "productStrength"
      | "productQty"
      | "productUOM"
      | "qtyOrdered"
      | "patientKey"
      | "patientName"
      | "patientWeight"
      | "patientWeightCode"
      | "patientSpeciesCode"
      | "patientBreedCode"
      | "patientIsPimsLinked"
      | "clientKey"
      | "clientGivenName"
      | "clientFamilyName"
      | "clientEmail"
      | "clientPhoneNumber"
      | "prescribingVetKey"
      | "prescribingVetGivenName"
      | "prescribingVetFamilyName"
      | "workflowStatusCode"
      | "approvableNote"
      | "isAutoship"
      | "veterinarianLicenseKey"
      | "flavorCode"
      | "directions"
      | "noteToPharmacy"
      | "smartScribeOverrideReason"
      | "expirationDate"
      | "refills"
      | "speciesCategoryCodes"
      | "isControlled"
      | "legalCategoryCode"
    > & {
        variantAttributeSelections: Maybe<
          Array<
            Maybe<
              { __typename?: "VariantAttributeSelections" } & Pick<
                VariantAttributeSelections,
                "variantAttributeTypeCode" | "variantAttributeValueTypeKey"
              >
            >
          >
        >;
        flavorCodes: Maybe<
          Array<{ __typename?: "FlavorCodeT" } & Pick<FlavorCodeT, "flavorCode" | "position" | "isVisibleToConsumer">>
        >;
      }
  >;
};

export type GetBaseProductQueryVariables = Exact<{
  guid: Scalars["UUID"];
}>;

export type GetBaseProductQuery = { __typename?: "Query" } & {
  getBaseProduct: Maybe<
    { __typename?: "BaseProductT" } & Pick<
      BaseProductT,
      "baseProductKey" | "treatmentCategories" | "isCompound" | "productManufacturerClassificationCodes"
    > & {
        familyCode: { __typename?: "BaseProductFamilyT" } & Pick<BaseProductFamilyT, "familyCode" | "name">;
        baseProductLegalCategories: Maybe<
          { __typename?: "BaseProductLegalCategoryT" } & Pick<
            BaseProductLegalCategoryT,
            "country" | "legalCategoryCode" | "requiresDispensing" | "requiresApproval"
          >
        >;
        controlledProducts: Maybe<
          { __typename?: "ControlledProductsT" } & Pick<
            ControlledProductsT,
            "country" | "stateOrProvince" | "controlledClassCode"
          >
        >;
      }
  >;
};

export type GetPracticeRenewalQueryVariables = Exact<{
  renewalKey: Scalars["String"];
}>;

export type GetPracticeRenewalQuery = { __typename?: "Query" } & {
  getRenewal: Maybe<
    { __typename?: "RenewalT" } & Pick<
      RenewalT,
      | "renewableKey"
      | "previousPrescriptionKey"
      | "nextPrescriptionKey"
      | "prescriptionTypeCode"
      | "clientKey"
      | "clientPrimaryPhone"
      | "clientPrimaryEmail"
      | "patientWeight"
      | "isPatientPimsLinked"
      | "patientKey"
      | "practiceKey"
      | "baseProductKey"
      | "productStrength"
      | "productQty"
      | "productUOM"
      | "isControlled"
      | "legalCategoryCode"
      | "variantAttributeTypeCode"
      | "variantAttributeValueCode"
      | "dosageName"
      | "familyCode"
      | "notesToClient"
      | "notificationDate"
      | "prescribingVetKey"
      | "prescribingVetGivenName"
      | "prescribingVetFamilyName"
      | "createdByUserKey"
      | "modifiedByUserKey"
      | "modifiedByUserGivenName"
      | "modifiedByFamilyName"
      | "notificationDisplayDate"
      | "clientFirstName"
      | "clientLastName"
      | "patientName"
      | "productName"
      | "speciesCode"
      | "isPrn"
      | "reminderDate"
      | "isAutoship"
      | "directions"
      | "qtyPerFill"
      | "refills"
      | "smartScribeOverrideReason"
      | "noteToPharmacy"
      | "createdDate"
      | "modifiedDate"
      | "workflowStatusCode"
      | "renewableExpirationDate"
      | "renewableStatusCode"
      | "renewableNote"
      | "expirationDisplayDate"
      | "isExternalPharmacy"
      | "methodTypeCode"
      | "originTypeCode"
      | "rejectionReason"
      | "flavorCode"
      | "veterinarianLicenseKey"
      | "lastFillDaysSupply"
      | "lastFillDate"
      | "expirationDate"
      | "pimsName"
      | "pimsProductName"
      | "speciesCategoryCodes"
      | "baseProductStatusCode"
    > & {
        variantAttributeSelections: Maybe<
          Array<
            Maybe<
              { __typename?: "VariantAttributeSelections" } & Pick<
                VariantAttributeSelections,
                "variantAttributeTypeCode" | "variantAttributeValueTypeKey"
              >
            >
          >
        >;
        flavorCodes: Maybe<
          Array<{ __typename?: "FlavorCodeT" } & Pick<FlavorCodeT, "flavorCode" | "position" | "isVisibleToConsumer">>
        >;
      }
  >;
};

export type RemoveRenewalMutationVariables = Exact<{
  renewalKey: Scalars["UUID"];
}>;

export type RemoveRenewalMutation = { __typename?: "Mutation" } & Pick<Mutation, "removeRenewal">;

export type removePrescriptionMutationVariables = Exact<{
  prescriptionGuid: Scalars["UUID"];
}>;

export type removePrescriptionMutation = { __typename?: "Mutation" } & Pick<Mutation, "removePrescription">;

export type searchPracticeRenewalsQueryVariables = Exact<{
  searchRenewals: SearchRenewalsCriteriaI;
}>;

export type searchPracticeRenewalsQuery = { __typename?: "Query" } & {
  searchRenewals: Maybe<
    Array<
      Maybe<
        { __typename?: "RenewalsT" } & Pick<
          RenewalsT,
          | "renewableKey"
          | "clientFirstName"
          | "clientLastName"
          | "patientName"
          | "patientSpecies"
          | "patientWeight"
          | "productName"
          | "productStrength"
          | "productQty"
          | "renewalNeededByDate"
          | "prescribingVetName"
          | "prescribingVetKey"
          | "workflowStatusCode"
        >
      >
    >
  >;
};

export type UpdateRxWithWorkflowStatusMutationVariables = Exact<{
  key: Scalars["String"];
  workflowType: Scalars["String"];
  workflowStatusCode: Scalars["String"];
}>;

export type UpdateRxWithWorkflowStatusMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "updateRxWithWorkflowStatus"
>;

export type CreateDraftRxWithUnlinkedProductMutationVariables = Exact<{
  createRx: CreateRxI;
}>;

export type CreateDraftRxWithUnlinkedProductMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createDraftRxWithUnlinkedProduct"
>;

export type CreateOrUpdateDraftPrescriptionMutationVariables = Exact<{
  claimCheckKey: Scalars["UUID"];
  pendingAuthRequestGuid: Scalars["UUID"];
  dvmPrescriptionGuid: Scalars["UUID"];
}>;

export type CreateOrUpdateDraftPrescriptionMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "createOrUpdateDraftPrescription"
>;

export type RetrievePendingAuthRequestForUserQueryVariables = Exact<{ [key: string]: never }>;

export type RetrievePendingAuthRequestForUserQuery = { __typename?: "Query" } & {
  retrievePendingAuthRequestForUser: Maybe<
    { __typename?: "PendingAuthRequestForUserT" } & {
      approvalPendingAuthRequests: Maybe<
        Array<
          Maybe<
            { __typename?: "PendingAuthRequestT" } & Pick<PendingAuthRequestT, "guid"> & {
                rx: Maybe<
                  { __typename?: "PendingAuthRequestPrescriptionT" } & Pick<
                    PendingAuthRequestPrescriptionT,
                    "guid" | "workflowStatus" | "requestType" | "updateEventType"
                  > & {
                      client: Maybe<
                        { __typename?: "PendingAuthRequestClientT" } & Pick<
                          PendingAuthRequestClientT,
                          "guid" | "clientFirstName" | "clientLastName" | "emailAddress" | "phoneNumber" | "dob"
                        >
                      >;
                      patient: Maybe<
                        { __typename?: "PendingAuthRequestPatientT" } & Pick<
                          PendingAuthRequestPatientT,
                          "guid" | "name" | "dob" | "gender" | "species" | "weight"
                        >
                      >;
                      product: Maybe<
                        { __typename?: "PendingAuthRequestProductT" } & Pick<
                          PendingAuthRequestProductT,
                          "guid" | "title" | "strength" | "quantity"
                        >
                      >;
                      vet: Maybe<
                        { __typename?: "PendingAuthRequestVetT" } & Pick<PendingAuthRequestVetT, "guid" | "name">
                      >;
                    }
                >;
                order: Maybe<
                  { __typename?: "PendingAuthRequestOrderT" } & Pick<
                    PendingAuthRequestOrderT,
                    "guid" | "orderNumber" | "orderedOn" | "shippingType" | "orderedQuantity"
                  >
                >;
              }
          >
        >
      >;
      renewalPendingAuthRequests: Maybe<
        Array<
          Maybe<
            { __typename?: "PendingAuthRequestT" } & Pick<PendingAuthRequestT, "guid"> & {
                rx: Maybe<
                  { __typename?: "PendingAuthRequestPrescriptionT" } & Pick<
                    PendingAuthRequestPrescriptionT,
                    "guid" | "workflowStatus" | "requestType" | "updateEventType" | "renewalNeededBy"
                  > & {
                      client: Maybe<
                        { __typename?: "PendingAuthRequestClientT" } & Pick<
                          PendingAuthRequestClientT,
                          "guid" | "clientFirstName" | "clientLastName" | "emailAddress" | "phoneNumber" | "dob"
                        >
                      >;
                      patient: Maybe<
                        { __typename?: "PendingAuthRequestPatientT" } & Pick<
                          PendingAuthRequestPatientT,
                          "guid" | "name" | "dob" | "gender" | "species" | "breed" | "weight"
                        >
                      >;
                      product: Maybe<
                        { __typename?: "PendingAuthRequestProductT" } & Pick<
                          PendingAuthRequestProductT,
                          "guid" | "title" | "strength" | "quantity"
                        >
                      >;
                      vet: Maybe<
                        { __typename?: "PendingAuthRequestVetT" } & Pick<PendingAuthRequestVetT, "guid" | "name">
                      >;
                    }
                >;
                order: Maybe<
                  { __typename?: "PendingAuthRequestOrderT" } & Pick<
                    PendingAuthRequestOrderT,
                    "guid" | "orderNumber" | "orderedOn" | "shippingType" | "orderedQuantity"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type RetrievePendingAuthRequestForVetQueryVariables = Exact<{
  vetGuid: Scalars["UUID"];
}>;

export type RetrievePendingAuthRequestForVetQuery = { __typename?: "Query" } & {
  retrievePendingAuthRequestForVet: Maybe<
    { __typename?: "PendingAuthRequestForUserT" } & {
      approvalPendingAuthRequests: Maybe<
        Array<
          Maybe<
            { __typename?: "PendingAuthRequestT" } & Pick<PendingAuthRequestT, "guid"> & {
                rx: Maybe<
                  { __typename?: "PendingAuthRequestPrescriptionT" } & Pick<
                    PendingAuthRequestPrescriptionT,
                    "guid" | "workflowStatus" | "requestType" | "updateEventType"
                  > & {
                      client: Maybe<
                        { __typename?: "PendingAuthRequestClientT" } & Pick<
                          PendingAuthRequestClientT,
                          "guid" | "clientFirstName" | "clientLastName" | "emailAddress" | "phoneNumber" | "dob"
                        >
                      >;
                      patient: Maybe<
                        { __typename?: "PendingAuthRequestPatientT" } & Pick<
                          PendingAuthRequestPatientT,
                          "guid" | "name" | "dob" | "gender" | "species" | "weight"
                        >
                      >;
                      product: Maybe<
                        { __typename?: "PendingAuthRequestProductT" } & Pick<
                          PendingAuthRequestProductT,
                          "guid" | "title" | "strength" | "quantity"
                        >
                      >;
                      vet: Maybe<
                        { __typename?: "PendingAuthRequestVetT" } & Pick<PendingAuthRequestVetT, "guid" | "name">
                      >;
                    }
                >;
                order: Maybe<
                  { __typename?: "PendingAuthRequestOrderT" } & Pick<
                    PendingAuthRequestOrderT,
                    "guid" | "orderNumber" | "orderedOn" | "shippingType" | "orderedQuantity"
                  >
                >;
              }
          >
        >
      >;
      renewalPendingAuthRequests: Maybe<
        Array<
          Maybe<
            { __typename?: "PendingAuthRequestT" } & Pick<PendingAuthRequestT, "guid"> & {
                rx: Maybe<
                  { __typename?: "PendingAuthRequestPrescriptionT" } & Pick<
                    PendingAuthRequestPrescriptionT,
                    "guid" | "workflowStatus" | "requestType" | "updateEventType" | "renewalNeededBy"
                  > & {
                      client: Maybe<
                        { __typename?: "PendingAuthRequestClientT" } & Pick<
                          PendingAuthRequestClientT,
                          "guid" | "clientFirstName" | "clientLastName" | "emailAddress" | "phoneNumber" | "dob"
                        >
                      >;
                      patient: Maybe<
                        { __typename?: "PendingAuthRequestPatientT" } & Pick<
                          PendingAuthRequestPatientT,
                          "guid" | "name" | "dob" | "gender" | "species" | "breed" | "weight"
                        >
                      >;
                      product: Maybe<
                        { __typename?: "PendingAuthRequestProductT" } & Pick<
                          PendingAuthRequestProductT,
                          "guid" | "title" | "strength" | "quantity"
                        >
                      >;
                      vet: Maybe<
                        { __typename?: "PendingAuthRequestVetT" } & Pick<PendingAuthRequestVetT, "guid" | "name">
                      >;
                    }
                >;
                order: Maybe<
                  { __typename?: "PendingAuthRequestOrderT" } & Pick<
                    PendingAuthRequestOrderT,
                    "guid" | "orderNumber" | "orderedOn" | "shippingType" | "orderedQuantity"
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type searchPracticeApprovalsQueryVariables = Exact<{
  searchApprovals: SearchApprovalsCriteriaI;
}>;

export type searchPracticeApprovalsQuery = { __typename?: "Query" } & {
  searchApprovals: Maybe<
    Array<
      Maybe<
        { __typename?: "ApprovalsT" } & Pick<
          ApprovalsT,
          | "approvableKey"
          | "prescriptionKey"
          | "clientFirstName"
          | "clientLastName"
          | "patientName"
          | "patientSpecies"
          | "patientWeight"
          | "productName"
          | "productStrength"
          | "orderedQty"
          | "orderedOnDate"
          | "orderNumber"
          | "shippingType"
          | "prescribingVetName"
          | "prescribingVetKey"
          | "workflowStatusCode"
        >
      >
    >
  >;
};

export type AutocompleteSearchGPMProductFamiliesQueryVariables = Exact<{
  criteria: FamilyProductSearchCriteriaI;
}>;

export type AutocompleteSearchGPMProductFamiliesQuery = { __typename?: "Query" } & {
  autoCompleteSearchProductFamilies: Array<
    { __typename?: "familyProductT" } & Pick<familyProductT, "name" | "familyCode" | "isCompound" | "status">
  >;
};

export type RetrieveClaimCheckByGuidForProductQueryVariables = Exact<{
  guid: Scalars["UUID"];
}>;

export type RetrieveClaimCheckByGuidForProductQuery = { __typename?: "Query" } & {
  retrieveClaimCheckByGuid: Maybe<
    { __typename?: "ClaimCheckT" } & {
      payload: { __typename?: "ClaimCheckPayloadT" } & {
        product: Maybe<
          { __typename?: "ClaimCheckProductT" } & Pick<
            ClaimCheckProductT,
            "pmsKey" | "code" | "form" | "description" | "strength" | "msdsNdc"
          >
        >;
      };
    }
  >;
};

export type CreateProductLinkMutationVariables = Exact<{
  productI: ProductLinkI;
}>;

export type CreateProductLinkMutation = { __typename?: "Mutation" } & Pick<Mutation, "createProductLink">;

export type RetrieveRxByClaimCheckGuidQueryVariables = Exact<{
  guid: Scalars["UUID"];
}>;

export type RetrieveRxByClaimCheckGuidQuery = { __typename?: "Query" } & {
  retrieveClaimCheckByGuid: Maybe<
    { __typename?: "ClaimCheckT" } & Pick<ClaimCheckT, "installationKey"> & {
        payload: { __typename?: "ClaimCheckPayloadT" } & {
          patient: Maybe<
            { __typename?: "ClaimCheckPatientT" } & Pick<
              ClaimCheckPatientT,
              | "vdsKey"
              | "pmsKey"
              | "name"
              | "dateOfBirth"
              | "gender"
              | "genderDescription"
              | "currentWeight"
              | "currentWeightUnit"
              | "speciesDescription"
              | "siteId"
            >
          >;
          client: Maybe<
            { __typename?: "ClaimCheckClientT" } & Pick<
              ClaimCheckClientT,
              "vdsKey" | "firstName" | "lastName" | "email" | "phoneNumber"
            >
          >;
          product: Maybe<
            { __typename?: "ClaimCheckProductT" } & Pick<ClaimCheckProductT, "vdsKey" | "pmsKey" | "description">
          >;
        };
      }
  >;
  retrieveRxByGuidOrClaimCheckGuid: Maybe<
    { __typename?: "PrescriptionT" } & Pick<
      PrescriptionT,
      | "pimsPrescriptionKey"
      | "code"
      | "description"
      | "form"
      | "strength"
      | "linkedProductKey"
      | "linkedPatientKey"
      | "linkedClientKey"
      | "pimsType"
      | "prescriptionRequestType"
    >
  >;
};

export type RetrieveStrengthRangeQueryVariables = Exact<{
  criteria: ProductSearchCriteriaI;
}>;

export type RetrieveStrengthRangeQuery = { __typename?: "Query" } & Pick<Query, "retrieveStrengthRange">;

export type SearchGPMProductFamiliesQueryVariables = Exact<{
  criteria: FamilyProductSearchCriteriaI;
}>;

export type SearchGPMProductFamiliesQuery = { __typename?: "Query" } & {
  searchProductFamilies: Array<
    { __typename?: "familyProductT" } & Pick<familyProductT, "name" | "familyCode" | "isCompound" | "status">
  >;
};

export type SearchProductsWithSpeciesAndWeightFilterQueryVariables = Exact<{
  criteria: ProductSearchCriteriaI;
  claimCheckKey: Maybe<Scalars["UUID"]>;
}>;

export type SearchProductsWithSpeciesAndWeightFilterQuery = { __typename?: "Query" } & {
  searchProductsWithSpeciesAndWeightFilter: Array<
    { __typename?: "ProductT" } & Pick<
      ProductT,
      | "name"
      | "familyName"
      | "familyNameWithDosage"
      | "normalizedStrengthWithUOM"
      | "key"
      | "baseProductKey"
      | "baseProductName"
      | "strength"
      | "size"
      | "speciesCategoryCodes"
      | "unitOfMeasure"
      | "productForm"
      | "shelfLife"
      | "clientSubTotal"
    > & {
        productLink: Maybe<
          { __typename?: "ProductLinkT" } & Pick<ProductLinkT, "productLinkKey" | "pimsProductKey" | "pimsProductName">
        >;
      }
  >;
};

export type SearchGPMProductsQueryVariables = Exact<{
  criteria: ProductSearchCriteriaI;
  claimCheckKey: Maybe<Scalars["UUID"]>;
}>;

export type SearchGPMProductsQuery = { __typename?: "Query" } & {
  searchProducts: Array<
    { __typename?: "ProductT" } & Pick<
      ProductT,
      | "name"
      | "familyName"
      | "familyNameWithDosage"
      | "normalizedStrengthWithUOM"
      | "key"
      | "baseProductKey"
      | "baseProductName"
      | "strength"
      | "size"
      | "speciesCategoryCodes"
      | "unitOfMeasure"
      | "productForm"
      | "shelfLife"
      | "clientSubTotal"
    > & {
        productLink: Maybe<
          { __typename?: "ProductLinkT" } & Pick<ProductLinkT, "productLinkKey" | "pimsProductKey" | "pimsProductName">
        >;
      }
  >;
};

export type RetrieveClaimCheckByGuidForUnlinkedProductQueryVariables = Exact<{
  guid: Scalars["UUID"];
}>;

export type RetrieveClaimCheckByGuidForUnlinkedProductQuery = { __typename?: "Query" } & {
  retrieveRxByGuidOrClaimCheckGuid: Maybe<
    { __typename?: "PrescriptionT" } & Pick<
      PrescriptionT,
      "linkedPatientKey" | "linkedClientKey" | "unlinkedProductKey" | "baseProductKey" | "pimsType"
    >
  >;
};

export type UpdateRxWithUnlinkedProductMutationVariables = Exact<{
  baseProductKey: Scalars["UUID"];
  productKey: Scalars["UUID"];
  claimCheckKey: Scalars["UUID"];
  pimsPrescriptionKey: Maybe<Scalars["UUID"]>;
}>;

export type UpdateRxWithUnlinkedProductMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "updateRxWithUnlinkedProduct"
>;

export type getPlatformAccountQueryVariables = Exact<{
  acbKey: Scalars["String"];
  platformAccountKey: Scalars["String"];
}>;

export type getPlatformAccountQuery = { __typename?: "Query" } & {
  getPlatformAccount: Maybe<
    { __typename?: "PlatformAccountT" } & Pick<
      PlatformAccountT,
      "platformAccountKey" | "platformAccountEnterpriseNumber" | "primaryAccountCovetrusCustomerNumber" | "displayName"
    > & {
        address: Maybe<
          { __typename?: "PlatformAccountAddressT" } & Pick<
            PlatformAccountAddressT,
            "addressTypeCode" | "country" | "stateOrProvince" | "postalCode" | "line1" | "line2" | "line3" | "city"
          >
        >;
      }
  >;
};

export type getSmartScribeErrorsQueryVariables = Exact<{
  validateCriteria: ValidateSmartScribeI;
}>;

export type getSmartScribeErrorsQuery = { __typename?: "Query" } & {
  validateSmartScribe: Maybe<
    { __typename?: "ValidateSmartScribeT" } & Pick<ValidateSmartScribeT, "passesValidation"> & {
        validation: Maybe<
          Array<
            Maybe<
              { __typename?: "ScriptValidations" } & Pick<
                ScriptValidations,
                | "type"
                | "code"
                | "values"
                | "patientWeightMin"
                | "patientWeightMax"
                | "selectedWeight"
                | "selectedSpecies"
              >
            >
          >
        >;
        skippedValidations: Maybe<
          Array<
            Maybe<
              { __typename?: "SkippedValidations" } & Pick<SkippedValidations, "skippedReasonCodes" | "validationCode">
            >
          >
        >;
      }
  >;
};

export type RetrieveLinkedClientDetailsQueryVariables = Exact<{
  guid: Scalars["UUID"];
}>;

export type RetrieveLinkedClientDetailsQuery = { __typename?: "Query" } & {
  retrieveClientByGuid: Maybe<
    { __typename?: "ClientT" } & Pick<
      ClientT,
      "givenName" | "familyName" | "emailAddress" | "phoneNumber" | "dateOfBirth"
    >
  >;
};

export type RetrieveLinkedPatientDetailsQueryVariables = Exact<{
  key: Scalars["UUID"];
}>;

export type RetrieveLinkedPatientDetailsQuery = { __typename?: "Query" } & {
  getPatient: Maybe<
    { __typename?: "PatientT" } & Pick<
      PatientT,
      | "key"
      | "name"
      | "clientKey"
      | "clientName"
      | "isClientActive"
      | "speciesCode"
      | "speciesType"
      | "breedCode"
      | "sexCode"
      | "weight"
      | "weightUnitCode"
      | "dateOfBirth"
      | "isActive"
      | "isLinked"
      | "clientRoleTypeCode"
    >
  >;
};

export type RetrieveProductDetailsQueryVariables = Exact<{
  baseProductKey: Scalars["UUID"];
  productKey: Scalars["UUID"];
  claimCheckKey: Maybe<Scalars["UUID"]>;
}>;

export type RetrieveProductDetailsQuery = { __typename?: "Query" } & {
  getProduct: Maybe<
    { __typename?: "ProductT" } & Pick<
      ProductT,
      | "name"
      | "productStatusCode"
      | "baseProductStatusCode"
      | "strength"
      | "speciesCategoryCodes"
      | "unitOfMeasure"
      | "legalCategoryCode"
      | "patientWeightMin"
      | "patientWeightMax"
      | "isDiet"
      | "treatmentCategories"
      | "labelKeywords"
      | "isControlled"
      | "isEssentialCopy"
      | "defaultSupplyCount"
      | "linkedProductKey"
      | "size"
    > & {
        flavorCodes: Maybe<
          Array<{ __typename?: "FlavorCodeT" } & Pick<FlavorCodeT, "flavorCode" | "position" | "isVisibleToConsumer">>
        >;
      }
  >;
};

export type RetrieveRxDetailsWithGuidORClaimCheckGuidQueryVariables = Exact<{
  claimCheckKey: Scalars["UUID"];
  pimsPrescriptionKey: Maybe<Scalars["UUID"]>;
}>;

export type RetrieveRxDetailsWithGuidORClaimCheckGuidQuery = { __typename?: "Query" } & {
  retrieveRxByGuidOrClaimCheckGuid: Maybe<
    { __typename?: "PrescriptionT" } & Pick<
      PrescriptionT,
      | "pimsPrescriptionKey"
      | "claimCheckKey"
      | "code"
      | "form"
      | "description"
      | "dosage"
      | "linkedClientKey"
      | "linkedPatientKey"
      | "linkedProductKey"
      | "unlinkedProductKey"
      | "baseProductKey"
      | "requestedProductKey"
      | "prescribingVetKey"
      | "refills"
      | "directions"
      | "expirationDate"
      | "pimsType"
      | "userCanEditRx"
      | "userCanDeclineRx"
      | "rxHasBeenProcessed"
      | "status"
      | "workflowStatus"
      | "authorizedWithChanges"
      | "units"
      | "prescriptionRequestType"
      | "legalCategoryCode"
      | "approvalKey"
      | "renewalKey"
    >
  >;
};

export type SubmitRxMutationVariables = Exact<{
  rx: PrescriptionI;
}>;

export type SubmitRxMutation = { __typename?: "Mutation" } & Pick<Mutation, "submitPrescription">;

export type UnlinkProductLinkMutationVariables = Exact<{
  productKey: Scalars["UUID"];
  installationKey: Scalars["UUID"];
  claimCheckKey: Scalars["UUID"];
}>;

export type UnlinkProductLinkMutation = { __typename?: "Mutation" } & Pick<Mutation, "unlinkProductLink">;

export const getAuthorizingVetsForUserDocument = gql`
  query getAuthorizingVetsForUser {
    retrieveVetsForPracticeUser {
      veterinarianKey
      givenName
      familyName
      isDefault
      primaryVetLicenseNumber
      veterinarianLicenses {
        veterinarianLicenseKey
        expirationDate
        stateOrProvince
        isActive
      }
      deaVeterinarianLicense {
        expirationDate
      }
    }
  }
`;

/**
 * __usegetAuthorizingVetsForUserQuery__
 *
 * To run a query within a React component, call `usegetAuthorizingVetsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetAuthorizingVetsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usegetAuthorizingVetsForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function usegetAuthorizingVetsForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<getAuthorizingVetsForUserQuery, getAuthorizingVetsForUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<getAuthorizingVetsForUserQuery, getAuthorizingVetsForUserQueryVariables>(
    getAuthorizingVetsForUserDocument,
    options,
  );
}
export function usegetAuthorizingVetsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<getAuthorizingVetsForUserQuery, getAuthorizingVetsForUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<getAuthorizingVetsForUserQuery, getAuthorizingVetsForUserQueryVariables>(
    getAuthorizingVetsForUserDocument,
    options,
  );
}
export type getAuthorizingVetsForUserQueryHookResult = ReturnType<typeof usegetAuthorizingVetsForUserQuery>;
export type getAuthorizingVetsForUserLazyQueryHookResult = ReturnType<typeof usegetAuthorizingVetsForUserLazyQuery>;
export type getAuthorizingVetsForUserQueryResult = Apollo.QueryResult<
  getAuthorizingVetsForUserQuery,
  getAuthorizingVetsForUserQueryVariables
>;
export const GetNotesShortcutsDocument = gql`
  query GetNotesShortcuts($getShortcutsInput: GetShortcutsInputI) {
    retrieveNotesShortcuts(getShortcutsInput: $getShortcutsInput) {
      shortcutKey
      label
      note
    }
  }
`;

/**
 * __useGetNotesShortcutsQuery__
 *
 * To run a query within a React component, call `useGetNotesShortcutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesShortcutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesShortcutsQuery({
 *   variables: {
 *      getShortcutsInput: // value for 'getShortcutsInput'
 *   },
 * });
 */
export function useGetNotesShortcutsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNotesShortcutsQuery, GetNotesShortcutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotesShortcutsQuery, GetNotesShortcutsQueryVariables>(GetNotesShortcutsDocument, options);
}
export function useGetNotesShortcutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotesShortcutsQuery, GetNotesShortcutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotesShortcutsQuery, GetNotesShortcutsQueryVariables>(
    GetNotesShortcutsDocument,
    options,
  );
}
export type GetNotesShortcutsQueryHookResult = ReturnType<typeof useGetNotesShortcutsQuery>;
export type GetNotesShortcutsLazyQueryHookResult = ReturnType<typeof useGetNotesShortcutsLazyQuery>;
export type GetNotesShortcutsQueryResult = Apollo.QueryResult<GetNotesShortcutsQuery, GetNotesShortcutsQueryVariables>;
export const getSigShortcutsDocument = gql`
  query getSigShortcuts($getShortcutsInput: GetShortcutsInputI) {
    retrieveSIGShortcuts(getShortcutsInput: $getShortcutsInput) {
      shortcutKey
      label
      note
    }
  }
`;

/**
 * __usegetSigShortcutsQuery__
 *
 * To run a query within a React component, call `usegetSigShortcutsQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetSigShortcutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usegetSigShortcutsQuery({
 *   variables: {
 *      getShortcutsInput: // value for 'getShortcutsInput'
 *   },
 * });
 */
export function usegetSigShortcutsQuery(
  baseOptions?: Apollo.QueryHookOptions<getSigShortcutsQuery, getSigShortcutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<getSigShortcutsQuery, getSigShortcutsQueryVariables>(getSigShortcutsDocument, options);
}
export function usegetSigShortcutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<getSigShortcutsQuery, getSigShortcutsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<getSigShortcutsQuery, getSigShortcutsQueryVariables>(getSigShortcutsDocument, options);
}
export type getSigShortcutsQueryHookResult = ReturnType<typeof usegetSigShortcutsQuery>;
export type getSigShortcutsLazyQueryHookResult = ReturnType<typeof usegetSigShortcutsLazyQuery>;
export type getSigShortcutsQueryResult = Apollo.QueryResult<getSigShortcutsQuery, getSigShortcutsQueryVariables>;
export const ProductDetailsByKeyDocument = gql`
  query ProductDetailsByKey($baseProductKey: UUID!, $productKey: UUID!, $claimCheckKey: UUID) {
    getProduct(baseProductKey: $baseProductKey, productKey: $productKey, claimCheckKey: $claimCheckKey) {
      key
      name
      baseProductKey
      baseProductName
      baseProductStatusCode
      productStatusCode
      familyCode
      familyName
      familyNameWithDosage
      normalizedStrengthWithUOM
      description
      directions
      strength
      size
      information
      ingredients
      precautions
      speciesCategoryCodes
      legalCategoryCode
      handling
      clientRetailPrice
      clientSubTotal
      unitOfMeasure
      labelKeywords
      isControlled
      patientWeightMin
      patientWeightMax
      flavorCodes {
        flavorCode
        position
        isVisibleToConsumer
      }
      isDiet
      treatmentCategories
      defaultSupplyCount
      linkedProductKey
      isEssentialCopy
      productForm
      shelfLife
      productLink {
        productLinkKey
        pimsProductKey
        pimsProductName
        installationKey
        baseProductKey
        productKey
        productName
        vdsProductKey
        isActive
        initiatingUserKey
      }
    }
  }
`;

/**
 * __useProductDetailsByKeyQuery__
 *
 * To run a query within a React component, call `useProductDetailsByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsByKeyQuery({
 *   variables: {
 *      baseProductKey: // value for 'baseProductKey'
 *      productKey: // value for 'productKey'
 *      claimCheckKey: // value for 'claimCheckKey'
 *   },
 * });
 */
export function useProductDetailsByKeyQuery(
  baseOptions: Apollo.QueryHookOptions<ProductDetailsByKeyQuery, ProductDetailsByKeyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductDetailsByKeyQuery, ProductDetailsByKeyQueryVariables>(
    ProductDetailsByKeyDocument,
    options,
  );
}
export function useProductDetailsByKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailsByKeyQuery, ProductDetailsByKeyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductDetailsByKeyQuery, ProductDetailsByKeyQueryVariables>(
    ProductDetailsByKeyDocument,
    options,
  );
}
export type ProductDetailsByKeyQueryHookResult = ReturnType<typeof useProductDetailsByKeyQuery>;
export type ProductDetailsByKeyLazyQueryHookResult = ReturnType<typeof useProductDetailsByKeyLazyQuery>;
export type ProductDetailsByKeyQueryResult = Apollo.QueryResult<
  ProductDetailsByKeyQuery,
  ProductDetailsByKeyQueryVariables
>;
export const RetrieveSpeciesDocument = gql`
  query RetrieveSpecies {
    retrieveSpecies {
      name
      speciesCode
      breeds {
        name
        breedCode
      }
      isDeleted
      categoryCode
    }
  }
`;

/**
 * __useRetrieveSpeciesQuery__
 *
 * To run a query within a React component, call `useRetrieveSpeciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveSpeciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveSpeciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetrieveSpeciesQuery(
  baseOptions?: Apollo.QueryHookOptions<RetrieveSpeciesQuery, RetrieveSpeciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveSpeciesQuery, RetrieveSpeciesQueryVariables>(RetrieveSpeciesDocument, options);
}
export function useRetrieveSpeciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetrieveSpeciesQuery, RetrieveSpeciesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveSpeciesQuery, RetrieveSpeciesQueryVariables>(RetrieveSpeciesDocument, options);
}
export type RetrieveSpeciesQueryHookResult = ReturnType<typeof useRetrieveSpeciesQuery>;
export type RetrieveSpeciesLazyQueryHookResult = ReturnType<typeof useRetrieveSpeciesLazyQuery>;
export type RetrieveSpeciesQueryResult = Apollo.QueryResult<RetrieveSpeciesQuery, RetrieveSpeciesQueryVariables>;
export const UpdatePatientWeightDocument = gql`
  mutation UpdatePatientWeight($patientKey: UUID!, $weight: Float!) {
    updatePatientWeight(patientKey: $patientKey, weight: $weight)
  }
`;
export type UpdatePatientWeightMutationFn = Apollo.MutationFunction<
  UpdatePatientWeightMutation,
  UpdatePatientWeightMutationVariables
>;

/**
 * __useUpdatePatientWeightMutation__
 *
 * To run a mutation, you first call `useUpdatePatientWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientWeightMutation, { data, loading, error }] = useUpdatePatientWeightMutation({
 *   variables: {
 *      patientKey: // value for 'patientKey'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdatePatientWeightMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePatientWeightMutation, UpdatePatientWeightMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePatientWeightMutation, UpdatePatientWeightMutationVariables>(
    UpdatePatientWeightDocument,
    options,
  );
}
export type UpdatePatientWeightMutationHookResult = ReturnType<typeof useUpdatePatientWeightMutation>;
export type UpdatePatientWeightMutationResult = Apollo.MutationResult<UpdatePatientWeightMutation>;
export type UpdatePatientWeightMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientWeightMutation,
  UpdatePatientWeightMutationVariables
>;
export const searchGPMClientsDocument = gql`
  query searchGPMClients($criteria: ClientSearchCriteriaI!) {
    searchClients(searchCriteria: $criteria) {
      key
      givenName
      familyName
      phoneNumber
      emailAddress
    }
  }
`;

/**
 * __usesearchGPMClientsQuery__
 *
 * To run a query within a React component, call `usesearchGPMClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usesearchGPMClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usesearchGPMClientsQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function usesearchGPMClientsQuery(
  baseOptions: Apollo.QueryHookOptions<searchGPMClientsQuery, searchGPMClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<searchGPMClientsQuery, searchGPMClientsQueryVariables>(searchGPMClientsDocument, options);
}
export function usesearchGPMClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<searchGPMClientsQuery, searchGPMClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<searchGPMClientsQuery, searchGPMClientsQueryVariables>(searchGPMClientsDocument, options);
}
export type searchGPMClientsQueryHookResult = ReturnType<typeof usesearchGPMClientsQuery>;
export type searchGPMClientsLazyQueryHookResult = ReturnType<typeof usesearchGPMClientsLazyQuery>;
export type searchGPMClientsQueryResult = Apollo.QueryResult<searchGPMClientsQuery, searchGPMClientsQueryVariables>;
export const RetrievePracticeUserWithClaimCheckDocument = gql`
  query RetrievePracticeUserWithClaimCheck($claimCheckKey: UUID!) {
    retrievePracticeUser {
      userKey
      username
      givenName
      familyName
      email
      practiceUserTypeCode
      vetDelegateLevels {
        veterinarianKey
        delegationTypeCode
      }
      animalCareBusinessKey
      isActive
      hasAccessPrescriptionPermission
      veterinarianKey
      isDeaLicenceActive
    }
    retrieveClaimCheckByGuid(guid: $claimCheckKey) {
      claimCheckKey
      animalCareBusinessKey
      platformAccountKey
      callbackUrl
      correlationKey
      originationDateTime
      installationKey
      pims
      eventType
      precedingEventCorrelationId
      context {
        localTime
        sessionId
      }
      payload {
        client {
          vdsKey
          pmsKey
          firstName
          lastName
          email
          phoneNumber
          dateOfBirth
          driversLicenseNumber
          siteId
        }
        patient {
          vdsKey
          pmsKey
          name
          speciesDescription
          gender
          genderDescription
          dateOfBirth
          currentWeight
          currentWeightUnit
          siteId
        }
        product {
          vdsKey
          pmsKey
          description
          form
          strength
          msdsNdc
          code
        }
        rx {
          vdsKey
          pmsKey
          expirationDate
          units
          quantity
          totalRefills
          directions
          strength
          gpmGuid
          requestType
          workflowStatus
        }
      }
    }
  }
`;

/**
 * __useRetrievePracticeUserWithClaimCheckQuery__
 *
 * To run a query within a React component, call `useRetrievePracticeUserWithClaimCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrievePracticeUserWithClaimCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrievePracticeUserWithClaimCheckQuery({
 *   variables: {
 *      claimCheckKey: // value for 'claimCheckKey'
 *   },
 * });
 */
export function useRetrievePracticeUserWithClaimCheckQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrievePracticeUserWithClaimCheckQuery,
    RetrievePracticeUserWithClaimCheckQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrievePracticeUserWithClaimCheckQuery, RetrievePracticeUserWithClaimCheckQueryVariables>(
    RetrievePracticeUserWithClaimCheckDocument,
    options,
  );
}
export function useRetrievePracticeUserWithClaimCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrievePracticeUserWithClaimCheckQuery,
    RetrievePracticeUserWithClaimCheckQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrievePracticeUserWithClaimCheckQuery, RetrievePracticeUserWithClaimCheckQueryVariables>(
    RetrievePracticeUserWithClaimCheckDocument,
    options,
  );
}
export type RetrievePracticeUserWithClaimCheckQueryHookResult = ReturnType<
  typeof useRetrievePracticeUserWithClaimCheckQuery
>;
export type RetrievePracticeUserWithClaimCheckLazyQueryHookResult = ReturnType<
  typeof useRetrievePracticeUserWithClaimCheckLazyQuery
>;
export type RetrievePracticeUserWithClaimCheckQueryResult = Apollo.QueryResult<
  RetrievePracticeUserWithClaimCheckQuery,
  RetrievePracticeUserWithClaimCheckQueryVariables
>;
export const importClientDocument = gql`
  mutation importClient($client: ClientImportI!) {
    importClient(client: $client)
  }
`;
export type importClientMutationFn = Apollo.MutationFunction<importClientMutation, importClientMutationVariables>;

/**
 * __useimportClientMutation__
 *
 * To run a mutation, you first call `useimportClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useimportClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importClientMutation, { data, loading, error }] = useimportClientMutation({
 *   variables: {
 *      client: // value for 'client'
 *   },
 * });
 */
export function useimportClientMutation(
  baseOptions?: Apollo.MutationHookOptions<importClientMutation, importClientMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<importClientMutation, importClientMutationVariables>(importClientDocument, options);
}
export type importClientMutationHookResult = ReturnType<typeof useimportClientMutation>;
export type importClientMutationResult = Apollo.MutationResult<importClientMutation>;
export type importClientMutationOptions = Apollo.BaseMutationOptions<
  importClientMutation,
  importClientMutationVariables
>;
export const importPatientDocument = gql`
  mutation importPatient($patient: PatientImportI!) {
    importPatient(patient: $patient)
  }
`;
export type importPatientMutationFn = Apollo.MutationFunction<importPatientMutation, importPatientMutationVariables>;

/**
 * __useimportPatientMutation__
 *
 * To run a mutation, you first call `useimportPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useimportPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPatientMutation, { data, loading, error }] = useimportPatientMutation({
 *   variables: {
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useimportPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<importPatientMutation, importPatientMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<importPatientMutation, importPatientMutationVariables>(importPatientDocument, options);
}
export type importPatientMutationHookResult = ReturnType<typeof useimportPatientMutation>;
export type importPatientMutationResult = Apollo.MutationResult<importPatientMutation>;
export type importPatientMutationOptions = Apollo.BaseMutationOptions<
  importPatientMutation,
  importPatientMutationVariables
>;
export const retrieveLinksByClaimCheckGuidDocument = gql`
  query retrieveLinksByClaimCheckGuid($guid: UUID!) {
    retrieveRxByGuidOrClaimCheckGuid(claimCheckKey: $guid, pimsPrescriptionKey: null) {
      linkedClientKey
      pimsType
    }
  }
`;

/**
 * __useretrieveLinksByClaimCheckGuidQuery__
 *
 * To run a query within a React component, call `useretrieveLinksByClaimCheckGuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useretrieveLinksByClaimCheckGuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useretrieveLinksByClaimCheckGuidQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useretrieveLinksByClaimCheckGuidQuery(
  baseOptions: Apollo.QueryHookOptions<retrieveLinksByClaimCheckGuidQuery, retrieveLinksByClaimCheckGuidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<retrieveLinksByClaimCheckGuidQuery, retrieveLinksByClaimCheckGuidQueryVariables>(
    retrieveLinksByClaimCheckGuidDocument,
    options,
  );
}
export function useretrieveLinksByClaimCheckGuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    retrieveLinksByClaimCheckGuidQuery,
    retrieveLinksByClaimCheckGuidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<retrieveLinksByClaimCheckGuidQuery, retrieveLinksByClaimCheckGuidQueryVariables>(
    retrieveLinksByClaimCheckGuidDocument,
    options,
  );
}
export type retrieveLinksByClaimCheckGuidQueryHookResult = ReturnType<typeof useretrieveLinksByClaimCheckGuidQuery>;
export type retrieveLinksByClaimCheckGuidLazyQueryHookResult = ReturnType<
  typeof useretrieveLinksByClaimCheckGuidLazyQuery
>;
export type retrieveLinksByClaimCheckGuidQueryResult = Apollo.QueryResult<
  retrieveLinksByClaimCheckGuidQuery,
  retrieveLinksByClaimCheckGuidQueryVariables
>;
export const RetrieveClaimCheckByGuidForPatientDocument = gql`
  query RetrieveClaimCheckByGuidForPatient($claimCheckKey: UUID!, $pimsPrescriptionKey: UUID) {
    retrieveRxByGuidOrClaimCheckGuid(claimCheckKey: $claimCheckKey, pimsPrescriptionKey: $pimsPrescriptionKey) {
      linkedPatientKey
      pimsType
    }
  }
`;

/**
 * __useRetrieveClaimCheckByGuidForPatientQuery__
 *
 * To run a query within a React component, call `useRetrieveClaimCheckByGuidForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveClaimCheckByGuidForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveClaimCheckByGuidForPatientQuery({
 *   variables: {
 *      claimCheckKey: // value for 'claimCheckKey'
 *      pimsPrescriptionKey: // value for 'pimsPrescriptionKey'
 *   },
 * });
 */
export function useRetrieveClaimCheckByGuidForPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrieveClaimCheckByGuidForPatientQuery,
    RetrieveClaimCheckByGuidForPatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveClaimCheckByGuidForPatientQuery, RetrieveClaimCheckByGuidForPatientQueryVariables>(
    RetrieveClaimCheckByGuidForPatientDocument,
    options,
  );
}
export function useRetrieveClaimCheckByGuidForPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveClaimCheckByGuidForPatientQuery,
    RetrieveClaimCheckByGuidForPatientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveClaimCheckByGuidForPatientQuery, RetrieveClaimCheckByGuidForPatientQueryVariables>(
    RetrieveClaimCheckByGuidForPatientDocument,
    options,
  );
}
export type RetrieveClaimCheckByGuidForPatientQueryHookResult = ReturnType<
  typeof useRetrieveClaimCheckByGuidForPatientQuery
>;
export type RetrieveClaimCheckByGuidForPatientLazyQueryHookResult = ReturnType<
  typeof useRetrieveClaimCheckByGuidForPatientLazyQuery
>;
export type RetrieveClaimCheckByGuidForPatientQueryResult = Apollo.QueryResult<
  RetrieveClaimCheckByGuidForPatientQuery,
  RetrieveClaimCheckByGuidForPatientQueryVariables
>;
export const createPatientLinkDocument = gql`
  mutation createPatientLink($patientLink: PatientLinkI!) {
    createPatientLink(patientLink: $patientLink)
  }
`;
export type createPatientLinkMutationFn = Apollo.MutationFunction<
  createPatientLinkMutation,
  createPatientLinkMutationVariables
>;

/**
 * __usecreatePatientLinkMutation__
 *
 * To run a mutation, you first call `usecreatePatientLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usecreatePatientLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientLinkMutation, { data, loading, error }] = usecreatePatientLinkMutation({
 *   variables: {
 *      patientLink: // value for 'patientLink'
 *   },
 * });
 */
export function usecreatePatientLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<createPatientLinkMutation, createPatientLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<createPatientLinkMutation, createPatientLinkMutationVariables>(
    createPatientLinkDocument,
    options,
  );
}
export type createPatientLinkMutationHookResult = ReturnType<typeof usecreatePatientLinkMutation>;
export type createPatientLinkMutationResult = Apollo.MutationResult<createPatientLinkMutation>;
export type createPatientLinkMutationOptions = Apollo.BaseMutationOptions<
  createPatientLinkMutation,
  createPatientLinkMutationVariables
>;
export const RetrieveClientByGuidDocument = gql`
  query RetrieveClientByGuid($guid: UUID!) {
    retrieveClientByGuid(guid: $guid) {
      givenName
      familyName
      emailAddress
      phoneNumber
    }
  }
`;

/**
 * __useRetrieveClientByGuidQuery__
 *
 * To run a query within a React component, call `useRetrieveClientByGuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveClientByGuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveClientByGuidQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useRetrieveClientByGuidQuery(
  baseOptions: Apollo.QueryHookOptions<RetrieveClientByGuidQuery, RetrieveClientByGuidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveClientByGuidQuery, RetrieveClientByGuidQueryVariables>(
    RetrieveClientByGuidDocument,
    options,
  );
}
export function useRetrieveClientByGuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetrieveClientByGuidQuery, RetrieveClientByGuidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveClientByGuidQuery, RetrieveClientByGuidQueryVariables>(
    RetrieveClientByGuidDocument,
    options,
  );
}
export type RetrieveClientByGuidQueryHookResult = ReturnType<typeof useRetrieveClientByGuidQuery>;
export type RetrieveClientByGuidLazyQueryHookResult = ReturnType<typeof useRetrieveClientByGuidLazyQuery>;
export type RetrieveClientByGuidQueryResult = Apollo.QueryResult<
  RetrieveClientByGuidQuery,
  RetrieveClientByGuidQueryVariables
>;
export const searchGPMPatientsDocument = gql`
  query searchGPMPatients($criteria: PatientSearchCriteriaI!) {
    searchPatients(searchCriteria: $criteria) {
      key
      name
      clientKey
      clientName
      isClientActive
      speciesCode
      breedCode
      sexCode
      weight
      weightUnitCode
      dateOfBirth
      isActive
      isLinked
      clientRoleTypeCode
    }
  }
`;

/**
 * __usesearchGPMPatientsQuery__
 *
 * To run a query within a React component, call `usesearchGPMPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usesearchGPMPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usesearchGPMPatientsQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function usesearchGPMPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<searchGPMPatientsQuery, searchGPMPatientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<searchGPMPatientsQuery, searchGPMPatientsQueryVariables>(searchGPMPatientsDocument, options);
}
export function usesearchGPMPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<searchGPMPatientsQuery, searchGPMPatientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<searchGPMPatientsQuery, searchGPMPatientsQueryVariables>(
    searchGPMPatientsDocument,
    options,
  );
}
export type searchGPMPatientsQueryHookResult = ReturnType<typeof usesearchGPMPatientsQuery>;
export type searchGPMPatientsLazyQueryHookResult = ReturnType<typeof usesearchGPMPatientsLazyQuery>;
export type searchGPMPatientsQueryResult = Apollo.QueryResult<searchGPMPatientsQuery, searchGPMPatientsQueryVariables>;
export const UpdateApprovalVetDocument = gql`
  mutation UpdateApprovalVet($key: String!, $workflowType: String!, $veterinarianKey: String!) {
    updateApprovalVet(key: $key, workflowType: $workflowType, veterinarianKey: $veterinarianKey)
  }
`;
export type UpdateApprovalVetMutationFn = Apollo.MutationFunction<
  UpdateApprovalVetMutation,
  UpdateApprovalVetMutationVariables
>;

/**
 * __useUpdateApprovalVetMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalVetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalVetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalVetMutation, { data, loading, error }] = useUpdateApprovalVetMutation({
 *   variables: {
 *      key: // value for 'key'
 *      workflowType: // value for 'workflowType'
 *      veterinarianKey: // value for 'veterinarianKey'
 *   },
 * });
 */
export function useUpdateApprovalVetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApprovalVetMutation, UpdateApprovalVetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateApprovalVetMutation, UpdateApprovalVetMutationVariables>(
    UpdateApprovalVetDocument,
    options,
  );
}
export type UpdateApprovalVetMutationHookResult = ReturnType<typeof useUpdateApprovalVetMutation>;
export type UpdateApprovalVetMutationResult = Apollo.MutationResult<UpdateApprovalVetMutation>;
export type UpdateApprovalVetMutationOptions = Apollo.BaseMutationOptions<
  UpdateApprovalVetMutation,
  UpdateApprovalVetMutationVariables
>;
export const DeclineApprovalDocument = gql`
  mutation DeclineApproval($declineApproval: DeclineApprovalI!) {
    declineApproval(declineApproval: $declineApproval)
  }
`;
export type DeclineApprovalMutationFn = Apollo.MutationFunction<
  DeclineApprovalMutation,
  DeclineApprovalMutationVariables
>;

/**
 * __useDeclineApprovalMutation__
 *
 * To run a mutation, you first call `useDeclineApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineApprovalMutation, { data, loading, error }] = useDeclineApprovalMutation({
 *   variables: {
 *      declineApproval: // value for 'declineApproval'
 *   },
 * });
 */
export function useDeclineApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineApprovalMutation, DeclineApprovalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeclineApprovalMutation, DeclineApprovalMutationVariables>(
    DeclineApprovalDocument,
    options,
  );
}
export type DeclineApprovalMutationHookResult = ReturnType<typeof useDeclineApprovalMutation>;
export type DeclineApprovalMutationResult = Apollo.MutationResult<DeclineApprovalMutation>;
export type DeclineApprovalMutationOptions = Apollo.BaseMutationOptions<
  DeclineApprovalMutation,
  DeclineApprovalMutationVariables
>;
export const GetPracticeApprovalDocument = gql`
  query GetPracticeApproval($approvableKey: String!) {
    getApproval(approvableKey: $approvableKey) {
      approvableStatusCode
      approvableKey
      prescriptionKey
      prescriptionTypeCode
      baseProductKey
      baseProductStatusCode
      productFamilyCode
      productName
      productStrength
      productQty
      productUOM
      variantAttributeSelections {
        variantAttributeTypeCode
        variantAttributeValueTypeKey
      }
      qtyOrdered
      patientKey
      patientName
      patientWeight
      patientWeightCode
      patientSpeciesCode
      patientBreedCode
      patientIsPimsLinked
      clientKey
      clientGivenName
      clientFamilyName
      clientEmail
      clientPhoneNumber
      prescribingVetKey
      prescribingVetGivenName
      prescribingVetFamilyName
      workflowStatusCode
      approvableNote
      isAutoship
      veterinarianLicenseKey
      flavorCode
      directions
      noteToPharmacy
      smartScribeOverrideReason
      expirationDate
      refills
      speciesCategoryCodes
      isControlled
      legalCategoryCode
      flavorCodes {
        flavorCode
        position
        isVisibleToConsumer
      }
    }
  }
`;

/**
 * __useGetPracticeApprovalQuery__
 *
 * To run a query within a React component, call `useGetPracticeApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeApprovalQuery({
 *   variables: {
 *      approvableKey: // value for 'approvableKey'
 *   },
 * });
 */
export function useGetPracticeApprovalQuery(
  baseOptions: Apollo.QueryHookOptions<GetPracticeApprovalQuery, GetPracticeApprovalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPracticeApprovalQuery, GetPracticeApprovalQueryVariables>(
    GetPracticeApprovalDocument,
    options,
  );
}
export function useGetPracticeApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPracticeApprovalQuery, GetPracticeApprovalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPracticeApprovalQuery, GetPracticeApprovalQueryVariables>(
    GetPracticeApprovalDocument,
    options,
  );
}
export type GetPracticeApprovalQueryHookResult = ReturnType<typeof useGetPracticeApprovalQuery>;
export type GetPracticeApprovalLazyQueryHookResult = ReturnType<typeof useGetPracticeApprovalLazyQuery>;
export type GetPracticeApprovalQueryResult = Apollo.QueryResult<
  GetPracticeApprovalQuery,
  GetPracticeApprovalQueryVariables
>;
export const GetBaseProductDocument = gql`
  query GetBaseProduct($guid: UUID!) {
    getBaseProduct(guid: $guid) {
      baseProductKey
      familyCode {
        familyCode
        name
      }
      baseProductLegalCategories {
        country
        legalCategoryCode
        requiresDispensing
        requiresApproval
      }
      controlledProducts {
        country
        stateOrProvince
        controlledClassCode
      }
      treatmentCategories
      isCompound
      productManufacturerClassificationCodes
    }
  }
`;

/**
 * __useGetBaseProductQuery__
 *
 * To run a query within a React component, call `useGetBaseProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useGetBaseProductQuery(
  baseOptions: Apollo.QueryHookOptions<GetBaseProductQuery, GetBaseProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaseProductQuery, GetBaseProductQueryVariables>(GetBaseProductDocument, options);
}
export function useGetBaseProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBaseProductQuery, GetBaseProductQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaseProductQuery, GetBaseProductQueryVariables>(GetBaseProductDocument, options);
}
export type GetBaseProductQueryHookResult = ReturnType<typeof useGetBaseProductQuery>;
export type GetBaseProductLazyQueryHookResult = ReturnType<typeof useGetBaseProductLazyQuery>;
export type GetBaseProductQueryResult = Apollo.QueryResult<GetBaseProductQuery, GetBaseProductQueryVariables>;
export const GetPracticeRenewalDocument = gql`
  query GetPracticeRenewal($renewalKey: String!) {
    getRenewal(renewalKey: $renewalKey) {
      renewableKey
      previousPrescriptionKey
      nextPrescriptionKey
      prescriptionTypeCode
      clientKey
      clientPrimaryPhone
      clientPrimaryEmail
      patientWeight
      isPatientPimsLinked
      patientKey
      practiceKey
      baseProductKey
      productStrength
      productQty
      productUOM
      isControlled
      legalCategoryCode
      variantAttributeTypeCode
      variantAttributeValueCode
      dosageName
      familyCode
      notesToClient
      notificationDate
      prescribingVetKey
      prescribingVetGivenName
      prescribingVetFamilyName
      createdByUserKey
      modifiedByUserKey
      modifiedByUserGivenName
      modifiedByFamilyName
      notificationDisplayDate
      clientFirstName
      clientLastName
      patientName
      productName
      speciesCode
      isPrn
      reminderDate
      isAutoship
      directions
      qtyPerFill
      refills
      smartScribeOverrideReason
      noteToPharmacy
      createdDate
      modifiedDate
      workflowStatusCode
      renewableExpirationDate
      renewableStatusCode
      renewableNote
      expirationDisplayDate
      isExternalPharmacy
      methodTypeCode
      originTypeCode
      rejectionReason
      variantAttributeSelections {
        variantAttributeTypeCode
        variantAttributeValueTypeKey
      }
      flavorCode
      veterinarianLicenseKey
      lastFillDaysSupply
      lastFillDate
      expirationDate
      pimsName
      pimsProductName
      speciesCategoryCodes
      flavorCodes {
        flavorCode
        position
        isVisibleToConsumer
      }
      baseProductStatusCode
    }
  }
`;

/**
 * __useGetPracticeRenewalQuery__
 *
 * To run a query within a React component, call `useGetPracticeRenewalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeRenewalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeRenewalQuery({
 *   variables: {
 *      renewalKey: // value for 'renewalKey'
 *   },
 * });
 */
export function useGetPracticeRenewalQuery(
  baseOptions: Apollo.QueryHookOptions<GetPracticeRenewalQuery, GetPracticeRenewalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPracticeRenewalQuery, GetPracticeRenewalQueryVariables>(
    GetPracticeRenewalDocument,
    options,
  );
}
export function useGetPracticeRenewalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPracticeRenewalQuery, GetPracticeRenewalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPracticeRenewalQuery, GetPracticeRenewalQueryVariables>(
    GetPracticeRenewalDocument,
    options,
  );
}
export type GetPracticeRenewalQueryHookResult = ReturnType<typeof useGetPracticeRenewalQuery>;
export type GetPracticeRenewalLazyQueryHookResult = ReturnType<typeof useGetPracticeRenewalLazyQuery>;
export type GetPracticeRenewalQueryResult = Apollo.QueryResult<
  GetPracticeRenewalQuery,
  GetPracticeRenewalQueryVariables
>;
export const RemoveRenewalDocument = gql`
  mutation RemoveRenewal($renewalKey: UUID!) {
    removeRenewal(renewalKey: $renewalKey)
  }
`;
export type RemoveRenewalMutationFn = Apollo.MutationFunction<RemoveRenewalMutation, RemoveRenewalMutationVariables>;

/**
 * __useRemoveRenewalMutation__
 *
 * To run a mutation, you first call `useRemoveRenewalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRenewalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRenewalMutation, { data, loading, error }] = useRemoveRenewalMutation({
 *   variables: {
 *      renewalKey: // value for 'renewalKey'
 *   },
 * });
 */
export function useRemoveRenewalMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveRenewalMutation, RemoveRenewalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveRenewalMutation, RemoveRenewalMutationVariables>(RemoveRenewalDocument, options);
}
export type RemoveRenewalMutationHookResult = ReturnType<typeof useRemoveRenewalMutation>;
export type RemoveRenewalMutationResult = Apollo.MutationResult<RemoveRenewalMutation>;
export type RemoveRenewalMutationOptions = Apollo.BaseMutationOptions<
  RemoveRenewalMutation,
  RemoveRenewalMutationVariables
>;
export const removePrescriptionDocument = gql`
  mutation removePrescription($prescriptionGuid: UUID!) {
    removePrescription(prescriptionGuid: $prescriptionGuid)
  }
`;
export type removePrescriptionMutationFn = Apollo.MutationFunction<
  removePrescriptionMutation,
  removePrescriptionMutationVariables
>;

/**
 * __useremovePrescriptionMutation__
 *
 * To run a mutation, you first call `useremovePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useremovePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePrescriptionMutation, { data, loading, error }] = useremovePrescriptionMutation({
 *   variables: {
 *      prescriptionGuid: // value for 'prescriptionGuid'
 *   },
 * });
 */
export function useremovePrescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<removePrescriptionMutation, removePrescriptionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<removePrescriptionMutation, removePrescriptionMutationVariables>(
    removePrescriptionDocument,
    options,
  );
}
export type removePrescriptionMutationHookResult = ReturnType<typeof useremovePrescriptionMutation>;
export type removePrescriptionMutationResult = Apollo.MutationResult<removePrescriptionMutation>;
export type removePrescriptionMutationOptions = Apollo.BaseMutationOptions<
  removePrescriptionMutation,
  removePrescriptionMutationVariables
>;
export const searchPracticeRenewalsDocument = gql`
  query searchPracticeRenewals($searchRenewals: SearchRenewalsCriteriaI!) {
    searchRenewals(searchRenewals: $searchRenewals) {
      renewableKey
      clientFirstName
      clientLastName
      patientName
      patientSpecies
      patientWeight
      productName
      productStrength
      productQty
      renewalNeededByDate
      prescribingVetName
      prescribingVetKey
      workflowStatusCode
    }
  }
`;

/**
 * __usesearchPracticeRenewalsQuery__
 *
 * To run a query within a React component, call `usesearchPracticeRenewalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usesearchPracticeRenewalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usesearchPracticeRenewalsQuery({
 *   variables: {
 *      searchRenewals: // value for 'searchRenewals'
 *   },
 * });
 */
export function usesearchPracticeRenewalsQuery(
  baseOptions: Apollo.QueryHookOptions<searchPracticeRenewalsQuery, searchPracticeRenewalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<searchPracticeRenewalsQuery, searchPracticeRenewalsQueryVariables>(
    searchPracticeRenewalsDocument,
    options,
  );
}
export function usesearchPracticeRenewalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<searchPracticeRenewalsQuery, searchPracticeRenewalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<searchPracticeRenewalsQuery, searchPracticeRenewalsQueryVariables>(
    searchPracticeRenewalsDocument,
    options,
  );
}
export type searchPracticeRenewalsQueryHookResult = ReturnType<typeof usesearchPracticeRenewalsQuery>;
export type searchPracticeRenewalsLazyQueryHookResult = ReturnType<typeof usesearchPracticeRenewalsLazyQuery>;
export type searchPracticeRenewalsQueryResult = Apollo.QueryResult<
  searchPracticeRenewalsQuery,
  searchPracticeRenewalsQueryVariables
>;
export const UpdateRxWithWorkflowStatusDocument = gql`
  mutation UpdateRxWithWorkflowStatus($key: String!, $workflowType: String!, $workflowStatusCode: String!) {
    updateRxWithWorkflowStatus(key: $key, workflowType: $workflowType, workflowStatusCode: $workflowStatusCode)
  }
`;
export type UpdateRxWithWorkflowStatusMutationFn = Apollo.MutationFunction<
  UpdateRxWithWorkflowStatusMutation,
  UpdateRxWithWorkflowStatusMutationVariables
>;

/**
 * __useUpdateRxWithWorkflowStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRxWithWorkflowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRxWithWorkflowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRxWithWorkflowStatusMutation, { data, loading, error }] = useUpdateRxWithWorkflowStatusMutation({
 *   variables: {
 *      key: // value for 'key'
 *      workflowType: // value for 'workflowType'
 *      workflowStatusCode: // value for 'workflowStatusCode'
 *   },
 * });
 */
export function useUpdateRxWithWorkflowStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRxWithWorkflowStatusMutation,
    UpdateRxWithWorkflowStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRxWithWorkflowStatusMutation, UpdateRxWithWorkflowStatusMutationVariables>(
    UpdateRxWithWorkflowStatusDocument,
    options,
  );
}
export type UpdateRxWithWorkflowStatusMutationHookResult = ReturnType<typeof useUpdateRxWithWorkflowStatusMutation>;
export type UpdateRxWithWorkflowStatusMutationResult = Apollo.MutationResult<UpdateRxWithWorkflowStatusMutation>;
export type UpdateRxWithWorkflowStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateRxWithWorkflowStatusMutation,
  UpdateRxWithWorkflowStatusMutationVariables
>;
export const CreateDraftRxWithUnlinkedProductDocument = gql`
  mutation CreateDraftRxWithUnlinkedProduct($createRx: CreateRxI!) {
    createDraftRxWithUnlinkedProduct(createRx: $createRx)
  }
`;
export type CreateDraftRxWithUnlinkedProductMutationFn = Apollo.MutationFunction<
  CreateDraftRxWithUnlinkedProductMutation,
  CreateDraftRxWithUnlinkedProductMutationVariables
>;

/**
 * __useCreateDraftRxWithUnlinkedProductMutation__
 *
 * To run a mutation, you first call `useCreateDraftRxWithUnlinkedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftRxWithUnlinkedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftRxWithUnlinkedProductMutation, { data, loading, error }] = useCreateDraftRxWithUnlinkedProductMutation({
 *   variables: {
 *      createRx: // value for 'createRx'
 *   },
 * });
 */
export function useCreateDraftRxWithUnlinkedProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDraftRxWithUnlinkedProductMutation,
    CreateDraftRxWithUnlinkedProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDraftRxWithUnlinkedProductMutation,
    CreateDraftRxWithUnlinkedProductMutationVariables
  >(CreateDraftRxWithUnlinkedProductDocument, options);
}
export type CreateDraftRxWithUnlinkedProductMutationHookResult = ReturnType<
  typeof useCreateDraftRxWithUnlinkedProductMutation
>;
export type CreateDraftRxWithUnlinkedProductMutationResult =
  Apollo.MutationResult<CreateDraftRxWithUnlinkedProductMutation>;
export type CreateDraftRxWithUnlinkedProductMutationOptions = Apollo.BaseMutationOptions<
  CreateDraftRxWithUnlinkedProductMutation,
  CreateDraftRxWithUnlinkedProductMutationVariables
>;
export const CreateOrUpdateDraftPrescriptionDocument = gql`
  mutation CreateOrUpdateDraftPrescription(
    $claimCheckKey: UUID!
    $pendingAuthRequestGuid: UUID!
    $dvmPrescriptionGuid: UUID!
  ) {
    createOrUpdateDraftPrescription(
      claimCheckKey: $claimCheckKey
      pendingAuthRequestGuid: $pendingAuthRequestGuid
      dvmPrescriptionGuid: $dvmPrescriptionGuid
    )
  }
`;
export type CreateOrUpdateDraftPrescriptionMutationFn = Apollo.MutationFunction<
  CreateOrUpdateDraftPrescriptionMutation,
  CreateOrUpdateDraftPrescriptionMutationVariables
>;

/**
 * __useCreateOrUpdateDraftPrescriptionMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateDraftPrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateDraftPrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateDraftPrescriptionMutation, { data, loading, error }] = useCreateOrUpdateDraftPrescriptionMutation({
 *   variables: {
 *      claimCheckKey: // value for 'claimCheckKey'
 *      pendingAuthRequestGuid: // value for 'pendingAuthRequestGuid'
 *      dvmPrescriptionGuid: // value for 'dvmPrescriptionGuid'
 *   },
 * });
 */
export function useCreateOrUpdateDraftPrescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateDraftPrescriptionMutation,
    CreateOrUpdateDraftPrescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrUpdateDraftPrescriptionMutation, CreateOrUpdateDraftPrescriptionMutationVariables>(
    CreateOrUpdateDraftPrescriptionDocument,
    options,
  );
}
export type CreateOrUpdateDraftPrescriptionMutationHookResult = ReturnType<
  typeof useCreateOrUpdateDraftPrescriptionMutation
>;
export type CreateOrUpdateDraftPrescriptionMutationResult =
  Apollo.MutationResult<CreateOrUpdateDraftPrescriptionMutation>;
export type CreateOrUpdateDraftPrescriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateDraftPrescriptionMutation,
  CreateOrUpdateDraftPrescriptionMutationVariables
>;
export const RetrievePendingAuthRequestForUserDocument = gql`
  query RetrievePendingAuthRequestForUser {
    retrievePendingAuthRequestForUser {
      approvalPendingAuthRequests {
        guid
        rx {
          guid
          workflowStatus
          requestType
          updateEventType
          client {
            guid
            clientFirstName
            clientLastName
            emailAddress
            phoneNumber
            dob
          }
          patient {
            guid
            name
            dob
            gender
            species
            gender
            weight
          }
          product {
            guid
            title
            strength
            quantity
          }
          vet {
            guid
            name
          }
        }
        order {
          guid
          orderNumber
          orderedOn
          shippingType
          orderedQuantity
        }
      }
      renewalPendingAuthRequests {
        guid
        rx {
          guid
          workflowStatus
          requestType
          updateEventType
          client {
            guid
            clientFirstName
            clientLastName
            emailAddress
            phoneNumber
            dob
          }
          patient {
            guid
            name
            dob
            gender
            species
            breed
            weight
          }
          product {
            guid
            title
            strength
            quantity
          }
          vet {
            guid
            name
          }
          renewalNeededBy
        }
        order {
          guid
          orderNumber
          orderedOn
          shippingType
          orderedQuantity
        }
      }
    }
  }
`;

/**
 * __useRetrievePendingAuthRequestForUserQuery__
 *
 * To run a query within a React component, call `useRetrievePendingAuthRequestForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrievePendingAuthRequestForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrievePendingAuthRequestForUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetrievePendingAuthRequestForUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RetrievePendingAuthRequestForUserQuery,
    RetrievePendingAuthRequestForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrievePendingAuthRequestForUserQuery, RetrievePendingAuthRequestForUserQueryVariables>(
    RetrievePendingAuthRequestForUserDocument,
    options,
  );
}
export function useRetrievePendingAuthRequestForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrievePendingAuthRequestForUserQuery,
    RetrievePendingAuthRequestForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrievePendingAuthRequestForUserQuery, RetrievePendingAuthRequestForUserQueryVariables>(
    RetrievePendingAuthRequestForUserDocument,
    options,
  );
}
export type RetrievePendingAuthRequestForUserQueryHookResult = ReturnType<
  typeof useRetrievePendingAuthRequestForUserQuery
>;
export type RetrievePendingAuthRequestForUserLazyQueryHookResult = ReturnType<
  typeof useRetrievePendingAuthRequestForUserLazyQuery
>;
export type RetrievePendingAuthRequestForUserQueryResult = Apollo.QueryResult<
  RetrievePendingAuthRequestForUserQuery,
  RetrievePendingAuthRequestForUserQueryVariables
>;
export const RetrievePendingAuthRequestForVetDocument = gql`
  query RetrievePendingAuthRequestForVet($vetGuid: UUID!) {
    retrievePendingAuthRequestForVet(vetGuid: $vetGuid) {
      approvalPendingAuthRequests {
        guid
        rx {
          guid
          workflowStatus
          requestType
          updateEventType
          client {
            guid
            clientFirstName
            clientLastName
            emailAddress
            phoneNumber
            dob
          }
          patient {
            guid
            name
            dob
            gender
            species
            gender
            weight
          }
          product {
            guid
            title
            strength
            quantity
          }
          vet {
            guid
            name
          }
        }
        order {
          guid
          orderNumber
          orderedOn
          shippingType
          orderedQuantity
        }
      }
      renewalPendingAuthRequests {
        guid
        rx {
          guid
          workflowStatus
          requestType
          updateEventType
          client {
            guid
            clientFirstName
            clientLastName
            emailAddress
            phoneNumber
            dob
          }
          patient {
            guid
            name
            dob
            gender
            species
            breed
            weight
          }
          product {
            guid
            title
            strength
            quantity
          }
          vet {
            guid
            name
          }
          renewalNeededBy
        }
        order {
          guid
          orderNumber
          orderedOn
          shippingType
          orderedQuantity
        }
      }
    }
  }
`;

/**
 * __useRetrievePendingAuthRequestForVetQuery__
 *
 * To run a query within a React component, call `useRetrievePendingAuthRequestForVetQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrievePendingAuthRequestForVetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrievePendingAuthRequestForVetQuery({
 *   variables: {
 *      vetGuid: // value for 'vetGuid'
 *   },
 * });
 */
export function useRetrievePendingAuthRequestForVetQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrievePendingAuthRequestForVetQuery,
    RetrievePendingAuthRequestForVetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrievePendingAuthRequestForVetQuery, RetrievePendingAuthRequestForVetQueryVariables>(
    RetrievePendingAuthRequestForVetDocument,
    options,
  );
}
export function useRetrievePendingAuthRequestForVetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrievePendingAuthRequestForVetQuery,
    RetrievePendingAuthRequestForVetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrievePendingAuthRequestForVetQuery, RetrievePendingAuthRequestForVetQueryVariables>(
    RetrievePendingAuthRequestForVetDocument,
    options,
  );
}
export type RetrievePendingAuthRequestForVetQueryHookResult = ReturnType<
  typeof useRetrievePendingAuthRequestForVetQuery
>;
export type RetrievePendingAuthRequestForVetLazyQueryHookResult = ReturnType<
  typeof useRetrievePendingAuthRequestForVetLazyQuery
>;
export type RetrievePendingAuthRequestForVetQueryResult = Apollo.QueryResult<
  RetrievePendingAuthRequestForVetQuery,
  RetrievePendingAuthRequestForVetQueryVariables
>;
export const searchPracticeApprovalsDocument = gql`
  query searchPracticeApprovals($searchApprovals: SearchApprovalsCriteriaI!) {
    searchApprovals(searchApprovals: $searchApprovals) {
      approvableKey
      prescriptionKey
      clientFirstName
      clientLastName
      patientName
      patientSpecies
      patientWeight
      productName
      productStrength
      orderedQty
      orderedOnDate
      orderNumber
      shippingType
      prescribingVetName
      prescribingVetKey
      workflowStatusCode
    }
  }
`;

/**
 * __usesearchPracticeApprovalsQuery__
 *
 * To run a query within a React component, call `usesearchPracticeApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `usesearchPracticeApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usesearchPracticeApprovalsQuery({
 *   variables: {
 *      searchApprovals: // value for 'searchApprovals'
 *   },
 * });
 */
export function usesearchPracticeApprovalsQuery(
  baseOptions: Apollo.QueryHookOptions<searchPracticeApprovalsQuery, searchPracticeApprovalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<searchPracticeApprovalsQuery, searchPracticeApprovalsQueryVariables>(
    searchPracticeApprovalsDocument,
    options,
  );
}
export function usesearchPracticeApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<searchPracticeApprovalsQuery, searchPracticeApprovalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<searchPracticeApprovalsQuery, searchPracticeApprovalsQueryVariables>(
    searchPracticeApprovalsDocument,
    options,
  );
}
export type searchPracticeApprovalsQueryHookResult = ReturnType<typeof usesearchPracticeApprovalsQuery>;
export type searchPracticeApprovalsLazyQueryHookResult = ReturnType<typeof usesearchPracticeApprovalsLazyQuery>;
export type searchPracticeApprovalsQueryResult = Apollo.QueryResult<
  searchPracticeApprovalsQuery,
  searchPracticeApprovalsQueryVariables
>;
export const AutocompleteSearchGPMProductFamiliesDocument = gql`
  query AutocompleteSearchGPMProductFamilies($criteria: FamilyProductSearchCriteriaI!) {
    autoCompleteSearchProductFamilies(criteria: $criteria) {
      name
      familyCode
      isCompound
      status
    }
  }
`;

/**
 * __useAutocompleteSearchGPMProductFamiliesQuery__
 *
 * To run a query within a React component, call `useAutocompleteSearchGPMProductFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteSearchGPMProductFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteSearchGPMProductFamiliesQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useAutocompleteSearchGPMProductFamiliesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteSearchGPMProductFamiliesQuery,
    AutocompleteSearchGPMProductFamiliesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutocompleteSearchGPMProductFamiliesQuery, AutocompleteSearchGPMProductFamiliesQueryVariables>(
    AutocompleteSearchGPMProductFamiliesDocument,
    options,
  );
}
export function useAutocompleteSearchGPMProductFamiliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteSearchGPMProductFamiliesQuery,
    AutocompleteSearchGPMProductFamiliesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteSearchGPMProductFamiliesQuery,
    AutocompleteSearchGPMProductFamiliesQueryVariables
  >(AutocompleteSearchGPMProductFamiliesDocument, options);
}
export type AutocompleteSearchGPMProductFamiliesQueryHookResult = ReturnType<
  typeof useAutocompleteSearchGPMProductFamiliesQuery
>;
export type AutocompleteSearchGPMProductFamiliesLazyQueryHookResult = ReturnType<
  typeof useAutocompleteSearchGPMProductFamiliesLazyQuery
>;
export type AutocompleteSearchGPMProductFamiliesQueryResult = Apollo.QueryResult<
  AutocompleteSearchGPMProductFamiliesQuery,
  AutocompleteSearchGPMProductFamiliesQueryVariables
>;
export const RetrieveClaimCheckByGuidForProductDocument = gql`
  query RetrieveClaimCheckByGuidForProduct($guid: UUID!) {
    retrieveClaimCheckByGuid(guid: $guid) {
      payload {
        product {
          pmsKey
          code
          form
          description
          strength
          msdsNdc
        }
      }
    }
  }
`;

/**
 * __useRetrieveClaimCheckByGuidForProductQuery__
 *
 * To run a query within a React component, call `useRetrieveClaimCheckByGuidForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveClaimCheckByGuidForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveClaimCheckByGuidForProductQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useRetrieveClaimCheckByGuidForProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrieveClaimCheckByGuidForProductQuery,
    RetrieveClaimCheckByGuidForProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveClaimCheckByGuidForProductQuery, RetrieveClaimCheckByGuidForProductQueryVariables>(
    RetrieveClaimCheckByGuidForProductDocument,
    options,
  );
}
export function useRetrieveClaimCheckByGuidForProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveClaimCheckByGuidForProductQuery,
    RetrieveClaimCheckByGuidForProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveClaimCheckByGuidForProductQuery, RetrieveClaimCheckByGuidForProductQueryVariables>(
    RetrieveClaimCheckByGuidForProductDocument,
    options,
  );
}
export type RetrieveClaimCheckByGuidForProductQueryHookResult = ReturnType<
  typeof useRetrieveClaimCheckByGuidForProductQuery
>;
export type RetrieveClaimCheckByGuidForProductLazyQueryHookResult = ReturnType<
  typeof useRetrieveClaimCheckByGuidForProductLazyQuery
>;
export type RetrieveClaimCheckByGuidForProductQueryResult = Apollo.QueryResult<
  RetrieveClaimCheckByGuidForProductQuery,
  RetrieveClaimCheckByGuidForProductQueryVariables
>;
export const CreateProductLinkDocument = gql`
  mutation CreateProductLink($productI: ProductLinkI!) {
    createProductLink(productLink: $productI)
  }
`;
export type CreateProductLinkMutationFn = Apollo.MutationFunction<
  CreateProductLinkMutation,
  CreateProductLinkMutationVariables
>;

/**
 * __useCreateProductLinkMutation__
 *
 * To run a mutation, you first call `useCreateProductLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductLinkMutation, { data, loading, error }] = useCreateProductLinkMutation({
 *   variables: {
 *      productI: // value for 'productI'
 *   },
 * });
 */
export function useCreateProductLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductLinkMutation, CreateProductLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductLinkMutation, CreateProductLinkMutationVariables>(
    CreateProductLinkDocument,
    options,
  );
}
export type CreateProductLinkMutationHookResult = ReturnType<typeof useCreateProductLinkMutation>;
export type CreateProductLinkMutationResult = Apollo.MutationResult<CreateProductLinkMutation>;
export type CreateProductLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateProductLinkMutation,
  CreateProductLinkMutationVariables
>;
export const RetrieveRxByClaimCheckGuidDocument = gql`
  query RetrieveRxByClaimCheckGuid($guid: UUID!) {
    retrieveClaimCheckByGuid(guid: $guid) {
      installationKey
      payload {
        patient {
          vdsKey
          pmsKey
          name
          dateOfBirth
          gender
          genderDescription
          currentWeight
          currentWeightUnit
          speciesDescription
          siteId
        }
        client {
          vdsKey
          firstName
          lastName
          email
          phoneNumber
        }
        product {
          vdsKey
          pmsKey
          description
        }
      }
    }
    retrieveRxByGuidOrClaimCheckGuid(claimCheckKey: $guid, pimsPrescriptionKey: null) {
      pimsPrescriptionKey
      code
      description
      form
      strength
      linkedProductKey
      linkedPatientKey
      linkedClientKey
      pimsType
      prescriptionRequestType
    }
  }
`;

/**
 * __useRetrieveRxByClaimCheckGuidQuery__
 *
 * To run a query within a React component, call `useRetrieveRxByClaimCheckGuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveRxByClaimCheckGuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveRxByClaimCheckGuidQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useRetrieveRxByClaimCheckGuidQuery(
  baseOptions: Apollo.QueryHookOptions<RetrieveRxByClaimCheckGuidQuery, RetrieveRxByClaimCheckGuidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveRxByClaimCheckGuidQuery, RetrieveRxByClaimCheckGuidQueryVariables>(
    RetrieveRxByClaimCheckGuidDocument,
    options,
  );
}
export function useRetrieveRxByClaimCheckGuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetrieveRxByClaimCheckGuidQuery, RetrieveRxByClaimCheckGuidQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveRxByClaimCheckGuidQuery, RetrieveRxByClaimCheckGuidQueryVariables>(
    RetrieveRxByClaimCheckGuidDocument,
    options,
  );
}
export type RetrieveRxByClaimCheckGuidQueryHookResult = ReturnType<typeof useRetrieveRxByClaimCheckGuidQuery>;
export type RetrieveRxByClaimCheckGuidLazyQueryHookResult = ReturnType<typeof useRetrieveRxByClaimCheckGuidLazyQuery>;
export type RetrieveRxByClaimCheckGuidQueryResult = Apollo.QueryResult<
  RetrieveRxByClaimCheckGuidQuery,
  RetrieveRxByClaimCheckGuidQueryVariables
>;
export const RetrieveStrengthRangeDocument = gql`
  query RetrieveStrengthRange($criteria: ProductSearchCriteriaI!) {
    retrieveStrengthRange(criteria: $criteria)
  }
`;

/**
 * __useRetrieveStrengthRangeQuery__
 *
 * To run a query within a React component, call `useRetrieveStrengthRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveStrengthRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveStrengthRangeQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useRetrieveStrengthRangeQuery(
  baseOptions: Apollo.QueryHookOptions<RetrieveStrengthRangeQuery, RetrieveStrengthRangeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveStrengthRangeQuery, RetrieveStrengthRangeQueryVariables>(
    RetrieveStrengthRangeDocument,
    options,
  );
}
export function useRetrieveStrengthRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetrieveStrengthRangeQuery, RetrieveStrengthRangeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveStrengthRangeQuery, RetrieveStrengthRangeQueryVariables>(
    RetrieveStrengthRangeDocument,
    options,
  );
}
export type RetrieveStrengthRangeQueryHookResult = ReturnType<typeof useRetrieveStrengthRangeQuery>;
export type RetrieveStrengthRangeLazyQueryHookResult = ReturnType<typeof useRetrieveStrengthRangeLazyQuery>;
export type RetrieveStrengthRangeQueryResult = Apollo.QueryResult<
  RetrieveStrengthRangeQuery,
  RetrieveStrengthRangeQueryVariables
>;
export const SearchGPMProductFamiliesDocument = gql`
  query SearchGPMProductFamilies($criteria: FamilyProductSearchCriteriaI!) {
    searchProductFamilies(criteria: $criteria) {
      name
      familyCode
      isCompound
      status
    }
  }
`;

/**
 * __useSearchGPMProductFamiliesQuery__
 *
 * To run a query within a React component, call `useSearchGPMProductFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGPMProductFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGPMProductFamiliesQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *   },
 * });
 */
export function useSearchGPMProductFamiliesQuery(
  baseOptions: Apollo.QueryHookOptions<SearchGPMProductFamiliesQuery, SearchGPMProductFamiliesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchGPMProductFamiliesQuery, SearchGPMProductFamiliesQueryVariables>(
    SearchGPMProductFamiliesDocument,
    options,
  );
}
export function useSearchGPMProductFamiliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchGPMProductFamiliesQuery, SearchGPMProductFamiliesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchGPMProductFamiliesQuery, SearchGPMProductFamiliesQueryVariables>(
    SearchGPMProductFamiliesDocument,
    options,
  );
}
export type SearchGPMProductFamiliesQueryHookResult = ReturnType<typeof useSearchGPMProductFamiliesQuery>;
export type SearchGPMProductFamiliesLazyQueryHookResult = ReturnType<typeof useSearchGPMProductFamiliesLazyQuery>;
export type SearchGPMProductFamiliesQueryResult = Apollo.QueryResult<
  SearchGPMProductFamiliesQuery,
  SearchGPMProductFamiliesQueryVariables
>;
export const SearchProductsWithSpeciesAndWeightFilterDocument = gql`
  query SearchProductsWithSpeciesAndWeightFilter($criteria: ProductSearchCriteriaI!, $claimCheckKey: UUID) {
    searchProductsWithSpeciesAndWeightFilter(criteria: $criteria, claimCheckKey: $claimCheckKey) {
      name
      familyName
      familyNameWithDosage
      normalizedStrengthWithUOM
      key
      baseProductKey
      baseProductName
      strength
      size
      speciesCategoryCodes
      unitOfMeasure
      productForm
      shelfLife
      clientSubTotal
      productLink {
        productLinkKey
        pimsProductKey
        pimsProductName
      }
    }
  }
`;

/**
 * __useSearchProductsWithSpeciesAndWeightFilterQuery__
 *
 * To run a query within a React component, call `useSearchProductsWithSpeciesAndWeightFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsWithSpeciesAndWeightFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsWithSpeciesAndWeightFilterQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *      claimCheckKey: // value for 'claimCheckKey'
 *   },
 * });
 */
export function useSearchProductsWithSpeciesAndWeightFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchProductsWithSpeciesAndWeightFilterQuery,
    SearchProductsWithSpeciesAndWeightFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchProductsWithSpeciesAndWeightFilterQuery,
    SearchProductsWithSpeciesAndWeightFilterQueryVariables
  >(SearchProductsWithSpeciesAndWeightFilterDocument, options);
}
export function useSearchProductsWithSpeciesAndWeightFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchProductsWithSpeciesAndWeightFilterQuery,
    SearchProductsWithSpeciesAndWeightFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchProductsWithSpeciesAndWeightFilterQuery,
    SearchProductsWithSpeciesAndWeightFilterQueryVariables
  >(SearchProductsWithSpeciesAndWeightFilterDocument, options);
}
export type SearchProductsWithSpeciesAndWeightFilterQueryHookResult = ReturnType<
  typeof useSearchProductsWithSpeciesAndWeightFilterQuery
>;
export type SearchProductsWithSpeciesAndWeightFilterLazyQueryHookResult = ReturnType<
  typeof useSearchProductsWithSpeciesAndWeightFilterLazyQuery
>;
export type SearchProductsWithSpeciesAndWeightFilterQueryResult = Apollo.QueryResult<
  SearchProductsWithSpeciesAndWeightFilterQuery,
  SearchProductsWithSpeciesAndWeightFilterQueryVariables
>;
export const SearchGPMProductsDocument = gql`
  query SearchGPMProducts($criteria: ProductSearchCriteriaI!, $claimCheckKey: UUID) {
    searchProducts(criteria: $criteria, claimCheckKey: $claimCheckKey) {
      name
      familyName
      familyNameWithDosage
      normalizedStrengthWithUOM
      key
      baseProductKey
      baseProductName
      strength
      size
      speciesCategoryCodes
      unitOfMeasure
      productForm
      shelfLife
      clientSubTotal
      productLink {
        productLinkKey
        pimsProductKey
        pimsProductName
      }
    }
  }
`;

/**
 * __useSearchGPMProductsQuery__
 *
 * To run a query within a React component, call `useSearchGPMProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGPMProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGPMProductsQuery({
 *   variables: {
 *      criteria: // value for 'criteria'
 *      claimCheckKey: // value for 'claimCheckKey'
 *   },
 * });
 */
export function useSearchGPMProductsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchGPMProductsQuery, SearchGPMProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchGPMProductsQuery, SearchGPMProductsQueryVariables>(SearchGPMProductsDocument, options);
}
export function useSearchGPMProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchGPMProductsQuery, SearchGPMProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchGPMProductsQuery, SearchGPMProductsQueryVariables>(
    SearchGPMProductsDocument,
    options,
  );
}
export type SearchGPMProductsQueryHookResult = ReturnType<typeof useSearchGPMProductsQuery>;
export type SearchGPMProductsLazyQueryHookResult = ReturnType<typeof useSearchGPMProductsLazyQuery>;
export type SearchGPMProductsQueryResult = Apollo.QueryResult<SearchGPMProductsQuery, SearchGPMProductsQueryVariables>;
export const RetrieveClaimCheckByGuidForUnlinkedProductDocument = gql`
  query RetrieveClaimCheckByGuidForUnlinkedProduct($guid: UUID!) {
    retrieveRxByGuidOrClaimCheckGuid(claimCheckKey: $guid, pimsPrescriptionKey: null) {
      linkedPatientKey
      linkedClientKey
      unlinkedProductKey
      baseProductKey
      pimsType
    }
  }
`;

/**
 * __useRetrieveClaimCheckByGuidForUnlinkedProductQuery__
 *
 * To run a query within a React component, call `useRetrieveClaimCheckByGuidForUnlinkedProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveClaimCheckByGuidForUnlinkedProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveClaimCheckByGuidForUnlinkedProductQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useRetrieveClaimCheckByGuidForUnlinkedProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrieveClaimCheckByGuidForUnlinkedProductQuery,
    RetrieveClaimCheckByGuidForUnlinkedProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RetrieveClaimCheckByGuidForUnlinkedProductQuery,
    RetrieveClaimCheckByGuidForUnlinkedProductQueryVariables
  >(RetrieveClaimCheckByGuidForUnlinkedProductDocument, options);
}
export function useRetrieveClaimCheckByGuidForUnlinkedProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveClaimCheckByGuidForUnlinkedProductQuery,
    RetrieveClaimCheckByGuidForUnlinkedProductQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RetrieveClaimCheckByGuidForUnlinkedProductQuery,
    RetrieveClaimCheckByGuidForUnlinkedProductQueryVariables
  >(RetrieveClaimCheckByGuidForUnlinkedProductDocument, options);
}
export type RetrieveClaimCheckByGuidForUnlinkedProductQueryHookResult = ReturnType<
  typeof useRetrieveClaimCheckByGuidForUnlinkedProductQuery
>;
export type RetrieveClaimCheckByGuidForUnlinkedProductLazyQueryHookResult = ReturnType<
  typeof useRetrieveClaimCheckByGuidForUnlinkedProductLazyQuery
>;
export type RetrieveClaimCheckByGuidForUnlinkedProductQueryResult = Apollo.QueryResult<
  RetrieveClaimCheckByGuidForUnlinkedProductQuery,
  RetrieveClaimCheckByGuidForUnlinkedProductQueryVariables
>;
export const UpdateRxWithUnlinkedProductDocument = gql`
  mutation UpdateRxWithUnlinkedProduct(
    $baseProductKey: UUID!
    $productKey: UUID!
    $claimCheckKey: UUID!
    $pimsPrescriptionKey: UUID
  ) {
    updateRxWithUnlinkedProduct(
      baseProductKey: $baseProductKey
      productKey: $productKey
      claimCheckKey: $claimCheckKey
      pimsPrescriptionKey: $pimsPrescriptionKey
    )
  }
`;
export type UpdateRxWithUnlinkedProductMutationFn = Apollo.MutationFunction<
  UpdateRxWithUnlinkedProductMutation,
  UpdateRxWithUnlinkedProductMutationVariables
>;

/**
 * __useUpdateRxWithUnlinkedProductMutation__
 *
 * To run a mutation, you first call `useUpdateRxWithUnlinkedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRxWithUnlinkedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRxWithUnlinkedProductMutation, { data, loading, error }] = useUpdateRxWithUnlinkedProductMutation({
 *   variables: {
 *      baseProductKey: // value for 'baseProductKey'
 *      productKey: // value for 'productKey'
 *      claimCheckKey: // value for 'claimCheckKey'
 *      pimsPrescriptionKey: // value for 'pimsPrescriptionKey'
 *   },
 * });
 */
export function useUpdateRxWithUnlinkedProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRxWithUnlinkedProductMutation,
    UpdateRxWithUnlinkedProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRxWithUnlinkedProductMutation, UpdateRxWithUnlinkedProductMutationVariables>(
    UpdateRxWithUnlinkedProductDocument,
    options,
  );
}
export type UpdateRxWithUnlinkedProductMutationHookResult = ReturnType<typeof useUpdateRxWithUnlinkedProductMutation>;
export type UpdateRxWithUnlinkedProductMutationResult = Apollo.MutationResult<UpdateRxWithUnlinkedProductMutation>;
export type UpdateRxWithUnlinkedProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateRxWithUnlinkedProductMutation,
  UpdateRxWithUnlinkedProductMutationVariables
>;
export const getPlatformAccountDocument = gql`
  query getPlatformAccount($acbKey: String!, $platformAccountKey: String!) {
    getPlatformAccount(acbKey: $acbKey, platformAccountKey: $platformAccountKey) {
      platformAccountKey
      platformAccountEnterpriseNumber
      primaryAccountCovetrusCustomerNumber
      displayName
      address {
        addressTypeCode
        country
        stateOrProvince
        postalCode
        line1
        line2
        line3
        city
      }
    }
  }
`;

/**
 * __usegetPlatformAccountQuery__
 *
 * To run a query within a React component, call `usegetPlatformAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetPlatformAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usegetPlatformAccountQuery({
 *   variables: {
 *      acbKey: // value for 'acbKey'
 *      platformAccountKey: // value for 'platformAccountKey'
 *   },
 * });
 */
export function usegetPlatformAccountQuery(
  baseOptions: Apollo.QueryHookOptions<getPlatformAccountQuery, getPlatformAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<getPlatformAccountQuery, getPlatformAccountQueryVariables>(
    getPlatformAccountDocument,
    options,
  );
}
export function usegetPlatformAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<getPlatformAccountQuery, getPlatformAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<getPlatformAccountQuery, getPlatformAccountQueryVariables>(
    getPlatformAccountDocument,
    options,
  );
}
export type getPlatformAccountQueryHookResult = ReturnType<typeof usegetPlatformAccountQuery>;
export type getPlatformAccountLazyQueryHookResult = ReturnType<typeof usegetPlatformAccountLazyQuery>;
export type getPlatformAccountQueryResult = Apollo.QueryResult<
  getPlatformAccountQuery,
  getPlatformAccountQueryVariables
>;
export const getSmartScribeErrorsDocument = gql`
  query getSmartScribeErrors($validateCriteria: ValidateSmartScribeI!) {
    validateSmartScribe(validateCriteria: $validateCriteria) {
      passesValidation
      validation {
        type
        code
        values
        patientWeightMin
        patientWeightMax
        selectedWeight
        selectedSpecies
      }
      skippedValidations {
        skippedReasonCodes
        validationCode
      }
    }
  }
`;

/**
 * __usegetSmartScribeErrorsQuery__
 *
 * To run a query within a React component, call `usegetSmartScribeErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetSmartScribeErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usegetSmartScribeErrorsQuery({
 *   variables: {
 *      validateCriteria: // value for 'validateCriteria'
 *   },
 * });
 */
export function usegetSmartScribeErrorsQuery(
  baseOptions: Apollo.QueryHookOptions<getSmartScribeErrorsQuery, getSmartScribeErrorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<getSmartScribeErrorsQuery, getSmartScribeErrorsQueryVariables>(
    getSmartScribeErrorsDocument,
    options,
  );
}
export function usegetSmartScribeErrorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<getSmartScribeErrorsQuery, getSmartScribeErrorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<getSmartScribeErrorsQuery, getSmartScribeErrorsQueryVariables>(
    getSmartScribeErrorsDocument,
    options,
  );
}
export type getSmartScribeErrorsQueryHookResult = ReturnType<typeof usegetSmartScribeErrorsQuery>;
export type getSmartScribeErrorsLazyQueryHookResult = ReturnType<typeof usegetSmartScribeErrorsLazyQuery>;
export type getSmartScribeErrorsQueryResult = Apollo.QueryResult<
  getSmartScribeErrorsQuery,
  getSmartScribeErrorsQueryVariables
>;
export const RetrieveLinkedClientDetailsDocument = gql`
  query RetrieveLinkedClientDetails($guid: UUID!) {
    retrieveClientByGuid(guid: $guid) {
      givenName
      familyName
      emailAddress
      phoneNumber
      dateOfBirth
    }
  }
`;

/**
 * __useRetrieveLinkedClientDetailsQuery__
 *
 * To run a query within a React component, call `useRetrieveLinkedClientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveLinkedClientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveLinkedClientDetailsQuery({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useRetrieveLinkedClientDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RetrieveLinkedClientDetailsQuery, RetrieveLinkedClientDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveLinkedClientDetailsQuery, RetrieveLinkedClientDetailsQueryVariables>(
    RetrieveLinkedClientDetailsDocument,
    options,
  );
}
export function useRetrieveLinkedClientDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveLinkedClientDetailsQuery,
    RetrieveLinkedClientDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveLinkedClientDetailsQuery, RetrieveLinkedClientDetailsQueryVariables>(
    RetrieveLinkedClientDetailsDocument,
    options,
  );
}
export type RetrieveLinkedClientDetailsQueryHookResult = ReturnType<typeof useRetrieveLinkedClientDetailsQuery>;
export type RetrieveLinkedClientDetailsLazyQueryHookResult = ReturnType<typeof useRetrieveLinkedClientDetailsLazyQuery>;
export type RetrieveLinkedClientDetailsQueryResult = Apollo.QueryResult<
  RetrieveLinkedClientDetailsQuery,
  RetrieveLinkedClientDetailsQueryVariables
>;
export const RetrieveLinkedPatientDetailsDocument = gql`
  query RetrieveLinkedPatientDetails($key: UUID!) {
    getPatient(key: $key) {
      key
      name
      clientKey
      clientName
      isClientActive
      speciesCode
      speciesType
      breedCode
      sexCode
      weight
      weightUnitCode
      dateOfBirth
      isActive
      isLinked
      clientRoleTypeCode
    }
  }
`;

/**
 * __useRetrieveLinkedPatientDetailsQuery__
 *
 * To run a query within a React component, call `useRetrieveLinkedPatientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveLinkedPatientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveLinkedPatientDetailsQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useRetrieveLinkedPatientDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RetrieveLinkedPatientDetailsQuery, RetrieveLinkedPatientDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveLinkedPatientDetailsQuery, RetrieveLinkedPatientDetailsQueryVariables>(
    RetrieveLinkedPatientDetailsDocument,
    options,
  );
}
export function useRetrieveLinkedPatientDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveLinkedPatientDetailsQuery,
    RetrieveLinkedPatientDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveLinkedPatientDetailsQuery, RetrieveLinkedPatientDetailsQueryVariables>(
    RetrieveLinkedPatientDetailsDocument,
    options,
  );
}
export type RetrieveLinkedPatientDetailsQueryHookResult = ReturnType<typeof useRetrieveLinkedPatientDetailsQuery>;
export type RetrieveLinkedPatientDetailsLazyQueryHookResult = ReturnType<
  typeof useRetrieveLinkedPatientDetailsLazyQuery
>;
export type RetrieveLinkedPatientDetailsQueryResult = Apollo.QueryResult<
  RetrieveLinkedPatientDetailsQuery,
  RetrieveLinkedPatientDetailsQueryVariables
>;
export const RetrieveProductDetailsDocument = gql`
  query RetrieveProductDetails($baseProductKey: UUID!, $productKey: UUID!, $claimCheckKey: UUID) {
    getProduct(baseProductKey: $baseProductKey, productKey: $productKey, claimCheckKey: $claimCheckKey) {
      name
      productStatusCode
      baseProductStatusCode
      strength
      speciesCategoryCodes
      unitOfMeasure
      legalCategoryCode
      flavorCodes {
        flavorCode
        position
        isVisibleToConsumer
      }
      patientWeightMin
      patientWeightMax
      isDiet
      treatmentCategories
      labelKeywords
      isControlled
      isEssentialCopy
      defaultSupplyCount
      linkedProductKey
      size
    }
  }
`;

/**
 * __useRetrieveProductDetailsQuery__
 *
 * To run a query within a React component, call `useRetrieveProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveProductDetailsQuery({
 *   variables: {
 *      baseProductKey: // value for 'baseProductKey'
 *      productKey: // value for 'productKey'
 *      claimCheckKey: // value for 'claimCheckKey'
 *   },
 * });
 */
export function useRetrieveProductDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RetrieveProductDetailsQuery, RetrieveProductDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RetrieveProductDetailsQuery, RetrieveProductDetailsQueryVariables>(
    RetrieveProductDetailsDocument,
    options,
  );
}
export function useRetrieveProductDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RetrieveProductDetailsQuery, RetrieveProductDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RetrieveProductDetailsQuery, RetrieveProductDetailsQueryVariables>(
    RetrieveProductDetailsDocument,
    options,
  );
}
export type RetrieveProductDetailsQueryHookResult = ReturnType<typeof useRetrieveProductDetailsQuery>;
export type RetrieveProductDetailsLazyQueryHookResult = ReturnType<typeof useRetrieveProductDetailsLazyQuery>;
export type RetrieveProductDetailsQueryResult = Apollo.QueryResult<
  RetrieveProductDetailsQuery,
  RetrieveProductDetailsQueryVariables
>;
export const RetrieveRxDetailsWithGuidORClaimCheckGuidDocument = gql`
  query RetrieveRxDetailsWithGuidORClaimCheckGuid($claimCheckKey: UUID!, $pimsPrescriptionKey: UUID) {
    retrieveRxByGuidOrClaimCheckGuid(claimCheckKey: $claimCheckKey, pimsPrescriptionKey: $pimsPrescriptionKey) {
      pimsPrescriptionKey
      claimCheckKey
      code
      form
      description
      dosage
      linkedClientKey
      linkedPatientKey
      linkedProductKey
      unlinkedProductKey
      baseProductKey
      requestedProductKey
      prescribingVetKey
      refills
      directions
      expirationDate
      pimsType
      userCanEditRx
      userCanDeclineRx
      rxHasBeenProcessed
      status
      workflowStatus
      authorizedWithChanges
      units
      prescriptionRequestType
      legalCategoryCode
      approvalKey
      renewalKey
    }
  }
`;

/**
 * __useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery__
 *
 * To run a query within a React component, call `useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery({
 *   variables: {
 *      claimCheckKey: // value for 'claimCheckKey'
 *      pimsPrescriptionKey: // value for 'pimsPrescriptionKey'
 *   },
 * });
 */
export function useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery(
  baseOptions: Apollo.QueryHookOptions<
    RetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
    RetrieveRxDetailsWithGuidORClaimCheckGuidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
    RetrieveRxDetailsWithGuidORClaimCheckGuidQueryVariables
  >(RetrieveRxDetailsWithGuidORClaimCheckGuidDocument, options);
}
export function useRetrieveRxDetailsWithGuidORClaimCheckGuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
    RetrieveRxDetailsWithGuidORClaimCheckGuidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
    RetrieveRxDetailsWithGuidORClaimCheckGuidQueryVariables
  >(RetrieveRxDetailsWithGuidORClaimCheckGuidDocument, options);
}
export type RetrieveRxDetailsWithGuidORClaimCheckGuidQueryHookResult = ReturnType<
  typeof useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery
>;
export type RetrieveRxDetailsWithGuidORClaimCheckGuidLazyQueryHookResult = ReturnType<
  typeof useRetrieveRxDetailsWithGuidORClaimCheckGuidLazyQuery
>;
export type RetrieveRxDetailsWithGuidORClaimCheckGuidQueryResult = Apollo.QueryResult<
  RetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
  RetrieveRxDetailsWithGuidORClaimCheckGuidQueryVariables
>;
export const SubmitRxDocument = gql`
  mutation SubmitRx($rx: PrescriptionI!) {
    submitPrescription(prescription: $rx)
  }
`;
export type SubmitRxMutationFn = Apollo.MutationFunction<SubmitRxMutation, SubmitRxMutationVariables>;

/**
 * __useSubmitRxMutation__
 *
 * To run a mutation, you first call `useSubmitRxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRxMutation, { data, loading, error }] = useSubmitRxMutation({
 *   variables: {
 *      rx: // value for 'rx'
 *   },
 * });
 */
export function useSubmitRxMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitRxMutation, SubmitRxMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitRxMutation, SubmitRxMutationVariables>(SubmitRxDocument, options);
}
export type SubmitRxMutationHookResult = ReturnType<typeof useSubmitRxMutation>;
export type SubmitRxMutationResult = Apollo.MutationResult<SubmitRxMutation>;
export type SubmitRxMutationOptions = Apollo.BaseMutationOptions<SubmitRxMutation, SubmitRxMutationVariables>;
export const UnlinkProductLinkDocument = gql`
  mutation UnlinkProductLink($productKey: UUID!, $installationKey: UUID!, $claimCheckKey: UUID!) {
    unlinkProductLink(productKey: $productKey, installationKey: $installationKey, claimCheckKey: $claimCheckKey)
  }
`;
export type UnlinkProductLinkMutationFn = Apollo.MutationFunction<
  UnlinkProductLinkMutation,
  UnlinkProductLinkMutationVariables
>;

/**
 * __useUnlinkProductLinkMutation__
 *
 * To run a mutation, you first call `useUnlinkProductLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkProductLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkProductLinkMutation, { data, loading, error }] = useUnlinkProductLinkMutation({
 *   variables: {
 *      productKey: // value for 'productKey'
 *      installationKey: // value for 'installationKey'
 *      claimCheckKey: // value for 'claimCheckKey'
 *   },
 * });
 */
export function useUnlinkProductLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<UnlinkProductLinkMutation, UnlinkProductLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnlinkProductLinkMutation, UnlinkProductLinkMutationVariables>(
    UnlinkProductLinkDocument,
    options,
  );
}
export type UnlinkProductLinkMutationHookResult = ReturnType<typeof useUnlinkProductLinkMutation>;
export type UnlinkProductLinkMutationResult = Apollo.MutationResult<UnlinkProductLinkMutation>;
export type UnlinkProductLinkMutationOptions = Apollo.BaseMutationOptions<
  UnlinkProductLinkMutation,
  UnlinkProductLinkMutationVariables
>;
