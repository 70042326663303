import React, { useState } from "react";
import { buildFlaggedIssues, StatesWith503B } from "../../containers/ReviewRx/utils";
import { FormWarning } from "../FormWarning/FormWarning";
import messages from "./messages";
import OTCScriptForm from "./OTCScriptForm";
import ScriptForm from "./ScriptForm";
import { HooksAndState } from "./ScriptForm/types";
import { CopyDiv, ErrorContainer } from "./styles";
import { FlaggedIssues } from "./Subcomponents/FlaggedIssuesError/types";
import { convertFlaggedIssuesToGrid } from "./Subcomponents/FlaggedIssuesError/utils";
import { Props } from "./types";
import { HooksAndState as OTCHooksAndState } from "./OTCScriptForm/types";
import { ValidateSmartScribeT, usegetSmartScribeErrorsQuery } from "../../data/gql/apiTypes";
import { exists } from "../../util";
import { FormError } from "../FormError/FormError";

export enum ProductStatusEnum {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  BACKORDERED = "BACKORDERED",
  DISCONTINUED = "DISCONTINUED"
}

export const legalCategoryCodeEnum = {
  RX: "RX",
  AOTC: "APPROVED_OTC",
  OTC: "OTC",
};

const getFormType = (
  legalCategoryCode: Props["legalCategoryCode"],
  props: Props & {
    hooksAndState: HooksAndState;
    flaggedIssues: FlaggedIssues;
  },
  setReviewRx: (state: Partial<Props["reviewRx"]>) => void,
) => {
  const { sendButton, reviewRx, flaggedIssues, submitRx, hooksAndState, pendingRequestType } = props;

  const flaggedGridValues = convertFlaggedIssuesToGrid(flaggedIssues);

  const reviewOTC = {
    expirationDate: reviewRx.expirationDate,
    autoship: reviewRx.autoship,
    authorizingVets: reviewRx.authorizingVets,
    linkedGuids: reviewRx.linkedGuids,
    strength: reviewRx.productStrength,
    authorizedWithChanges: reviewRx.authorizedWithChanges,
    vetDelegateLevels: reviewRx.vetDelegateLevels,
    legalCategoryCode: reviewRx.legalCategoryCode,
    authorizingVet: reviewRx.authorizingVet,
  };

  const otcHooksAndState: OTCHooksAndState = {
    shouldValidateOnAction: hooksAndState.shouldValidateOnAction,
    setShouldValidateOnAction: hooksAndState.setShouldValidateOnAction,
    setIsFormSubmitting: hooksAndState.setIsFormSubmitting,
    disableSubmit: hooksAndState.disableSubmit
  };

  switch (legalCategoryCode) {
    case legalCategoryCodeEnum.OTC:
      return (
        <OTCScriptForm
          submitRx={submitRx}
          sendButton={sendButton}
          hooksAndState={otcHooksAndState}
          legalCategoryCode={legalCategoryCode}
          reviewOTC={reviewOTC}
          flaggedGridValues={flaggedGridValues}
          pendingRequestType={pendingRequestType}
          setReviewRx={setReviewRx}
        />
      );
    case legalCategoryCodeEnum.AOTC:
      return (
        <OTCScriptForm
          submitRx={submitRx}
          sendButton={sendButton}
          hooksAndState={otcHooksAndState}
          flaggedGridValues={flaggedGridValues}
          legalCategoryCode={legalCategoryCode}
          reviewOTC={reviewOTC}
          pendingRequestType={pendingRequestType}
          setReviewRx={setReviewRx}
        />
      );
    case legalCategoryCodeEnum.RX:
    default:
      return (
        <ScriptForm
          submitRx={submitRx}
          sendButton={sendButton}
          hooksAndState={hooksAndState}
          reviewRx={reviewRx}
          flaggedGridValues={flaggedGridValues}
          pendingRequestType={pendingRequestType}
          setReviewRx={setReviewRx}
        />
      );
  }
};

const FinishScriptCard = (props: Props) => {
  const { copy, legalCategoryCode, setIsFormSubmitting } = props;
  const [shouldValidateOnAction, setShouldValidateOnAction] = useState(false);
  const [reviewRx, setReviewRx] = useState<Partial<Props["reviewRx"]>>(props.reviewRx);
  const [directions, setDirections] = useState(props.reviewRx.directions);

  const gpmSmartScribeQuery = usegetSmartScribeErrorsQuery({
    variables: {
      validateCriteria: {
        baseProductKey: props.relevantFieldsForConflict.baseProductKey,
        childProductKey: props.relevantFieldsForConflict.childProductKey,
        clientKey: props.relevantFieldsForConflict.clientKey || "",
        patientKey: props.relevantFieldsForConflict.patientKey || "",
        unitsPerFill: props.relevantFieldsForConflict.unitsPerFill,
        directions: directions,
        acbKey: props.relevantFieldsForConflict.acbKey || "",
        dosageTypeCode: props.relevantFieldsForConflict.dosageTypeCode
      }
    },
    skip:
      !exists(props.relevantFieldsForConflict.patientKey) &&
      !exists(props.relevantFieldsForConflict.clientKey),
  });

  const gpmSmartScribe = gpmSmartScribeQuery?.data?.validateSmartScribe as ValidateSmartScribeT;

  const flaggedIssues =
    buildFlaggedIssues(props.relevantFieldsForConflict, gpmSmartScribe, reviewRx);

  const hooksAndState = {
    shouldValidateOnAction,
    setShouldValidateOnAction,
    setDirections,
    setIsFormSubmitting,
    disableSubmit: props.isProductOrBaseProductDiscontinuedOrInactive
  };

  const onReviewRxUpdate = (newState: Partial<Props["reviewRx"]>) => {
    setReviewRx((prevState) => {
      return {
        ...prevState,
        ...newState,
      };
    });
  };

  return (
    <div>
      <h1>{messages.finishScriptTitle}</h1>
      <CopyDiv>
        {copy.partOne}
        <span>{copy.bold}</span>
        {copy.partTwo}
      </CopyDiv>
      {props.isProductOrBaseProductDiscontinuedOrInactive
        ? (
          <FormError title={messages.validations.resolve} reason={messages.validations.productUnavailable} />
        ) :
        flaggedIssues.weight ||
          flaggedIssues?.species ||
          flaggedIssues.keywords ||
          flaggedIssues?.controlledProduct ||
          flaggedIssues?.authorizingVetLicense ||
          flaggedIssues?.authorizingVetDeaLicense ||
          flaggedIssues?.productIsEssentialCopy ?
          reviewRx.productStatusCode === ProductStatusEnum.BACKORDERED ? (
            //Add a bullet point • to separate out each message for the scenario where the product is backordered and the script has other flag issue(s).
            <FormWarning reason={<div><div>• {messages.validations.backorderedProduct}</div><div>• {messages.validations.problemWithScript}</div></div>} />
          ) : (<FormWarning reason={<div>{messages.validations.problemWithScript}</div>} />)
          :
          reviewRx.productStatusCode === ProductStatusEnum.BACKORDERED ?
            (<FormWarning reason={<div><div>{messages.validations.backorderedProduct}</div></div>} />) : null
      }
      {reviewRx.is503B ? (<ErrorContainer>{messages.validations.productIs503B}</ErrorContainer>) : null}
      <h2>{messages.details}</h2>
      {getFormType(
        legalCategoryCode,
        {
          ...props,
          hooksAndState,
          flaggedIssues,
        },
        onReviewRxUpdate,
      )}
    </div>
  );
};

export default FinishScriptCard;
