import styled from "styled-components/macro";
import { Button } from "../StyledComponents/Button/Button";
import { InputErrorDiv as InputErrorDivBase, InputWarningDiv as InputWarningDivBase } from "../../containers/PatientImport/styles";

export const SubmitRxBtn = styled(Button)`
  float: right;
  margin: 20px 0 40px 0;
  padding: 0 30px 0 30px;
`;

export const SpacerDiv = styled.div`
  margin-top: 20px;
  height: 90px;
`;

export const ControlledWarningSpacerDiv = styled.div`
  margin-top: 20px;
  height: 350px;
`;

export const HelpLabel = styled.label`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: #737373;
`;

export const FillMessageLabel = styled.label`
  display: block;
  margin-top: 4px;
  font-size: 12.5px;
  font-weight: bold
`;

export const InputInlineWrapper = styled.div`
  padding-bottom: 10px;

  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    margin-bottom: 2px;
  }

  input {
    margin-bottom: 2px;
  }
`;

export const FieldColContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
`;

// TODO: Move toggle into separate styled component
export const AutoshipDiv = styled.div`
  font-size: 12px;
  svg {
    margin-right: 10px;
    margin-bottom: -8px;
  }

  /* The switch - the box around the slider */
  label {
    transform: scale(0.7);
    margin-left: 5px;

    vertical-align: middle;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  label input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9b9b9b;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #1f9798;
  }

  input:focus + span {
    box-shadow: 0 0 1px #1f9798;
  }

  input:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    border-radius: 50%;
  }
`;

export const InputWrapper = styled.div`
  padding-bottom: 10px;

  button {
    border: none;
  }

  input {
    font-family: arial;
    margin-bottom: 2px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select#notesToClientShortcuts,
  select#sigShortcuts {
    width: 57%;
    margin-right: 11px;
  }
`;

export const MessageWrapper = styled.div`
  height: 76px;
  padding-bottom: 10px;
`;

export const MessageDiv = styled.div`
  position: absolute;
  bottom: 10;
  left: 10;
`;

export const TooltipWrapper = styled.div`
  div {
    text-align: right !important; // TODO: remove !important

    margin-top: -25px;
    padding-right: 7.5px;

    pointer-events: none;
    span {
      pointer-events: auto;
    }

    .tooltip-bottom::after {
      border-bottom: 9px solid #555555;
      margin-top: 25px;
    }

    .tooltip-message {
      text-align: left !important; // TODO: remove !important
      max-width: 300px;
      background-color: #555555;
      padding: 20px;
      margin-top: 25px;
    }
  }

  label {
    margin-top: 10px;
  }
`;

export const CopyDiv = styled.div`
  span {
    font-weight: bold;
  }

  padding-bottom: 20px;
`;

export const ErrorContainer = styled.div`

  margin-top: 10px ;
  color: red;
  font-weight: bold;
  
`;

export const SpacerErrorDiv = styled.div`
  padding: 0;
  height: 20px;
`;

export const InputErrorDiv = styled(InputErrorDivBase)`
  height: 20px;
`;

export const InputWarningDiv = styled(InputWarningDivBase)`
  height: 20px;
`;

export const SubTitleH2 = styled.h2`
  padding: 20px 0;
`;

export const TopPaddingSpacer = styled.div`
  padding-top: 5px;
`;

export const CheckboxWrapperDiv = styled.div`
  display: flex;
  align-content: flex-start;
  align-items: center;
  justify-content: left;
  width: 100%;
  
  input[type="checkbox"] {
    margin-right: 12px;
    height: 1em;
  }

  input[type="checkbox"] + span {
    font-weight: bold;
    color: black;
  }
  
  label {
    color: #404040;
  }
`;